import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react'

import { useRouter } from 'next/router'

import TagManager from 'react-gtm-module' // TODO: evaluate to change with something more specific for NextJs or makeit manually

import { GeneralContext } from './General'

const GTMContext = createContext()

export const GTM_CUSTOM_EVENTS = { FORM: 'formSubmit' }

const GTMProvider = ({ children }) => {
  const router = useRouter()
  const [gtmInstance, setGtmInstance] = useState(null)
  const { options = {} } = useContext(GeneralContext)
  const gtmId = options.gtm

  /**
   * Push data to a datalayer
   * @param {object} payload
   * @returns
   */
  const pushToDataLayer = useCallback(({
    event = 'custom-event',
    type,
    label,
    value
  }) => {
    if (!gtmInstance) return

    const dataLayerPayload = {
      dataLayer: {
        event,
        eventType: type,
        eventLabel: label,
        eventValue: value
      },
      dataLayerName: 'TransmecDataLayer'
    }
    // console.log(dataLayerPayload)
    try {
      gtmInstance.dataLayer(dataLayerPayload)
    } catch (error) {
      console.error('GTM error on push:', error.message)
    }
  }, [gtmInstance])
  /**
   * Wait n seconds before push data to a datalayer
   * @param {object} payload
   * @param {number} delay
   * @returns
   */
  const waitAndPushToDataLayer = async (payload = {}, delay = 1000) => {
    pushToDataLayer(payload)

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, delay)
    })
  }


  /**
   * Create new datalayer
   * @param {string} dataLayerName
   */
  /**
   * Create new datalayer
   * @param {string} dataLayerName
   */
  const create = useCallback((dataLayerName = 'DataLayer') => {
    // console.debug('GTM - Start loading!')

    const instance = TagManager
    instance.initialize({
      gtmId, dataLayerName
    })
    setGtmInstance(instance)
  }, [gtmId])

  /**
   * Inject new datalayer
   * @param {string} dataLayerName
   */
  const newDataLayer = (dataLayerName = '') => {
    if (!dataLayerName) {
      // console.debug('GTM - Missing datalayer name')
      return
    }

    create(dataLayerName)
  }

  useEffect(() => {
    if (!gtmId) return
    create('TransmecDataLayer')
    pushToDataLayer({ event: 'additional_script_trigger' })
  }, [create, gtmId, pushToDataLayer])

  useEffect(() => {
    const handleRouteChange = () => {
      pushToDataLayer({ event: 'additional_script_trigger' })
    }
    if (gtmId && window.dataLayer) {
      pushToDataLayer({ event: 'additional_script_trigger' })
      router.events.on('routeChangeComplete', handleRouteChange)
    }
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [gtmId, pushToDataLayer, router.events])

  const value = {
    gtmInstance, pushToDataLayer, waitAndPushToDataLayer, newDataLayer
  }

  return <GTMContext.Provider value={value}>{children}</GTMContext.Provider>
}

export default GTMProvider
export { GTMContext }
