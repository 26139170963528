import { createStitches } from '@stitches/react'
import localFont from '@next/font/local'

import colors from 'theme/colors'
import { media } from 'theme/breakpoints'
import utils from 'theme/utils'

const Satoshi = localFont({
  src: [
    {
      path: '../public/assets/fonts/Satoshi/Satoshi-Medium.woff2',
      weight: '500',
      style: 'normal',
      display: 'swap',
      preload: true,
      fallback: ['sans-serif']
    },
    {
      path: '../public/assets/fonts/Satoshi/Satoshi-Medium.woff',
      weight: '500',
      style: 'normal',
      display: 'swap',
      preload: true,
      fallback: ['sans-serif']
    },
    {
      path: '../public/assets/fonts/Satoshi/Satoshi-Regular.woff2',
      weight: '400',
      style: 'normal',
      display: 'swap',
      preload: true,
      fallback: ['sans-serif']
    },
    {
      path: '../public/assets/fonts/Satoshi/Satoshi-Regular.woff',
      weight: '400',
      style: 'normal',
      display: 'swap',
      preload: true,
      fallback: ['sans-serif']
    },
    {
      path: '../public/assets/fonts/Satoshi/Satoshi-Bold.woff2',
      weight: '700',
      style: 'normal',
      display: 'swap',
      preload: true,
      fallback: ['sans-serif']
    },
    {
      path: '../public/assets/fonts/Satoshi/Satoshi-Bold.woff',
      weight: '700',
      style: 'normal',
      display: 'swap',
      preload: true,
      fallback: ['sans-serif']
    },
  ],
})

export const DEFAULT_SIZE = 8
export const SITE_COLUMNS = 12
export const HEADER_HEIGHT = {
  desktop: 151,
  mobile: 80
}

export const rem = (...values) => values.map(px => `${+px / (DEFAULT_SIZE * 2)}rem`).join(' ')

export const col = (colN) =>  `${(100 / 12) * colN}% `

export const {
  keyframes,
  styled,
  getCssText,
  globalCss,
  config,
} = createStitches({
  theme: {
    fonts: { base: Satoshi.style.fontFamily },
    colors,
    fontSizes: {
      // Desktop font sizes
      'form-text': rem(24),
      'news-title': rem(24),
      'big-cta-desk': rem(24),
      'big-text-desk': rem(48),
      'cta-primary': rem(16),
      'cta-underline': rem(18),

      'footer': rem(14),
      'sub-desk': rem(12),
      'p4': rem(14),
      'p3-mid': rem(14),
      'p3': rem(16),
      'p1-mid': rem(24),
      'p2': rem(20),
      'p1': rem(24),
      'subtitle-2': rem(20),
      'subtitle-1': rem(32),

      'h4': rem(24),
      'h3': rem(48),
      'h2': rem(80),
      'h1-mid': rem(80),
      'h1': rem(100),
      'sliding-txt': rem(200),
      'stats': rem(54),

      // Mobile font sizes
      'news-title-mobile': rem(18),
      'big-text-mobile': rem(32),
      'cta-primary-mobile': rem(16),
      'cta-underline-mobile': rem(14),
      'sub-2-mobile': rem(8),
      'sub-mobile': rem(8),
      'p4-mobile': rem(10),
      'p3-mid-mobile': rem(10),
      'p3-mobile': rem(11),
      'p2-mid-mobile': rem(12),
      'p2-mobile': rem(12),
      'p1-mid-mobile': rem(14),
      'p1-mobile': rem(14),
      'subtitle-2-mobile': rem(20),
      'subtitle-1-mobile': rem(24),
      'h4-mobile': rem(18),
      'h3-mobile': rem(24),
      'h2-mobile': rem(32),
      'h1-mid-mobile': rem(40),
      'h1-mobile': rem(48),
      'sliding-txt-mobile': rem(100),
      'stats-mobile': rem(48),
    },
    lineHeights: {
      // Desktop line height sizes
      'form-text': rem(32.4),
      'news-title': rem(30),
      'big-cta-desk': rem(24),
      'big-text-desk': rem(64.8),
      'cta-underline': rem(24.3),
      'cta-primary': rem(21.6),
      'footer': rem(16),
      'sub-desk': rem(16.2),
      'p4': rem(20),
      'p3-mid': rem(18.9),
      'p3': rem(20),
      'p1-mid': rem(28),
      'p2': rem(26),
      'p1': rem(28),
      'subtitle-2': rem(27),
      'subtitle-1': rem(43.2),
      'h4': rem(32.4),
      'h3': rem(64.8),
      'h2': rem(80),
      'h1-mid': rem(80),
      'h1': rem(100),
      'sliding-txt': '120%',
      'stats': rem(64),

      // Mobile line height sizes
      'news-title-mobile': rem(24),
      'big-text-mobile': rem(43.2),
      'cta-primary-mobile': rem(21.6),
      'cta-underline-mobile': rem(18.9),
      'sub-2-mobile': rem(10.8),
      'sub-mobile': rem(10.8),
      'p4-mobile': rem(12),
      'p3-mid-mobile': rem(13.5),
      'p3-mobile': rem(14),
      'p2-mid-mobile': rem(16.2),
      'p2-mobile': rem(16.2),
      'p1-mid-mobile': rem(20),
      'p1-mobile': rem(18),
      'subtitle-2-mobile': rem(27),
      'subtitle-1-mobile': rem(32.4),
      'h4-mobile': rem(24),
      'h3-mobile': rem(32.4),
      'h2-mobile': rem(43.2),
      'h1-mid-mobile': rem(48),
      'h1-mobile': rem(56),
      'sliding-txt-mobile': '125%',
      'stats-mobile': rem(64.8),
    },
    fontWeights: {
      1: 500,
      2: 400,
      3: 700,
    },
    radii: {
      smaller: '40px',
      small: '70px',
      normal: '70px',
      big: '100px',
      huge: '100px',
      round: '50%',
    },
    space: {
      0: rem(12),
      1: rem(DEFAULT_SIZE),
      2: rem(DEFAULT_SIZE * 2),
      3: rem(DEFAULT_SIZE * 3),
      4: rem(DEFAULT_SIZE * 4),
      5: rem(DEFAULT_SIZE * 5),
    },
    sizes: {
      0: rem(12),
      1: rem(DEFAULT_SIZE),
      2: rem(DEFAULT_SIZE * 2),
      3: rem(DEFAULT_SIZE * 3),
      4: rem(DEFAULT_SIZE * 4),
      5: rem(DEFAULT_SIZE * 5),
      6: rem(DEFAULT_SIZE / 2),
    }
  },
  media,
  utils,
})
