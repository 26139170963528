import React from 'react'

import Icon from '@/UI/Icon'

import { StyledMapBackButton } from './styles'

const MapBackButton = ({ clickFn }) => {
  const onClickHandler = () => {
    if (clickFn) {
      clickFn()
    }
  }

  return (
    <StyledMapBackButton onClick={onClickHandler}>
      <Icon name="arrowLeft" />
    </StyledMapBackButton>
  )
}

export default MapBackButton
