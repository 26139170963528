import React from 'react'

const hiTech = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="10" fill="#26376D"/>
    <path d="M23.2344 25.4551L28.474 37.0084" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M27.9805 23.1045H36.3763" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M22.1673 25.6872C23.5941 25.6872 24.7507 24.5306 24.7507 23.1038C24.7507 21.6771 23.5941 20.5205 22.1673 20.5205C20.7406 20.5205 19.584 21.6771 19.584 23.1038C19.584 24.5306 20.7406 25.6872 22.1673 25.6872Z" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M45.4167 26.9792L38.9583 28.2708L36.375 23.1042L38.9583 17.9375L45.4167 19.2292" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M37.6673 39.8962H33.1465C33.1465 39.0397 32.8063 38.2184 32.2007 37.6128C31.5951 37.0072 30.7737 36.667 29.9173 36.667C29.0609 36.667 28.2395 37.0072 27.634 37.6128C27.0284 38.2184 26.6882 39.0397 26.6882 39.8962H22.1673C21.4822 39.8962 20.8251 40.1683 20.3406 40.6528C19.8562 41.1373 19.584 41.7943 19.584 42.4795V44.417H40.2507V42.4795C40.2507 41.7943 39.9785 41.1373 39.494 40.6528C39.0095 40.1683 38.3525 39.8962 37.6673 39.8962V39.8962Z" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="square"/>
  </svg>
)

export default hiTech
