import {
  styled, rem
} from 'config/stitches.config'

const SectionContent = styled('div', {
  display: 'block',
  width: '100%',
  marginTop: rem(48),
  '@md': { marginTop: rem(64) }
})

export default SectionContent
