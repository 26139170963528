import React, { forwardRef } from 'react'

import Input from '@/UI/Fields/Input'
import Address from '@/UI/Fields/Address'
import Textarea from '@/UI/Fields/Textarea'
import Select from '@/UI/Fields/Select'
import Group from '@/UI/Fields/Group'
import Search from '@/UI/Fields/Search'
import Recaptcha from '@/UI/Fields/Recaptcha'
import File from '@/UI/Fields/File'
import AsyncSelect from '@/UI/Fields/AsyncSelect'

const Controller = forwardRef(function Controller(props, recaptchaRef) {
  const { type } = props
  let ControllerField = null
  switch (type) {
    case 'text':
    case 'email':
    case 'checkbox':
    case 'date':
    case 'number':
    case 'hidden':
      ControllerField = <Input {...props} />
      break
    case 'search':
      ControllerField = <Search {...props} />
      break
    case 'address':
      ControllerField = <Address {...props} />
      break
    case 'textarea':
      ControllerField = <Textarea {...props} />
      break
    case 'select':
      ControllerField = <Select {...props} />
      break
    case 'asyncSelect':
      ControllerField = <AsyncSelect {...props} />
      break
    case 'radioGroup':
    case 'checkboxGroup':
      ControllerField = <Group {...props} />
      break
    case 'recaptcha':
      ControllerField = <Recaptcha {...props} ref={recaptchaRef} />
      break
    case 'file':
      ControllerField = <File {...props} />
      break
    default:
      ControllerField =  null
  }

  return (
    ControllerField
  )
})

export default Controller
