import React from 'react'


import Icon from '@/UI/Icon'
import Button from '@/UI/Button'

import {
  StyledItems,
  StyledItem
} from './styles'

const List = ({
  items,
  nextLevel,
  onClick
}) => (
  items &&
  !!items.length &&
  <StyledItems>
    {
      items.map((item) => (
        <StyledItem key={item.name}>
          <Button
            theme="reset"
            onClick={() => {
              onClick({
                type: nextLevel,
                name: item.name,
                code: item.code ?? item.id,
                continent: item.continent,
                countries: item.countries,
                zoomLevel: item.zoomLevel,
              })
            }}
          >
            <span>
              {item.name}
            </span>
            <Icon name="chevronRight" />
          </Button>
        </StyledItem>
      ))
    }
  </StyledItems>
)

export default List
