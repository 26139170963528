import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import useIsMobile from 'hooks/useIsMobile'
import CustomImage from '@/Image'
// import Video from '@/UI/Video'

import { StyledMediaWithTagImage } from './styles'

const Video = dynamic(() => import('@/UI/Video'), { ssr: true, })
const MediaWithTag = ({
  title,
  keyword,
  media,
  videoMobile
}) => {
  const isMobile = useIsMobile()

  const isVideo = media && media.data && media.data.attributes.mime.includes('video')

  const mediaProps = useMemo(() => {
    const gotVideoMobile = videoMobile && videoMobile.data
    if (isVideo) {
      const videoProps = {
        type: 'video',
        title,
        sources: [{
          src: media.data.attributes.url,
          type: media.data.attributes.mime,
          size: 1280,
        }]
      }
      if (gotVideoMobile && isMobile) {
        videoProps.sources[0] = {
          src: videoMobile.data.attributes.url,
          type: videoMobile.data.attributes.mime,
          size: 720,
        }
      }

      return videoProps
    }

    return {
      as: 'div',
      media,
      rounded: 'big',
      tags: keyword ? [keyword] : false,
    }
  }, [isVideo, isMobile])

  return (
    isVideo
      ? <Video source={mediaProps} />
      : (
        <StyledMediaWithTagImage className='MediaWithTag'>
          <CustomImage {...mediaProps}  />
        </StyledMediaWithTagImage>
      )
  )
}


export default MediaWithTag
