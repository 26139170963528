import React, { useContext } from 'react'
import { object } from 'yup'

import { createValidationSchema } from 'services/models/Form'
import { GeneralContext } from 'contexts/General'

import CustomForm from '@/UI/CustomForm'

const Form = ({
  form,
  ...rest
}) => {
  const { page } = useContext(GeneralContext)
  if (!form) return <></>

  const {
    title,
    fields,
    endpoint,
    successMessage,
  } = form

  let validationSchema = {}

  fields.originalFieldset.forEach(({ fields: f }) => {
    const fieldsetValidationSchema = f.reduce(createValidationSchema, {})
    validationSchema = {
      ...validationSchema,
      ...fieldsetValidationSchema
    }
  })

  return (
    <CustomForm
      {...rest}
      {...fields}
      title={title}
      successMessage={successMessage}
      endpoint={endpoint}
      validateOnMount
      validationSchema={object().shape(validationSchema)}
      darkMode={page.pageDarkTheme}
      settings={fields.settings}
    />
  )
}


export default Form
