import {
  styled, rem
} from 'config/stitches.config'

export const StyledFooter = styled('footer', {
  color: '$white',
  backgroundColor: '$primary',
  paddingTop: rem(72),
  paddingBottom: rem(40),
  '@md': {
    paddingTop: rem(100),
    paddingBottom: 0,
  }
})

export const StyledFooterRow = styled('section', {
  display: 'block',
  width: '100%',
  paddingY: 0,
  '@md': {
    borderTop: '1px solid $white',
    paddingTop: rem(32),
    paddingBottom: rem(64),
  }
})

export const StyledFooterCol = styled('div', {})

export const StyledFooterLower = styled('div', {
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto',
  gridTemplateAreas: `
    "company iva"
    "menus menus"
    "menus menus"
  `,
  paddingTop: rem(32),

  '@md': {
    paddingTop: 0,
    gridTemplateColumns: '1fr 10fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
      "company menus iva"
    `,
  },
  '.LowerMenu': {
    paddingY: rem(32),
    'div': { marginTop: 0 },
    '&:last-child': {
      borderTop: '1px solid rgba(255,255,255,.2)',
      '@md': { borderTop: 'none' }
    },
    '@md': { paddingY: 0, }
  }
})
