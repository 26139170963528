import React, {
  useState, useCallback
} from 'react'
import AsyncSelect from 'react-select/async'
import { getEntity } from 'services/API'
import CustomField from './index'
import { Field } from 'formik'
import { StyledReactSelect } from './styles'
import FieldError from './Error'


const SelectField = ({
  placeholder,
  options = [],
  searchHandler,
  labels,
  field,
  form,
  onSelect
}) => {
  const [touched, setTouched] = useState(false)
  const onSearch = async (search) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // Call the intern API to have results
      try {
        const {
          entity, param, filter
        } = searchHandler
        let { data: results } = await getEntity({
          entity, params: { [param]: search }
        })
        // Format the results to fit the select
        if (!!filter && filter.label) {
          results = results.map(({ attributes }) => {
            if (!!attributes) {
              return {
                label: attributes[filter.label], value: attributes[filter.value]
              }
            }
            return null
          }).filter(r => !!r)
        }
        resolve(results)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching results', error)
        reject(error)
      }
    })
  }

  const handleChange = useCallback((selectedOption) => {
    form.setFieldValue(
      field.name,
      selectedOption.value
    )
    if (onSelect) {
      onSelect(selectedOption.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBlur = () => {
    setTouched(true)
  }

  return (
    <StyledReactSelect error={touched && !!form.errors[field.name]}>
      <AsyncSelect
        cacheOptions
        placeholder={placeholder}
        loadingMessage={() => labels.loading || 'Loading...'}
        noOptionsMessage={() => labels.noOptions || 'No options'}
        defaultOptions={options}
        closeMenuOnSelect
        classNamePrefix="react-select"
        loadOptions={onSearch}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FieldError touched={touched} error={form.errors[field.name]} />
    </StyledReactSelect>
  )
}

const ReactAsyncSelect = ({
  dependsOn, ...props
}) => {
  return (
    <CustomField dependsOn={dependsOn} field={props} >
      <Field
        {...props}
        component={SelectField}
      />
    </CustomField>
  )
}


export default ReactAsyncSelect
