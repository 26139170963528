import {
  styled,
  rem
} from 'config/stitches.config'

import Button from '@/UI/Button'

export const StyledTabs = styled('div', {
  width: '100%',
  // overflow: 'hidden',
})

export const StyledTabsHeader = styled('header', {
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '$2',


  variants: {
    withDropDown: {
      false: {
        overflowX: 'scroll',
        overscrollBehavior: 'contain',
        '&::-webkit-scrollbar': { height: 1, },

        '&::-webkit-scrollbar-track': { background: '$white', },

        '&::-webkit-scrollbar-thumb': { backgroundColor: '$secondary', },
        '@md': {
          overflowX: 'auto',
          columnGap: '$4',
          paddingBottom: '$2'
        },
      },
      true: {
        width: '100%',
        flexDirection: 'column',
        rowGap: '$3',

        '@md': { flexDirection: 'row', },

        '.TabsDropDown': {
          width: '100%',

          '@md': { width: 'auto', },

          'ul': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '$3',
            maxHeight: 220,
            overflowY: 'auto',
            marginRight: 8,

            'button': {
              minWidth: 140,
              textAlign: 'left',
              whiteSpace: 'normal',
              width: '100%',
            },

            '&::-webkit-scrollbar': {
              height: 1,
              width: 1
            },

            '&::-webkit-scrollbar-track': { background: '$gray4', },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '$black', },
          }
        },
      }
    }
  },
  defaultVariants: { withDropDown: false }
})

export const StyledTabsHeaderButton = styled(Button, {
  $$y: '0%',
  columnGap: rem(4),
  flexShrink: 0,
  position: 'relative',
  overflow: 'hidden',
  transition: 'color ease-in-out .4s, border-color ease-in-out .4s',
  transform: 'translateZ(0)',

  'sup': {
    position: 'relative',
    zIndex: 0,
    color: '$gray',
    fontSize: rem(8),
    lineHeight: rem(10),
  },

  '@md': {
    fontSize: '$p4',
    lineHeight: '$p4',
  },
  variants: {
    theme: { default: { border: '1px solid $primary', }, },
    hovered: { true: { color: '$black' } },
    selected: {
      true: {
        color: '$black',
        'sup': { color: '$primary' }
      },
      false: { '&:hover': { borderColor: '$secondary', }, }
    }
  },
  compoundVariants: [
    {
      theme: 'default',
      selected: true,
      css: {
        border: '1px solid $secondary',
        backgroundColor: '$secondary',
      }
    },
    {
      theme: 'noBackground',
      selected: true,
      css: {
        color: '$black',
        border: '1px solid $secondary',
        backgroundColor: '$secondary',
        'sup': { color: '$white' }
      }
    },
    {
      theme: 'noBackground',
      selected: false,
      css: {
        color: '$black',
        border: '1px solid $gray3'
      }
    },
  ]
})

export const StyledTabsContent = styled('div', {
  marginTop: rem(72),
  variants: { withDropDown: { true: { marginTop: rem(32), } } }
})


export const StyledTabsHeaderButtonHover = styled('span', {
  width: '150%',
  height: '200%',
  position: 'absolute',
  zIndex: 0,
  left: '50%',
  backgroundColor: '$secondary',
  br: '50%'
})
