import React from 'react'

import NavigationItem from './Item'

import { StyledNavigation } from './styles'

const Navigation = ({
  items,
  slug,
}) => {
  return (
    <StyledNavigation position={slug}>
      <ul>
        {items.map((item) => {
          return (
            <li key={item.title}>
              <NavigationItem {...item}/>
            </li>
          )
        })}
      </ul>
    </StyledNavigation>
  )
}

export default Navigation
