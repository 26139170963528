import React from 'react'
const mapPin = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 1.5C7.85786 1.5 4.5 4.85786 4.5 9C4.5 11.8025 6.38524 14.6808 8.3737 16.9178C9.35621 18.0231 10.3403 18.9459 11.0793 19.5925C11.4484 19.9155 11.7555 20.1688 11.9694 20.3407C11.9799 20.3491 11.99 20.3572 12 20.3652C12.01 20.3572 12.0201 20.3491 12.0306 20.3407C12.2445 20.1688 12.5516 19.9155 12.9207 19.5925C13.6597 18.9459 14.6438 18.0231 15.6263 16.9178C17.6148 14.6808 19.5 11.8025 19.5 9C19.5 4.85786 16.1421 1.5 12 1.5ZM12 21C11.7 21.4 11.6997 21.3998 11.6997 21.3998L11.6978 21.3983L11.6927 21.3945L11.674 21.3803C11.6578 21.368 11.6343 21.3499 11.6039 21.3264C11.5431 21.2793 11.4548 21.2101 11.3431 21.1202C11.1195 20.9406 10.8016 20.6783 10.4207 20.345C9.65973 19.6791 8.64379 18.7269 7.6263 17.5822C5.61476 15.3192 3.5 12.1975 3.5 9C3.5 4.30558 7.30558 0.5 12 0.5C16.6944 0.5 20.5 4.30558 20.5 9C20.5 12.1975 18.3852 15.3192 16.3737 17.5822C15.3562 18.7269 14.3403 19.6791 13.5793 20.345C13.1984 20.6783 12.8805 20.9406 12.6569 21.1202C12.5452 21.2101 12.4569 21.2793 12.3961 21.3264C12.3657 21.3499 12.3422 21.368 12.326 21.3803L12.3073 21.3945L12.3022 21.3983L12.3007 21.3994C12.3007 21.3994 12.3 21.4 12 21ZM12 21L12.3007 21.3994L12 21.625L11.6997 21.3998L12 21Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5ZM8.5 9C8.5 7.067 10.067 5.5 12 5.5C13.933 5.5 15.5 7.067 15.5 9C15.5 10.933 13.933 12.5 12 12.5C10.067 12.5 8.5 10.933 8.5 9Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.71315 20.1102C4.5388 20.2857 4.5 20.4155 4.5 20.5C4.5 20.6121 4.57362 20.8108 4.9258 21.071C5.26819 21.3239 5.79561 21.5728 6.49228 21.7905C7.87968 22.2241 9.82705 22.5 12 22.5C14.173 22.5 16.1203 22.2241 17.5077 21.7905C18.2044 21.5728 18.7318 21.3239 19.0742 21.071C19.4264 20.8108 19.5 20.6121 19.5 20.5C19.5 20.4155 19.4612 20.2857 19.2869 20.1102C19.1108 19.933 18.8278 19.7433 18.428 19.5579C17.6304 19.1882 16.4668 18.8807 15.0631 18.6941L15.1949 17.7028C16.6545 17.8968 17.9264 18.2232 18.8485 18.6507C19.3085 18.8639 19.7064 19.1137 19.9963 19.4054C20.2878 19.6989 20.5 20.0676 20.5 20.5C20.5 21.0783 20.1259 21.5373 19.6684 21.8753C19.2012 22.2205 18.557 22.5103 17.806 22.745C16.298 23.2163 14.2453 23.5 12 23.5C9.75468 23.5 7.70204 23.2163 6.19401 22.745C5.44297 22.5103 4.79881 22.2205 4.33156 21.8753C3.8741 21.5373 3.5 21.0783 3.5 20.5C3.5 20.0676 3.71216 19.6989 4.00373 19.4054C4.29357 19.1137 4.69149 18.8639 5.15147 18.6507C6.07357 18.2232 7.34549 17.8968 8.80511 17.7028L8.93688 18.6941C7.53319 18.8807 6.36961 19.1882 5.57204 19.5579C5.17219 19.7433 4.88922 19.933 4.71315 20.1102Z" fill="currentColor"/>
  </svg>
)

export default mapPin
