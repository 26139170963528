
import React, { forwardRef } from 'react'
import Link from 'next/link'
import Icon from '@/UI/Icon'

import { StyledCta } from './styles'

import { HEADER_HEIGHT } from 'config/stitches.config'

const ICON_CLASSNAME = 'Cta-icon'

const DefaultCta = (props, forwardedRef) => {
  const {
    icon = null,
    iconPosition = 'right',
    children
  } = props

  const handleAnchorClick = (e) => {
    e.preventDefault()
    const scrollTo = document.querySelector(`[data-section-index="${props.href.replace('#', '')}"]`)
    if (!scrollTo) return

    window.scrollTo({
      top: scrollTo.offsetTop - HEADER_HEIGHT.desktop,
      behavior: 'smooth'
    })
  }

  const isAnchor = props.href && props.href.startsWith('#')
  const anchorProps = isAnchor ? { onClick: handleAnchorClick, } : {}

  return (
    <StyledCta
      as={isAnchor ? 'a' : Link}
      {...props}
      {...anchorProps}
      ref={forwardedRef}
    >
      {
        icon &&
        iconPosition === 'left' &&
        <Icon className={ICON_CLASSNAME} name={icon} />
      }

      {
        children &&
        <span>
          {children}
        </span>
      }

      {
        icon &&
        iconPosition === 'right' &&
        <Icon className={ICON_CLASSNAME} name={icon} />
      }
    </StyledCta>
  )
}

export default forwardRef(DefaultCta)
