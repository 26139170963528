import {
  styled,
  rem
} from 'config/stitches.config'

import Text from '@/UI/Text'

export const StyledNewsletter = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  paddingY: rem(64),
  gap: rem(32),
  [Text]: { flexBasis: '100%' },
  '@md': {
    flexWrap: 'nowrap',
    [Text]: { flexBasis: '35%' },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
})

export const StyledNewsletterForm = styled('div', {

  color: '$black',
  flexBasis: '100%',
  'form': {
    width: '100%',

    '@md': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '[data-type="email"]': { marginBottom: 0 },
    '[data-type="checkbox"]': {
      position: 'absolute',
      color: '$white',
      marginLeft: rem(16),
      maxWidth: 'calc(100% - 64px)',
      'input[type="checkbox"] + label::before': { borderColor: '$white' },
      '@md': {
        marginTop: rem(16),
        marginLeft: 0
      }
    },
    'input': {
      borderBottom: 'none !important',
      marginBottom: rem(16),
      paddingY: 0,
      br: 110,
      backgroundColor: '$white !important',
      textIndent: `${rem(16)} !important`,
      fontSize: '$p1-mobile !important',
      '&::placeholder': { color: 'red', },
      '@md': {
        textIndent: '0 !important',
        br: 0,
        marginBottom: 0,
        backgroundColor: 'transparent',
      }
    },
    'button': {
      backgroundColor: '$black',
      height: 57,
      marginTop: rem(48),

      fontSize: `${rem(15)} !important`,
      lineHeight: `${rem(18)} !important`,
      '@md': {
        width: `${rem(145)} !important`,
        marginTop: 0
      }
    }
  },
  'fieldset': { margin: 0 },
  '@md': {
    backgroundColor: '$white',
    br: 110,

    flexBasis: '45%',
    paddingLeft: rem(32),
    paddingRight: 2,
    paddingY: 2,
  },
})
