import {
  styled, rem
} from 'config/stitches.config'

import SimpleLink from '@/UI/Link'

export const StyledNavigation = styled('nav', {
  ul: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    a: {
      textDecoration: 'none', width: '100%'
    },
  },
  variants: {
    position: {
      'upper-menu': {
        paddingRight: '$3',
        ul: {
          columnGap: rem(32),
          '@lg': { columnGap: rem(48), }
        },

        'button, a': {
          color: '$gray1',
          fontSize: '$p4',
          lineHeight: '$p4',
        },
        '.icon': {
          width: 'auto',
          marginLeft: '$1',
          svg: { fill: 'none' },
        },
      },
      'lower-menu': {
        ul: { columnGap: rem(80) },
        a: {
          color: '$black',
          fontSize: '$p2',
          lineHeight: '$p2',
          fontWeight: '$1',
        },
      },
    },
  },
})

export const StyledNavigationItem = styled(SimpleLink, {
  variants: {
    highlighted: {
      true: {
        backgroundColor: '$primary',
        textAlign: 'center',
        color: '$white',
        span: { color: '$white' },
        '@md': {
          paddingY: rem(10),
          paddingX: rem(24),
          br: 80,
        },
      },
    },
  },
})
