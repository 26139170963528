import React from 'react'

import Stores from './Stores'
import Map from './Map'

import {
  StyledStoreLocator,
  StyledStoreLocatorContainer
} from './styles'

const StoreLocatorDesktop = ({
  ctaLabel,
  breadcrumbLabel,
  stores,
  count,
  state,
  dispatch,
  storeToggleState,
  setStoreToggleState,
}) => {
  return (
    <StyledStoreLocator>
      <StyledStoreLocatorContainer>
        <Map
          state={state}
          dispatch={dispatch}
          isMobile={false}
          setStoreToggleState={setStoreToggleState}
        />
        <Stores
          storeToggleState={storeToggleState}
          setStoreToggleState={setStoreToggleState}
          label={ctaLabel}
          breadcrumbLabel={breadcrumbLabel}
          items={stores}
          count={count}
          state={state}
          dispatch={dispatch}
          isMobile={false}
        />
      </StyledStoreLocatorContainer>
    </StyledStoreLocator>
  )
}

export default StoreLocatorDesktop
