import Link from 'next/link'
import { StyledDropListContainer } from '@/UI/Dropdown/styles'
import {
  rem,
  styled
} from 'config/stitches.config'

export const StyledLanguages = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  columnGap: rem(31),
  padding: 0,
  margin: 0,
  listStyle: 'none',
  'button': { color: '$gray1' },
  '&.MobileLanguages': {
    '[data-open="true"] > button': { width: 'auto !important' },
    [`${StyledDropListContainer}`]: {
      paddingY: 0,
      paddingLeft: 50,
      height: '100%',
      '> div': { height: '100%', },

      'ul': {
        display: 'inline-flex',
        alignItems: 'center',
        height: '100%',
        marginTop: 0,
        gap: rem(8)
      }
    }
  }
})

export const StyledLanguagesLink = styled(Link, {
  color: '$darkGray',
  textTransform: 'uppercase',
  textDecoration: 'none',

  textAlign: 'left',
  '&:hover': { textDecoration: 'underline', },
  variants: { currentLang: { true: { textDecoration: 'underline', } } }
})
