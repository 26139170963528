import React, {
  useState, useRef, useEffect
} from 'react'
import {
  motion,
  AnimatePresence
} from 'framer-motion'
import { useRouter } from 'next/router'

import DropDownProvider from 'contexts/DropDown'
import Icon from '@/UI/Icon'

import {
  StyledDropDown,
  StyledDropTrigger,
  StyledDropListContainer,
  StyledDropList,
} from './styles'


const DropDown = ({
  children,
  trigger,
  theme,
  triggerProps = {},
  onClick,
  initialState = false,
  className,
  overlap = true,
}) => {
  if (!trigger) throw new Error('Trigger element is required')
  const [isOpen, setIsOpen] = useState(initialState)

  const containerRef = useRef(null)
  const triggerRef = useRef(null)
  const {
    asPath, locale
  } = useRouter()

  const onTriggerClick =  () => {
    setIsOpen(!isOpen)

    if (onClick) onClick()
  }

  useEffect(() => {
    let w = 'auto'
    if (!!isOpen) {
      const { width } = containerRef.current.getBoundingClientRect()
      w = `${width}px`
    }
    triggerRef.current.style.width = w
  }, [isOpen])

  useEffect(() => {
    if (isOpen) setIsOpen(false)
  }, [asPath, locale])

  return (
    <DropDownProvider setOpen={setIsOpen} isOpen={isOpen}>
      <StyledDropDown className={`${className ? className : ''}`} theme={theme} isOpen={isOpen} data-open={isOpen}>
        <StyledDropTrigger ref={triggerRef} onClick={onTriggerClick} {...triggerProps} >
          {trigger}
          <Icon name="chevron"/>
        </StyledDropTrigger>
        <StyledDropListContainer isOpen={isOpen} overlap={overlap} ref={containerRef}>
          <AnimatePresence>
            {
              isOpen && (
                <motion.div
                  style={{ width: '100%' }}
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <StyledDropList>
                    {children}
                  </StyledDropList>
                </motion.div>
              )

            }
          </AnimatePresence>
        </StyledDropListContainer>

      </StyledDropDown>
    </DropDownProvider>
  )
}

export default DropDown
