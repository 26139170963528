import React from 'react'

import CustomImage from '@/Image'

import { StyledImagesList } from './styles'

const ImagesList = ({
  items,
  layout
}) => (
  items &&
  items.length &&
  <StyledImagesList layout={layout}>
    {
      items.map(({
        attributes,
        id
      }) => (
        <CustomImage
          key={id}
          href={attributes.href}
          rounded="normal"
          theme="inverted"
          {...attributes.link}
          {...attributes.image}
        />
      ))
    }
  </StyledImagesList>
)

export default ImagesList
