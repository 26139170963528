const globals = {
  '*': {
    boxSizing: 'border-box', fontFamily: '$base'
  },
  'body, html': {
    width: '100%',
    minHeight: '100%',
    margin: 0,
    cursor: 'default',
  },
  html: {
    backgroundColor: '$primary',
    scrollBehavior: 'initial'
  },
}

export default globals
