import Form from './Form'

const FormList = async (data) => {
  const {
    form,
    ...rest
  } = data

  if (!form.data) return rest

  const formTabs = []
  const formList = form.data.map((singleForm) => {
    const formData = { form: { data: singleForm } }
    const formNormalized = Form(formData)
    formNormalized.id = singleForm.id
    formTabs.push({
      id: singleForm.id,
      title: formNormalized.form.title,
      size: 'big'
    })
    return formNormalized
  })

  const formTab = {
    header: formTabs,
    content: formList
  }

  rest.formTab = formTab
  return rest
}

export default FormList
