import React, { useContext } from 'react'

import dynamic from 'next/dynamic'

import { MenuContext } from 'contexts/Menu'

import useIsMobile from 'hooks/useIsMobile'

import Logo from '@/Logo'
import Languages from '@/Languages'
import HamburgerMenu from '@/Buttons/HamburgerMenu'
import SearchButton from '@/Buttons/SearchButton'

import Container from '@/UI/Container'

import Navigation from './Navigation'

const MenuModal = dynamic(() => import('@/Modals/MenuModal'), { ssr: false, })

import {
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderUpper,
  StyledHeaderLower,
  StyledHeaderUpperContent,
} from './styles'
import GlobalButton from '@/Buttons/Global'

const Header = () => {
  const {
    menus,
    menuOpen,
    menuChangeHandler
  } = useContext(MenuContext)
  const isMobile = useIsMobile('middle')

  return (
    <StyledHeader
      isOpen={menuOpen}
    >
      <Container>
        <StyledHeaderContainer>
          <StyledHeaderUpper>
            <Logo />

            <StyledHeaderUpperContent>
              {
                !isMobile &&
                isMobile !== undefined &&
                menus.header &&
                !!menus.header.length &&
                <Navigation {...menus.header[0]}/>
              }

              <HamburgerMenu
                menuOpen={menuOpen}
                menuChangeHandler={menuChangeHandler}
                cursor={{ type: 'hidden' }} />

              <SearchButton />
              {
                !isMobile &&
                isMobile !== undefined &&
                <>
                  <GlobalButton />
                  <Languages />
                </>
              }

            </StyledHeaderUpperContent>
          </StyledHeaderUpper>

          {
            !isMobile &&
            isMobile !== undefined &&
            menus.header &&
            !!menus.header.length &&
            <StyledHeaderLower>
              <Navigation {...menus.header[1]}/>
            </StyledHeaderLower>
          }

        </StyledHeaderContainer>
      </Container>
      <MenuModal />
    </StyledHeader>
  )
}

export default Header
