import { styled } from 'config/stitches.config'

export const StyledIcon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  size: '100%',
  '> svg': {
    'display': 'block',
    'margin': 0,
  },
  variants: {
    theme: {
      default: {
        '> svg': {
          color: '$gray1', fill: '$gray1'
        }
      },
      inverted: {
        '> svg': {
          color: '$white', fill: '$white'
        }
      }
    }
  },
  defaultVariants: { theme: 'default' }
})
