import { ENDPOINTS, } from 'config'

import { models } from '../models'

export const getApiUrl = () => {
  // console.debug(' process.env.API_URL,',  process.env.NEXT_PUBLIC_API_URL,)
  return process.env.NEXT_PUBLIC_API_URL
}
const fetchDataMemo = (originalFunction) => {
  return async (...args) => {
    const [url, opt, raw] = args
    if (typeof window === 'undefined') {
      const crypto = require('crypto')
      const fs = require('fs')
      const path = require('path')
      const hash = crypto.createHash('sha256')
      hash.update(url)

      if (!fs.existsSync('./api-cache/')) {
        fs.mkdirSync('./api-cache/')
      }

      const filePath = path.resolve(`./api-cache/${hash.digest('hex')}.json`)
      if (fs.existsSync(filePath)) {
        const data = JSON.parse(fs.readFileSync(filePath, 'utf8'))
        return data
      }
      const data = await originalFunction(url, opt, raw)
      fs.writeFileSync(filePath, JSON.stringify(data), 'utf8')
      return data
    }
    return await originalFunction(url, opt, raw)
  }
}


export const fetchData =  fetchDataMemo(async (url, options = {}) => {
  let response
  try {
    console.debug(
      '\x1b[32m',
      `Calling Url ${url}`
    )
    const request = await fetch(url, options)
    // console.log('REQUEST', request.headers)
    response = await request.json()
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === 'development') {
      response = error
    }
  }
  return response
})

const getEntity = async ({
  entity,
  params = {},
  qs = ''
}) => {
  const baseUrl = getApiUrl()

  let endpoint = ENDPOINTS[entity]
  const queryString = params ? new URLSearchParams(params).toString() : qs

  if (queryString) endpoint = `${endpoint}?${queryString}`

  const apiURL = `${baseUrl}${endpoint}`

  const data = await fetchData(apiURL)
  data.entity = entity

  return data
}

const getAllResultsFromEntity = async ({
  entity,
  params = {},
  qs
}, environment) => {
  // We make the first call to know if there are other pages
  const {
    data, meta
  } = await getEntity({
    entity, params, qs
  }, environment)
  // if data is an array we get a collection of pages, otherwise it's a single type (homepage, search-results)
  // eslint-disable-next-line no-nested-ternary
  const _data = !!data ? Array.isArray(data) ? data : [data] : []
  let results = _data.map(d => ({
    ...d, entity
  }))
  if (meta && meta.pagination && meta.pagination.pageCount > 1) {
    // If there are other pages I start to call from page 2 and add them to results
    const promises = []
    for (let index = 2; index <= meta.pagination.pageCount; index++) {
      const searchUrl = params ? new URLSearchParams(params) : ''
      promises.push(getEntity({
        entity, params: null, qs: `${searchUrl}${qs ? qs : ''}&pagination[page]=${index}`
      }, environment))
    }
    const allResults = await Promise.all(promises)
    results = [...results, ...allResults.map(({
      data: newPageResults, entity: newPageEntity
    }) => {
      return newPageResults.map(d => ({
        ...d, entity: newPageEntity
      }))
    }).flat()]
  }
  return results
}

export const getForm = async (formID) => {
  if (!formID) throw new Error('formID is required')
  const baseUrl = getApiUrl()
  const apiURL = `${baseUrl}${ENDPOINTS.forms}/${formID}`
  const { data } = await fetchData(apiURL)

  if (!data) return {}

  return data
}

export const getMenus = async () => {
  const baseUrl = getApiUrl()
  const apiURL = `${baseUrl}${ENDPOINTS.menus}`
  const data = await fetchData(apiURL)
  return data
}


const getFooter = async (locale) => {
  const data = await getEntity({
    entity: 'footer',
    params: {
      locale,
      populate: 'deep,5'
    }
  })

  const footerData = await models.Footer(data)
  return footerData
}

const getHeaderMenu = async (locale) => {
  const baseUrl = getApiUrl()
  const params = {
    type: 'TREE',
    locale,
  }

  const queryString = new URLSearchParams(params).toString()

  const apiURL = `${baseUrl}${ENDPOINTS.navigation}?${queryString}`

  const data = await fetchData(apiURL)
  const headerMenuData = await models.MainMenu(data)
  return headerMenuData
}

const getHeaderFooterData = async ({ locale }) => {
  const [
    headerMenu,
    footerData
  ] = await Promise.all([
    getHeaderMenu(locale),
    getFooter(locale)
  ])

  return {
    headerMenu,
    footerData
  }
}


const getOptions = async ({ locale }) => {
  const data = await getEntity({
    entity: 'options',
    params: {
      locale,
      populate: 'deep'
    }
  })

  if (!data.data) return {}

  const options = await models.Options(data)

  return options
}

const getRedirects =  async () => {
  const baseUrl = getApiUrl()
  const apiURL = `${baseUrl}${ENDPOINTS.redirects}`
  const data = await fetchData(apiURL)

  if (!data.length === 0) return {}

  return data
}

const getPageData = async ({
  entity,
  slug = '',
  locale = 'it',
}) => {
  const baseUrl = getApiUrl()

  const options = await getOptions({ locale })
  let apiURL =  `${baseUrl}${ENDPOINTS[entity]}`

  const params = {
    'filters[slug]': slug,
    locale,
    populate: 'deep'
  }

  const queryString = new URLSearchParams(params).toString()

  apiURL = `${apiURL}?${queryString}`

  const data = await fetchData(apiURL)
  data.options = options

  return data
}

export {
  getPageData,
  getEntity,
  getAllResultsFromEntity,
  getHeaderFooterData,
  getOptions,
  getRedirects,
  models,
}
