import Form from './Form'
import {
  getParent,
  getShortenedLocale
} from 'services/API/helpers'

const getLinkProps = ({ data } = {}, label) => {
  if (!data) return false
  const {
    slug,
    parent,
    locale
  } = data.attributes

  let href = `/${slug}`
  if (parent) {
    const parentEl = getParent(parent)

    href = parentEl ? `/${parentEl}${href}` : href
  }

  return {
    id: `${data.id}-${label.replace(/\s/g, '-').toLowerCase()}`,
    label,
    href,
    locale
  }
}


const getMenus = (data) => {
  if (!data) return []

  let { data: menuData } = data

  if (!menuData && Array.isArray(data)) {
    menuData = data.map(d => ({
      id: d.id, attributes: {
        ...d, locale: null
      }
    }))
  } else if (!menuData) {
    return []
  }
  return (Array.isArray(menuData) ? menuData : [menuData]).map(({
    attributes,
    id
  }) =>{
    const {
      urlIndeed,
      title,
      locale,
      slug,
      parent,
      url,
    } = attributes

    let href

    if ('urlIndeed' in attributes) {
      href = urlIndeed
    } else {
      href = url || `/${slug}`
      if (parent) {
        const parentEl = getParent(parent)
        href = parentEl ? `/${parentEl}${href}` : href
      }
    }

    return {
      id,
      label: title,
      href,
      locale: getShortenedLocale(locale),
      target: url ? '_blank' : '_self'
    }
  })
}

const Footer = async (data) => {
  if (!data.data) return null

  const { attributes } = data.data
  const {
    title,
    newsletter,
    upperMenu,
    lowerMenu
  } = attributes

  const normalizedData = {
    payoff: title,
    upper: null,
    lower: null
  }

  if (upperMenu) {
    const upper = Object.keys(upperMenu)
      .filter(k => typeof upperMenu[k] === 'object' && !Array.isArray(upperMenu[k]) && upperMenu[k])
      .map(key => {
        const {
          title: label,
          page,
          relation,
          description,
          ...otherMenuProps
        } = upperMenu[key]

        let header = getLinkProps(page, label) || null
        if (!header && label) header = { label }
        let main = getMenus(relation)

        if (description) main = [{ description }, ...main]

        const formattedMenu = {
          ...otherMenuProps,
          header,
          main,
        }

        return formattedMenu
      })
      .sort((a, b) => a.order - b.order)

    Object.assign(normalizedData, { upper })
  }

  if (lowerMenu) {
    const lower = Object.keys(lowerMenu)
      .filter(k => typeof lowerMenu[k] === 'object' && lowerMenu[k])
      .map(key => {
        const main = lowerMenu[key].map(({
          title: label,
          url: href,
          ...otherMenuProps
        }) => ({
          label: label || null,
          href,
          ...otherMenuProps
        }))

        const lowerMenuFormatted = {
          className: 'LowerMenu',
          type: 'MenuItem',
          collapsable: false,
          direction: 'row',
          main,
        }

        return lowerMenuFormatted
      })

    const {
      company,
      vatNumber
    } = lowerMenu

    Object.assign(normalizedData, {
      lower,
      company,
      vatNumber
    })
  }

  const newsletterForm = !newsletter || newsletter.data === null ? null : await Form({ form: newsletter })

  Object.assign(normalizedData, { newsletterForm })

  return normalizedData
}

export default Footer
