import React from 'react'

import RichText from '@/UI/RichText'

import { StyledMenuItemLink, } from './styles'
import Icon from '@/UI/Icon'

const MenuItem = ({
  title,
  description,
  icon,
  ...menuItemProps
}) => (
  description
    ? <RichText className='FooterInfo' as='div' content={description} />
    : (
      <StyledMenuItemLink {...menuItemProps} title={icon ? icon : false}>
        {
          title && <span>{title}</span>
        }

        {
          !!icon &&
            <Icon theme="inverted" name={icon} />
        }
      </StyledMenuItemLink>
    )
)


export default MenuItem
