import React from 'react'

import SectionContainer from '@/Section/Container'
import CustomImage from '@/UI/Image'

const SimpleContent = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent,
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    {
      sectionContent.media &&
      <CustomImage rounded="normal" {...sectionContent.media}/>
    }
  </SectionContainer>
)

export default SimpleContent
