import React, {
  useRef,
  useEffect,
  useCallback
} from 'react'

import { gsap } from 'gsap'
import SplitText from 'gsap/dist/SplitText'

import colors from 'theme/colors'

import { StyledAnimatedParagraph } from './styles'

const ParagraphWithAnimation = ({
  theme,
  content
}) => {
  const paragraph = useRef(null)

  const animationCallback = useCallback(() => {
    const paragraphSplitted = new SplitText(paragraph.current, { type: 'words' })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: paragraph.current.closest('.onscrollparagraph'),
        start: 'top center+=100',
        end: 'bottom bottom',
        scrub: 0.5,
        // markers: true,
        once: true
      }
    })

    tl.to(paragraphSplitted.words, {
      color: theme === 'inverted' ? colors.white : colors.black,
      stagger: 0.3,
    })
  }, [theme])

  useEffect(animationCallback, [animationCallback])

  if (!content) return <></>

  return (
    <StyledAnimatedParagraph
      theme={theme}
      ref={paragraph}
      as="p"
      size={{
        '@initial': 'big-text-mobile',
        '@md': 'big-text-desk'
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default ParagraphWithAnimation
