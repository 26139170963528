import {
  styled,
  rem,
} from 'config/stitches.config'

import Cta from '@/UI/Cta/DefaultCta'

Cta.toString = () => '.FormSubmit'

export const StyledFieldset = styled('fieldset', {
  all: 'unset',
  display: 'block',
  width: '100%',
  borderColor: 'transparent',
  margin: `0 0 ${rem(72)} 0`,
  padding: 0,
  '&:last-child': { margin: 0 }
})

export const StyledFieldsetTitle = styled('div', {
  display: ' block',
  marginBottom: '$3',
  'strong': { color: '$gray3' }
})

export const StyledFieldsetContent = styled('div', {})

export const StyledFormSubmit = styled('div', {
  [' .FormSubmit']: {
    width: '100%',
    justifyContent: 'center',
    fontSize: '$cta-primary-mobile',
    lineHeight: '$cta-primary-mobile',

    '@md': {
      fontSize: '$big-cta-desk',
      lineHeight: '$big-cta-desk',
    }
  },
})
