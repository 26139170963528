import React from 'react'

const automotive = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="10" fill="#26376D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6667 44C16.6667 42.1591 18.159 40.6667 20 40.6667H44C45.8409 40.6667 47.3333 42.1591 47.3333 44C47.3333 45.841 45.8409 47.3334 44 47.3334H20C18.159 47.3334 16.6667 45.841 16.6667 44ZM20 42C18.8954 42 18 42.8955 18 44C18 45.1046 18.8954 46 20 46H44C45.1046 46 46 45.1046 46 44C46 42.8955 45.1046 42 44 42H20Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3333 24C19.3333 23.6318 19.6318 23.3334 20 23.3334L25.3333 23.3334C25.7015 23.3334 26 23.6318 26 24C26 24.3682 25.7015 24.6667 25.3333 24.6667L20 24.6667C19.6318 24.6667 19.3333 24.3682 19.3333 24Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6667 24.6667C20.2985 24.6667 20 24.3682 20 24L20 21.3334C20 20.2288 20.8954 19.3334 22 19.3334L23.3333 19.3334C24.4379 19.3334 25.3333 20.2288 25.3333 21.3334L25.3333 24C25.3333 24.3682 25.0349 24.6667 24.6667 24.6667C24.2985 24.6667 24 24.3682 24 24L24 21.3334C24 20.9652 23.7015 20.6667 23.3333 20.6667L22 20.6667C21.6318 20.6667 21.3333 20.9652 21.3333 21.3334L21.3333 24C21.3333 24.3682 21.0349 24.6667 20.6667 24.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.6667 27.3334C22.2985 27.3334 22 27.0349 22 26.6667L22 24C22 23.6319 22.2985 23.3334 22.6667 23.3334C23.0349 23.3334 23.3333 23.6319 23.3333 24L23.3333 26.6667C23.3333 27.0349 23.0349 27.3334 22.6667 27.3334Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M24.6667 21.3334C24.6667 20.9652 24.9651 20.6667 25.3333 20.6667H44C44.3682 20.6667 44.6667 20.9652 44.6667 21.3334C44.6667 21.7015 44.3682 22 44 22H25.3333C24.9651 22 24.6667 21.7015 24.6667 21.3334Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M38.2207 20.8378C38.4944 20.5915 38.9159 20.6137 39.1622 20.8874L45.1622 27.5541C45.4085 27.8277 45.3863 28.2493 45.1126 28.4956C44.839 28.7419 44.4174 28.7197 44.1711 28.446L38.1711 21.7793C37.9248 21.5057 37.947 21.0841 38.2207 20.8378Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.2842 34.3496C20.91 34.2315 19.8072 35.474 20.0924 36.8048L20.5811 39.0857C20.7787 40.0079 21.5937 40.6667 22.5367 40.6667H38.7966C39.7397 40.6667 40.5546 40.0079 40.7522 39.0857L41.241 36.8048C41.5262 35.474 40.4234 34.2315 39.0491 34.3496C36.549 34.5645 33.2279 34.8 30.6667 34.8C28.1054 34.8 24.7843 34.5645 22.2842 34.3496ZM18.7886 37.0842C18.3119 34.8593 20.1495 32.8279 22.3984 33.0212C24.8934 33.2356 28.1664 33.4667 30.6667 33.4667C33.167 33.4667 36.4399 33.2356 38.935 33.0212C41.1839 32.8279 43.0215 34.8593 42.5447 37.0842L42.0559 39.3651C41.7266 40.902 40.3684 42 38.7966 42H22.5367C20.9649 42 19.6067 40.902 19.2774 39.3651L18.7886 37.0842Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.1876 30C26.6273 30 25.1703 30.7798 24.3047 32.0781L23.2214 33.7032C23.0171 34.0095 22.6032 34.0923 22.2969 33.8881C21.9905 33.6838 21.9077 33.2699 22.112 32.9636L23.1953 31.3385C24.3081 29.6693 26.1815 28.6667 28.1876 28.6667H33.1457C35.1518 28.6667 37.0252 29.6693 38.138 31.3385L39.2214 32.9636C39.4256 33.2699 39.3428 33.6838 39.0365 33.8881C38.7301 34.0923 38.3162 34.0095 38.112 33.7032L37.0286 32.0781C36.1631 30.7798 34.706 30 33.1457 30H28.1876Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3333 36C19.3333 35.6319 19.6318 35.3334 20 35.3334H24C24.3682 35.3334 24.6667 35.6319 24.6667 36C24.6667 36.3682 24.3682 36.6667 24 36.6667H20C19.6318 36.6667 19.3333 36.3682 19.3333 36Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.6667 36C36.6667 35.6319 36.9651 35.3334 37.3333 35.3334H41.3333C41.7015 35.3334 42 35.6319 42 36C42 36.3682 41.7015 36.6667 41.3333 36.6667H37.3333C36.9651 36.6667 36.6667 36.3682 36.6667 36Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.6667 39.3333C26.2985 39.3333 26 39.6318 26 40V41.3333C26 41.7015 25.7015 42 25.3333 42C24.9651 42 24.6667 41.7015 24.6667 41.3333V40C24.6667 38.8954 25.5621 38 26.6667 38H34.6667C35.7712 38 36.6667 38.8954 36.6667 40V41.3333C36.6667 41.7015 36.3682 42 36 42C35.6318 42 35.3333 41.7015 35.3333 41.3333V40C35.3333 39.6318 35.0349 39.3333 34.6667 39.3333H26.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18 32C18 30.8954 18.8954 30 20 30H21.3333C22.4379 30 23.3333 30.8954 23.3333 32C23.3333 33.1046 22.4379 34 21.3333 34H20C18.8954 34 18 33.1046 18 32ZM20 31.3333C19.6318 31.3333 19.3333 31.6318 19.3333 32C19.3333 32.3682 19.6318 32.6667 20 32.6667H21.3333C21.7015 32.6667 22 32.3682 22 32C22 31.6318 21.7015 31.3333 21.3333 31.3333H20Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M38 32C38 30.8954 38.8954 30 40 30H41.3333C42.4379 30 43.3333 30.8954 43.3333 32C43.3333 33.1046 42.4379 34 41.3333 34H40C38.8954 34 38 33.1046 38 32ZM40 31.3333C39.6318 31.3333 39.3333 31.6318 39.3333 32C39.3333 32.3682 39.6318 32.6667 40 32.6667H41.3333C41.7015 32.6667 42 32.3682 42 32C42 31.6318 41.7015 31.3333 41.3333 31.3333H40Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M45.3333 20.6666C44.9651 20.6666 44.6667 20.9651 44.6667 21.3333C44.6667 21.7015 44.9651 22 45.3333 22C45.7015 22 46 21.7015 46 21.3333C46 20.9651 45.7015 20.6666 45.3333 20.6666ZM43.3333 21.3333C43.3333 20.2287 44.2288 19.3333 45.3333 19.3333C46.4379 19.3333 47.3333 20.2287 47.3333 21.3333C47.3333 22.4379 46.4379 23.3333 45.3333 23.3333C44.2288 23.3333 43.3333 22.4379 43.3333 21.3333Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M45.3808 22.0017C45.7481 22.028 46.0245 22.3469 45.9983 22.7142L44.665 41.3809C44.6387 41.7481 44.3198 42.0246 43.9525 41.9983C43.5852 41.9721 43.3088 41.6531 43.335 41.2859L44.6684 22.6192C44.6946 22.2519 45.0136 21.9755 45.3808 22.0017Z" fill="white"/>
  </svg>
)

export default automotive
