import { models } from '../models'
import locales from '../../locales.json'

export const rewriteLocale = (locale, format = 'full') => {
  const full = format === 'full'
  const fullLocale = locales.find(l => l[full ? 'shortenedCode' : 'code'] === locale)
  return fullLocale ? fullLocale[full ? 'code' : 'shortenedCode'] : locale
}

const rewriteSlug = (locale, slug, redirects = null) => {
  /**
   * IMPORTANT!
   * This is a workaround developed 'cause unique slug for locale doesn't work on Strapi
   * It works ONLY if the slug to be redirect is in the redirects list and if the slug
   * itself ends with '-eng'
   */
  if (locale !== 'en' || !slug.endsWith('-eng') || !redirects) return [...slug.split('/')]

  const toBeRedirect = redirects.find(({ source } = {}) => source.endsWith(slug))

  if (!!toBeRedirect) {
    const slugNormalized = slug.replace('-eng', '')
    return [slugNormalized]
  }

  return [...slug.split('/')]
}

export const getShortenedLocale = locale => !!locale ? locale.split('-')[0] : null

export const getParent = parent => {
  if (typeof parent === 'string') return parent

  if (!parent.data) return false

  return parent.data.attributes.slug
}

export const generateSlug = (attributes, redirects) => {
  if (attributes.homepage) return false

  if (!attributes.slug) return []

  if (!!attributes.parent) {
    const parent = getParent(attributes.parent)
    return [parent, ...rewriteSlug(attributes.locale, attributes.slug, redirects)].filter(Boolean)
  }

  return [...rewriteSlug(attributes.locale, attributes.slug, redirects)]
}

const generatePageIndex = (sections) => {
  if (!sections || sections.length === 0) return null

  return sections.filter(s => s.indexLabel)
    .map(section => {
      const target = section.indexLabel.toLowerCase().replace(/\s+/g, '-')
      return {
        label: section.indexLabel,
        target
      }
    })
}


export const normalizeData = async (dataToNormalize = [], locale, additionalData, pageMeta) => {
  const data = []
  for (const section of dataToNormalize) {
    let _section = section
    const type = section.type ? section.type.replace(/\s+/g, '-') : null
    if (models[type]) {
      _section = await models[type](_section, locale, additionalData, pageMeta)
    }
    data.push(_section)
  }

  return data
}


export const normalizeMetadata = async (data, options) => {
  const metadata = data.seo || {}
  metadata.defaultSeo = {}
  metadata.defaultSeo.title = metadata.metaTitle || `${data.title}${options.metaDefaultTitle ? ' | ' + options.metaDefaultTitle  : ''}`
  metadata.defaultSeo.description = metadata.metaDescription || options.metaDefaultDescription || data.excerpt  || ''
  metadata.defaultSeo.image = metadata.metaImage || {}

  return metadata
}

const getTranslatedUrls = (localizations, data, redirects) => {
  let i18nUrls = []
  const currentUrl = generateSlug(data, redirects)
  i18nUrls = [{
    href: `/${currentUrl ? currentUrl.join('/') : ''}`,
    locale: rewriteLocale(data.locale, 'shortened')
  }]

  if (!localizations || !localizations.data || localizations.data.length === 0) return i18nUrls

  const localizedUrls = localizations.data.map(({ attributes }) => {
    const { locale } = attributes
    const url = generateSlug(attributes, redirects)
    const localeUrl = {
      href: `/${url ? url.join('/') : ''}`,
      locale: rewriteLocale(locale, 'shortened')
    }
    return localeUrl
  })

  i18nUrls = [...i18nUrls, ...localizedUrls]
  return i18nUrls
}

export const normalizeAdditionalData = async (data, redirects) => {
  const {
    index,
    darkTheme,
    slug,
    homepage = false,
    removeFooter = false,
    title,
    scenarios = null,
    services = null,
    localizations,
    jobLocations = null,
    jobCountries = null,
  } = data

  const i18nUrls = getTranslatedUrls(localizations, data, redirects)

  const page = {
    slug,
    homepage,
    pageDarkTheme: !!darkTheme,
    removeFooter,
    title,
    index: index ? generatePageIndex(data.sections) : false,
    i18nUrls,
    jobLocations,
    jobCountries,
  }

  const additionalData = {
    page,
    scenarios,
    services,
  }


  return additionalData
}


export const normalizeResults = (data) => {
  if (!data || data.length === 0) return []
  const clusteredData = data.reduce((acc, curr) => {
    const cluster = curr.contentTypeLabel
    if (!acc[cluster]) acc[cluster] = []

    const link = {
      locale: rewriteLocale(curr.locale, 'shortened'),
      href: curr.homepage ? '/' : `/${generateSlug(curr).join('/')}`
    }

    Object.assign(curr, { link })

    acc[cluster].push(curr)
    return acc
  }, {})

  const formattedData = Object.entries(clusteredData).map(([cluster, items]) => ({
    cluster,
    items
  }))

  return formattedData
}
