import React from 'react'

const fashion = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="10" fill="#26376D"/>
    <path d="M39.8375 21.5312H45.0938V45.0938H17.9062V21.5312H23.1625" stroke="white" strokeWidth="1.6" strokeMiterlimit="10"/>
    <path d="M31.5 45.0938V38.75L37.8438 32.4062L36.9375 30.5938L41.4688 26.9688L38.75 17.9062H24.25L21.5312 26.9688L26.0625 30.5938L25.1562 32.4062L31.5 38.75" stroke="white" strokeWidth="1.6" strokeMiterlimit="10"/>
    <path d="M38.75 17.9062L31.5 31.5L24.25 17.9062" stroke="white" strokeWidth="1.6" strokeMiterlimit="10"/>
    <path d="M36.9375 39.6562H40.5625" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M31.5 31.5V38.75" stroke="white" strokeWidth="1.6" strokeMiterlimit="10"/>
  </svg>

)

export default fashion
