import {
  styled,
  rem
} from 'config/stitches.config'
import Link from '@/UI/Link'

export const StyledImage = styled(Link, {
  position: 'relative',
  overflow: 'hidden',
  '@md': {
    '&:hover': {
      '.SeparatedIconCta-icon': {
        opacity: 1,
        transform: 'translateX(0px)',
      }
    }
  },

})


export const StyledImageContent = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: rem(24)
})
