import {
  styled,
  rem
} from 'config/stitches.config'

import SimpleLink from '@/UI/Link'

SimpleLink.toString = () => 'ServicePreviewLink'


export const StyledServices = styled('div', {
  display: ' flex',
  alignItems: ' flex-end',
  flexWrap: ' wrap',
  columnGap: '$4',
  rowGap: '$2',
  marginTop: rem(64),
  '@md': {
    rowGap: '$4',
    marginTop: rem(100)
  }
})

export const StyledServicePreview = styled('div', {
  width: '100%',
  minWidth: 0
})

export const StyledServicePreviewContainer = styled(SimpleLink, {
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: rem(35, 26),
  transition: 'background-color ease-in-out .4s, color ease-in-out .4s',
  textDecoration: 'none',
  backgroundColor: '$white',
  br: 20,
  height: '100%',
  boxShadow: '0px 2px 64px rgba(51, 51, 51, 0.03)',
  border: '1px solid #D3D3D3',

  'svg, object, object embed svg': { fill: '$primary !important' },
  '@md': {
    border: 'none',
    // minHeight: 360,
    '&:hover': {
      backgroundColor: '$primary',
      color: '$white',
      '.icon': { backgroundColor: '$white' },
      'p': { color: '$lightGray' },
    },
  },

  '.icon': {
    size: rem(32),
    backgroundColor: '$primary',
    br: 10,
    transition: 'background-color ease-in-out .4s',
    position: 'absolute',
    // width: 'auto',
    // height: 'auto'
    'svg': {
      fill: '$white',
      size: '90%',
      transition: 'fill ease-in-out .4s',
      '@md': { size: '50%', }
    },
    '@md': {
      position: 'relative',
      size: rem(64),
      svg: { size: 'auto' },
    },
  },


})
export const StyledServicePreviewContent = styled('div', {
  // marginTop: rem(50),
  width: '100%',
  '@md': {
    paddingLeft: rem(26),
    // marginTop: 'auto',
  },
  'strong': {
    paddingTop: rem(4),
    paddingLeft: rem(40),
    fontWeight: '$1',
    marginBottom: rem(22),
    '@md': {
      marginBottom: '$2',
      paddingLeft: 0,
    }
  },

  'p': {
    transition: 'color ease-in-out .4s',
    color: '$gray'
  }
})
