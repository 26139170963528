import * as yup from 'yup'

export const createValidationSchema = (schema, config) => {
  const {
    name,
    validationType,
    validations = [],
    type: fieldType
  } = config

  if (!yup[validationType]) {
    return schema
  }

  let validator = yup[validationType]()
  if (validations && !!validations.length) {
    validations.forEach(({
      params = [],
      type
    }) => {
      if (!validator[type]) {
        return
      }
      if (type === 'when' && typeof params[1] === 'object' && params[1].is && params[1].then) {
        let thenValidator = yup[validationType]()
        params[1].then.forEach((t) => {
          thenValidator = validator[t.type](...t.params)
        })
        const valueToCheck = params[1].is.split('not:')
        validator = validator[type](params[0], {
          is: (value) => {
            return valueToCheck.length > 1 ? value !== valueToCheck[1] : !!value && valueToCheck[0] === value
          },
          then: thenValidator
        })
      } else {
        if (fieldType !== 'file') {
          validator = validator[type](...params)
        } else {
          const fileValidation = {
            fileSize: (value) => value ? value.size <= Number(params[2]) : true,
            fileFormat: (value) => value ? params[2].includes(value.type) : true,
          }
          validator = validator[type](params[0], params[1], fileValidation[params[0]])
        }
      }
    })
  }

  schema[name] = validator
  return schema
}

const getValues = (data, target) => {
  const val = data[target]
  if (!val) return []

  const opts = val.data.reduce((acc, curr) => {
    const option =  curr.name || curr.attributes.name
    if (acc.has(option)) return acc

    acc.set(option, {
      value: option, label: option
    })
    return acc
  }, new Map)

  const options = []
  for (const value of opts.values()) {
    options.push(value)
  }

  return options
}

const updateFieldset = (fieldsToPopulate, source, fieldsToUpdate) => {
  if (!fieldsToPopulate || fieldsToPopulate.length === 0) return fieldsToUpdate
  fieldsToPopulate.forEach(({
    field,
    relatedTo
  }) => {
    fieldsToUpdate.map(({ fields }) => {
      return fields.map(el => {
        if (el.name == field) {
          const { options } = el
          const values = getValues(source, relatedTo)

          const updatedOptions = [...options, ...values]
          el.options = updatedOptions
        }
        return el
      })
    })
  })

  return fieldsToUpdate
}

const targetRelatedPrefillFields = (prefillArray, dataSource, dataToPrefill) => {
  if (!prefillArray || prefillArray.length === 0) return {}

  const prefilledValue = prefillArray.reduce((acc, {
    field,
    target
  }) => {
    if (!(field in dataToPrefill) || !(target in dataSource)) return acc

    acc[field] = dataSource[target]
    return acc
  }, {})

  return  {
    ...dataToPrefill,
    ...prefilledValue
  }
}

const Form = (data, _, additionalData = {}) => {
  const {
    form,
    ...restData
  } = data

  const clonedData = { ...form }
  if (!clonedData.data || !clonedData.data.attributes.fields) return restData

  const {
    fields,
    ...otherFormProps
  } = clonedData.data.attributes

  const initialValues = {}
  const {
    fieldsets,
    settings,
    ...rest
  } = fields

  const normalizedInitialValues = {}

  if (!fieldsets) return restData

  let originalFieldset = JSON.parse(JSON.stringify(fieldsets))
  let fieldsSetsToShow = fieldsets
  if (settings && settings.populate) {
    const { page = {} } = additionalData
    const updatedFieldsets = updateFieldset(settings.populate, page, originalFieldset)
    originalFieldset = updatedFieldsets
    fieldsSetsToShow = updatedFieldsets
  }


  fieldsSetsToShow.forEach(fieldset => {
    fieldset.fields.forEach(field => {
      normalizedInitialValues[field.name] = (field.value === null || field.value === undefined) ? '' : field.value
      if (initialValues[field.name]) normalizedInitialValues[field.name] = initialValues[field.name]

      delete field.value

      delete field.validationType
      delete field.validations
    })
  })

  if (settings && settings.prefill) {
    const { page = {} } = additionalData
    const updatedPrefillValues = targetRelatedPrefillFields(settings.prefill, page, normalizedInitialValues)
    Object.assign(normalizedInitialValues, updatedPrefillValues)
  }

  const normalizedData = {
    ...otherFormProps,
    fields: {
      ...rest,
      settings: settings || {},
      initialValues: normalizedInitialValues,
      originalFieldset,
      fieldsets: fieldsSetsToShow,
    },
  }

  return {
    ...restData,
    form: normalizedData
  }
}

export default Form
