import {
  styled,
  // rem
} from 'config/stitches.config'

export const StyledStoreLocator = styled('div', {
  display: 'block',
  padding: '$4'
})

export const StyledStoreLocatorMobile = styled('div', {
  display: 'block',

  'header': { padding: '$2' },
  'div': { marginTop: '$3' }
})

export const StyledStoreLocatorContainer = styled('div', {
  aspectRatio: '16 / 9',
  width: '100%',
  height: '80vh',
  br: 40,
  position: 'relative',
  overflow: 'hidden'
})
