import {
  styled,
  rem
} from 'config/stitches.config'

import Icon from '@/UI/Icon'

export const StyledMapContainer = styled('div', {
  height: '100%',
  width: '100%',

  '.mapCanvas': {
    height: '100%',
    width: '100%',
    minHeight: '70vh',
    position: 'relative',

    '.marker': {
      backgroundColor: '$white',
      borderRadius: '$smaller',
      cursor: 'pointer',
      padding: '$0 $2',
      fontWeight: '$1',

      '&::before': {
        content: ' ',
        width: '14.4px',
        height: '13px',
        transform: 'translate(-50%, 35%) rotate(45deg)',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        backgroundColor: '$white',
        borderRadius: '2px',
        zIndex: -1,
      }
    }
  }
})

export const StyledMapBackButton = styled('button', {
  position: 'absolute',
  bottom: 0,
  right: rem(22),
  height: 70,
  width: 70,
  backgroundColor: '$primary',
  br: '$round',
  marginY: rem(22),
  border: 'none',
  cursor: 'pointer',

  [Icon]: { 'svg': { fill: '$white' } },

  '@md': { right: 500 }
})
