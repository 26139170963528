import mapboxgl from 'mapbox-gl'
import React, {
  useRef, useEffect
} from 'react'

import { AnimatePresence } from 'framer-motion'

import MapBackButton from './back'
import Details from '../Stores/Details'

import {
  getItemsBounds, createMap, createDispatchObject
} from './helpers'

import { StyledMapContainer } from './styles'
// import Details from '../Stores/Details'

mapboxgl.accessToken = 'pk.eyJ1IjoiY2FmZmVpbmEiLCJhIjoiY2xoOTd6ZjQ5MDUycjNlbnNoMmtua2R4ciJ9.G26Zn4a-7HTQBlF7GLT2Kg'

const Map = ({
  state,
  dispatch,
  isMobile,
  setStoreToggleState,
}) => {
  const mapContainer = useRef(null)
  const map = useRef(null)

  const onMarkerClick = (feature) => {
    const data = createDispatchObject(feature, state.nextLevel)

    if (state.levels.store.name === data.name) {
      setStoreToggleState('opened')
      return
    }


    const dispatchData = data.type === 'store' && !!data.cities ? {
      ...data, type: 'store'
    }  : data
    dispatch(dispatchData)
  }


  // this effect is used to initialize the map when the state changes
  useEffect(() => {
    const allItems = state.allMapItems
    const stores = allItems.map((store) => {
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            store.longitude,
            store.latitude,
          ]
        },
        'properties': {
          'name': store.name,
          'code': store.code ?? store.id,
          'zoomLevel': store.zoomLevel,
          'items': store[state.show],
          'continent': store.continent,
          'countries': store.countries,
          'cities': store.cities,
        }
      }
    })

    createMap(map, mapContainer, stores, onMarkerClick, isMobile)

    const bounds = getItemsBounds(state.items)
    const options = {
      linear: true, duration: 0, padding: 50
    }

    // setting maxZoom to 10 when showing cities or when there's only one store otherwise the zoom level is too high
    if (state.items.length === 1 || state.zoomLevel !== undefined) {
      options.maxZoom = state.zoomLevel ?? 10
    }
    map.current.fitBounds(bounds, options)
  }, [state, map])

  const onBackClick = () => {
    dispatch({ type: 'back' })
  }

  return (
    <>
      <StyledMapContainer>
        <div ref={mapContainer} className='mapCanvas' data-lenis-prevent />

        { isMobile && state.levels.store.name && (
          <AnimatePresence>
            <Details
              {...state}
              isMobileMap={true}
              closeFn={onBackClick}></Details>
          </AnimatePresence>
        )}
      </StyledMapContainer>
      {
        isMobile && state.levels.continent.name &&
        <MapBackButton clickFn={onBackClick}></MapBackButton>
      }
    </>
  )
}

export default Map
