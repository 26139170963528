import {
  styled,
  rem
} from 'config/stitches.config'

export const StyledCardsLink = styled('div', {})

export const StyledCardLinkHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  columnGap: rem(16),
  color: '$black',
  paddingTop: 48,
  width: '100%',

  '.icon': {
    position: 'absolute',
    top: 32,
    right: 24,
    size: 48,
    br: '$round',
    backgroundColor: '$primary',
    marginLeft: 'auto',

    'svg': {
      width: '40%',
      fill: '$white'
    },

    '@md': {
      position: 'static',
      size: 32,
      backgroundColor: 'transparent',
      border: '1px solid $gray1',
      color: '$gray1',

      'svg': {
        width: '60%',
        fill: '$gray1'
      },
    }
  },

  '@md': {
    marginBottom: rem(10),
    paddingTop: 0,
  }
})

export const StyledCardLinkContent = styled('div', {
  color: '$gray1',

  'p': {
    marginBlockStart: rem(16),
    marginBlockEnd: 0,
  },
  'a': {
    color: '$primary',
    fontSize: '$cta-underline-mobile',
    lineHeight: '$cta-underline-mobile',
    fontWeight: '$3',
    '@md': {
      fontSize: '$cta-underline',
      lineHeight: '$cta-underline',
    }

  }
})

export const StyledCardLink = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  flexDirection: 'column',
  textDecoration: 'none',
  backgroundColor: '$white',
  br: 20,
  paddingX: rem(24),
  paddingY: rem(32),
  position: 'relative',
  transition: 'background-color ease-in-out .4s, color ease-in-out .4s',
  minHeight: 280,
  boxShadow: '0px 2px 64px rgba(51, 51, 51, 0.03)',
  border: '1px solid #D3D3D3',

  '@md': {
    minHeight: 200,
    justifyContent: 'flex-start',
    paddingX: rem(39),
    paddingY: rem(44),
    border: 'none',
  },
  variants: {
    withLink: {
      true: {
        '@md': {
          '&:hover': {
            backgroundColor: '$primary',
            color: '$white',

            [`
              ${StyledCardLinkHeader},
              ${StyledCardLinkContent} span,
            `]: { color: '$white !important' },

            [`${StyledCardLinkHeader} .icon`]: {
              backgroundColor: '$white',
              borderColor: '$white',
              svg: { fill: '$primary' }
            }
          },
        }
      },
    },
    spaced: {
      true: {
        paddingX: rem(40),
        paddingY: rem(70),
      }
    }
  }
})
