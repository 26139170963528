import React from 'react'

import Text from '@/UI/Text'
import RichText from '@/UI/RichText'

import Icon from '@/UI/Icon'

import {
  StyledItemsContainer,
  StyledItems,
  StyledItem,
  StyledDetail,
  StyledDetailHeader,
  StyledDetailHeaderMain,
  StyledDetailHeaderMap,
  StyledNavigationCta
} from './styles'
import SimpleLink from '@/UI/Link'

const Details = ({
  items, isMobileMap, closeFn
}) => {
  const onClose = () => {
    if (closeFn) closeFn()
  }

  return (
    <StyledItemsContainer className={isMobileMap ? 'mobile-map' : ''}>
      {
        isMobileMap && <Icon name="close" onClick={onClose}></Icon>
      }

      {
        items &&
        !!items.length &&
        <StyledItems>
          {
            items.map(({
              id,
              name,
              description,
              street,
              phone,
              fax,
              zipCode,
              mapsLink,
              contactLink,
              contactUsLabel
            }, idx) => (
              <StyledItem key={`${id}_${name}_${idx}`}>
                <StyledDetail>
                  <StyledDetailHeader>
                    <StyledDetailHeaderMain>
                      <Text as="strong" bold size="cta-primary" >{name}</Text>
                      {
                        description &&
                        <RichText as="p" size="p4" content={description?.replaceAll('\n', '<br />')} />
                      }
                    </StyledDetailHeaderMain>
                    {
                      mapsLink && !isMobileMap &&
                      <StyledDetailHeaderMap>
                        <SimpleLink href={mapsLink} target='_blank'>
                          <Icon name="gMap" />
                        </SimpleLink>
                      </StyledDetailHeaderMap>
                    }
                  </StyledDetailHeader>

                  <Text as="p" size="p4">{street}</Text>
                  <Text as="p" size="p4">{zipCode}</Text>
                  <Text as="p" size="p4">{fax}</Text>
                  <Text as="p" size="p4">{phone}</Text>

                  <StyledNavigationCta
                    href={contactLink}
                    size="big"
                    theme="default"
                  >{contactUsLabel}</StyledNavigationCta>
                </StyledDetail>
              </StyledItem>
            ))
          }
        </StyledItems>
      }
    </StyledItemsContainer>
  )
}

export default Details
