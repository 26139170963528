import React, { useId } from 'react'
import { motion } from 'framer-motion'

import withCustomCursor from 'hoc/withCustomCursor'

import SectionHeader from '@/Section/Header'
import SectionContent from '@/Section/Content'
import Cta from '@/UI/Cta/DefaultCta'

import { FadeIn } from 'animations'
import {
  StyledSectionContainer,
  StyledSectionContent,
  StyledSectionCta,
} from './styles'

const SectionContainer = ({
  theme,
  sectionHeader,
  children,
  footer,
  containerProps = {},
  type,
  headerCta = false,
  ...rest
}) => (
  <StyledSectionContainer
    theme={theme}
    className='SectionContainer'
    {...containerProps}
  >
    <StyledSectionContent
      theme={theme}
      as={motion.div}
      initial="hidden"
      whileInView="show"
      variants={FadeIn({
        duration: 0.6,
        delay: 0.2,
        delayChildren: 0.4,
        staggerChildren: 0.4
      })}
      viewport={{ once: true }}
      {...rest}
      id={useId()}
    >
      {
        sectionHeader &&
        <SectionHeader {...sectionHeader} headerCta={headerCta} />
      }
      {
        (children || sectionHeader?.ctas) &&
        <SectionContent css={!sectionHeader && { marginTop: 0 }}>
          {children && children}

          {
            sectionHeader?.ctas &&
            sectionHeader?.ctas.length > 0 &&
            !headerCta &&
            <StyledSectionCta>
              {
                sectionHeader?.ctas.map((cta, idx) => (
                  <Cta
                    multiple={sectionHeader?.ctas.length}
                    key={`${type}_section_cta_${idx}`}
                    className="SectionCta"
                    href={cta.href}
                    target={cta.target}
                    size="huge"
                    theme={(idx + 1) % 2 !== 0 ? theme : 'multiple'}
                  >
                    {cta.label}
                  </Cta>
                ))
              }
            </StyledSectionCta>
          }

        </SectionContent>
      }

    </StyledSectionContent>

    { footer && footer }
  </StyledSectionContainer>
)

export default withCustomCursor(SectionContainer)
