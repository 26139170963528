import {
  styled,
  rem
} from 'config/stitches.config'


export const StyledInputFileLabel = styled('span', {
  textIndent: rem(4),
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  fontSize: '$subtitle-2-mobile',
  lineHeight: '$subtitle-2-mobile',
  width: '100%',
  height: rem(48),
  borderBottom: '1px solid',
  pointerEvents: 'none',
  '@md': {
    fontSize: '$subtitle-2',
    lineHeight: '$subtitle-2',
  },
})

export const StyledField = styled('div', {
  position: 'relative',
  marginBottom: '$4',

  '&:last-child': { marginBottom: 0 },

  '&[type="checkbox"], &[type="radio"]': {
    width: 'auto',

    '> div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  variants: {
    type: {
      hidden: { marginBottom: 0, },
      default: {
        'label': {
          pointerEvents: 'none',
          width: '100%',
        }
      },
      radio: {
        minWidth: '150px',
        'label': {
          pointerEvents: 'auto',
          width: 'auto',
        }
      },
      checkbox: {
        'label': {
          pointerEvents: 'auto',
          width: 'auto',
        }
      },
    }
  },
  defaultVariants: { type: 'default' }
})

export const StyledFieldContainer = styled('div', {
  position: 'relative',
  width: '100%',

  'input[type="search"]': {
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid $secondary !important',

      borderRadius: '30px',
    }
  },

  'input:not([type="checkbox"]):not([type="radio"]), select, textarea': {
    textIndent: rem(4),
    width: '100%',
    // height: rem(48),
    border: 'none',
    backgroundColor: 'transparent',
    color: 'inherit',
    fontSize: '$h4-mobile',
    lineHeight: '$h4-mobile',
    '@md': {
      fontSize: '$form-text',
      lineHeight: '$form-text',
    },
  },

  'input:not([type="checkbox"]):not([type="radio"]), textarea': {
    paddingX: 0,
    paddingY: rem(8),
    borderBottom: '1px solid',
    fontFamily: '$base',

    '&::placeholder': {
      all: 'unset',
      color: 'inherit',
      fontFamily: '$base'
    },

    '&:focus, &:not(:placeholder-shown)': { borderBottom: '1px solid', },

    '&:placeholder-shown + label': {
      color: 'transparent',
      userSelect: 'none',
    },
    '@md': { paddingY: rem(16), }
  },

  'input:focus:not([type="checkbox"]):not([type="radio"]) + label, input:not([type="checkbox"]):not([type="radio"]):not(:placeholder-shown) + label, textarea:focus + label, textarea:not(:placeholder-shown) + label': {
    transform: 'translate(0, -90%) scale(0.45)',
    color: 'inherit',
  },

  'textarea': {
    width: '100%',
    display: 'block',
    minHeight: rem(180)
  },

  'textarea + label': { top: '16px' },
  'input[type="checkbox"], input[type="radio"]': {
    size: '20px',
    padding: 0,
    margin: 0,
    opacity: 0,
    visibility: 'hidden',
  },

  'input[type="checkbox"] + label, input[type="radio"] + label': {
    display: 'block',
    paddingTop: rem(3),
    position: 'relative',
    top: 'unset',
    transform: 'none',
    cursor: 'pointer',
    fontSize: '$sub-desk',
    lineHeight: '$sub-desk',
    paddingLeft: '$2',

    '&:before': {
      content: '',
      size: '$2',
      transition: 'background-color ease-in-out .3s, border-color ease-in-out .3s, border-width ease-in-out .2s',
      border: '2px solid',
      position: 'absolute',
      right: '100%',
      top: 0,
    },

    '&:hover:before': { borderColor: '$black', }
  },

  'input[type="radio"] + label': { '&:before': { br: '50%', } },

  'input[type="checkbox"]:checked + label, input[type="radio"]:checked + label': {
    '&:before': {
      borderColor: 'inherit',
      backgroundColor: 'inherit'
    }
  },

  'input[type="checkbox"]:checked + label': {
    '&:before': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      content: '✓',
      color: '$white',
      backgroundColor: '$primary'
    },
  },

  'input[type="file"]': {
    opacity: 0,
    '&:not(:placeholder-shown) + label': {
      all: 'unset',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%) scale(1) !important',
      color: '$gray',
      fontSize: '$p3-mid',
      display: 'inline-block'
    },
    '&::-webkit-file-upload-button': { display: 'none' }
  },

  'select': {
    textIndent: 0,
    fontWeight: '$2',
    paddingY: rem(8),
    margin: 0,
    fontFamily: '$base',
    'option': {
      color: '$primary',
      fontWeight: '$2',
      padding: 0
    },
    '@md': { paddingY: rem(16), }
  },

  variants: {
    type: {
      checkbox: {
        display: 'flex',
        alignItems: 'flex-start',
      },
      radio: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    error: {
      true: {
        'input:not([type="checkbox"]):not([type="radio"]), textarea,': {
          '&::placeholder': { color: '$error', },
          borderColor: '$error',
          '&:focus, &:not(:placeholder-shown)': { borderColor: '$error', },
        },
        'select': { borderBottom: '1px solid $error', },
        'input[type="checkbox"] + label, input[type="radio"] + label': {
          color: '$error',
          '&:before': { borderColor: '$error', }
        },
        'input[type="file"] + span': {
          borderColor: '$error',
          color: '$error'
        }
      },
      false: {
        'input:not([type="checkbox"]):not([type="radio"]), textarea': {
          borderColor: '$gray',
          '&:focus, &:not(:placeholder-shown)': { borderColor: '$gray', },
        },
        'select': { borderBottom: '1px solid $gray', },
        'input[type="checkbox"] + label, input[type="radio"] + label': {
          color: 'inherit',
          a: { color: 'inherit', },
          '&:before': {
            borderColor: '$black',
            flexShrink: 0,
          }
        }
      }
    }
  },
  defaultVariants: { error: 'false' }
})

export const StyledFieldLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'absolute',
  top: '50%',
  left: 0,
  color: '$primary',

  transformOrigin: 'top left',
  transform: 'translate(2%,-50%)',
  transition: 'transform .3s ease-in-out, color .3s ease-in-out',
  fontSize: '$subtitle-2-mobile',
  lineHeight: '$subtitle-2-mobile',

  '@md': {
    fontSize: '$subtitle-2',
    lineHeight: '$subtitle-2',
  }
})

export const StyledFieldError = styled('div', {
  position: 'absolute',
  left: 0,
  top: '100%',
  color: '$error',
  fontSize: '$p3-mid-mobile',
  lineHeight: '$p3-mid-mobile',
  marginTop: '$1',

  '@md': {
    fontSize: '$p3-mid',
    lineHeight: '$p3-mid',
  }
})

export const StyledGroup = styled('div', {
  display: 'block',
  marginBottom: '$3',
  paddingBottom: '$2',
  overflow: 'auto',
  width: '100vw',
  marginX: '-14px',
  paddingX: '14px',
  '&::-webkit-scrollbar': { height: 1, },

  '&::-webkit-scrollbar-track': { background: '$white', },
  '&::-webkit-scrollbar-thumb': { backgroundColor: '$secondary', },
  '@md': {
    width: 'auto',
    marginX: 0,
    paddingX: 0,
  },
  'strong': {
    display: 'block',
    marginBottom: '$2',
  },
  [StyledField]: { marginBottom: 0 }
})

export const StyledGroupContainer = styled('div', {
  display: 'inline-flex',
  // width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  columnGap: '$2',
  rowGap: '$2',

  // background: 'red',
  '[data-type="radio"], [data-type="checkbox"]': {
    position: 'relative',
    'label': {
      fontSize: '$p4 !important',
      lineHeight: '$p4 !important',
      whiteSpace: 'nowrap',
      height: 50,
      backgroundColor: '$white',
      border: '1px solid $black',
      color: '$black',
      paddingTop: `${rem(16)}!important`,
      paddingBottom: rem(16),
      paddingLeft: `${rem(32)}!important`,
      paddingRight: rem(32),
      textAlign: 'center',
      br: 100,
      transition: 'background-color ease-in-out .4s, border-color ease-in-out .4s',
      '@md': {
        '&:hover': {
          backgroundColor: '$secondary',
          borderColor: '$secondary',
        }
      }
    },
    'input': {
      position: 'absolute',
      size: '100%',
      left: 0,
      top: 0,
      '&:checked+label': {
        backgroundColor: '$secondary',
        borderColor: '$secondary',
      }
    }

  },
  '[data-type="radio"]': {
    minWidth: 0,
    'label': { '&:before': { display: 'none' }, },
  },
  '[data-type="checkbox"]': {
    'label': {
      paddingLeft: '$2 !important',
      paddingRight: '$3 !important',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: rem(12),
      '&:before': {
        br: 2,
        size: '18px !important',
        right: '0 !important',
        position: 'relative !important'
      },
    },
    'input': {
      '&:checked+label': {
        '&:before': {
          backgroundColor: '$white',
          color: '$black',
          borderColor: '$black',
        }
      }
    }
  }
})


export const StyledReactSelect = styled('div', {
  fontSize: '$h4-mobile',
  lineHeight: '$h4-mobile',
  '@md': {
    fontSize: '$form-text',
    lineHeight: '$form-text',
  },
  '.react-select__control': {
    borderRadius: '0px',
    border: '0px',
    borderBottom: '1px solid $gray',
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    textIndent: 0,
    fontWeight: '$2',
    margin: 0,
    paddingY: `${rem(16)}`,
    fontFamily: '$base',
    '&:hover': { borderBottom: '1px solid $gray', },
    '&:focus, &:focus-visible': {
      outline: '#015fcc solid 2px !important',
      borderRadius: '4px'
    }
  },
  '.react-select__input-container': {
    margin: '0px',
    paddingY: '0px'
  },
  '.react-select__placeholder': {
    marginLeft: '4px',
    color: '$black'
  },
  '.react-select__value-container': { padding: '0px', },
  '.react-select__single-value': {
    color: '$black',
    fontWeight: '$2',
    padding: 0
  },
  '.react-select__indicator-separator': { display: 'none' },
  '.react-select__indicator': {
    paddingRight: '0px',
    'svg': {
      color: '$primary',
      width: '14px',
      height: '14px',
    }
  },

  '.react-select__option--is-focused, .react-select__option--is-selected': { color: '$white', },
  '.react-select__option--is-selected': { backgroundColor: '$primary' },
  '.react-select__option--is-focused:not(.react-select__option--is-selected)': { backgroundColor: '#999eb7' },

  variants: {
    error: {
      true: {
        '.react-select__control': {
          borderBottom: '1px solid $error',
          color: '$error'
        },
        '.react-select__placeholder': { color: '$error' },
        '.react-select__indicator svg': { color: '$error' }
      }
    }
  }
})
