import {
  LngLatBounds,
  Map,
  Marker,
} from 'mapbox-gl'

// Gets the map bounds from the items to fix the map to the right zoom level
export const getItemsBounds = (items) => {
  if (items.length > 0) {
    const bounds = new LngLatBounds(
      [items[0].longitude, items[0].latitude],
      [items[0].longitude, items[0].latitude]
    )

    for (const item of items) {
      bounds.extend([item.longitude, item.latitude])
    }
    return bounds
  }
  return undefined
}

// Creates marker html
export const createElement = (props) => {
  const html = `<div class='marker'>${props.name}</div>`

  const el = document.createElement('div')
  el.innerHTML = html

  return el.firstChild
}

// Creates the object to dispatch to the reducer
export const createDispatchObject = (data, nextLevel) => {
  const continent = data.properties.continent ? JSON.parse(data.properties.continent) : null
  const countries = data.properties.countries ? JSON.parse(data.properties.countries) : null
  const cities = data.properties.cities ? JSON.parse(data.properties.cities) : null

  return {
    continent,
    countries,
    cities,
    type: nextLevel,
    name: data.properties.name,
    code: data.properties.code,
    zoomLevel: data.properties.zoomLevel,
  }
}

export const createMap = (ref, containerRef, stores, onClick, isMobile = false) => {
  // HACK: remove the map and create a new one to reset the zoom, pitch and camera
  // tried to find a better way to do this but couldn't
  if (ref.current) {ref.current.remove()}

  ref.current = new Map({
    container: containerRef.current,
    style: 'mapbox://styles/caffeina/clh97sk9j00zf01qyd51kc0ew',
    cooperativeGestures: !isMobile,
    pitchWithRotate: !isMobile,
    dragRotate: !isMobile,
  })

  if (isMobile) {
    ref.current.touchZoomRotate.disableRotation()
  }

  ref.current.on('mouseenter', 'clusters', () => {
    ref.current.getCanvas().style.cursor = 'pointer'
  })
  ref.current.on('mouseleave', 'clusters', () => {
    ref.current.getCanvas().style.cursor = ''
  })

  if (stores) {
    ref.current.on('load', () => {
      ref.current.addSource('locations', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: stores
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
      })

      // clustered style
      ref.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'locations',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#ffffff',
          'circle-radius': 20
        }
      })

      // cluster count
      ref.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'locations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 16
        }
      })

      // inspect a cluster on click
      ref.current.on('click', 'clusters', (e) => {
        const features = ref.current.queryRenderedFeatures(e.point, { layers: ['clusters'] })
        const clusterId = features[0].properties.cluster_id
        ref.current.getSource('locations').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return

            ref.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            })
          }
        )
      })

      const markers = {}
      let markersOnScreen = {}

      // Creates markers, their html and event listeners
      // Must be here otherwise the markers are not added to the map
      function setMarkersOnMap() {
        const newMarkers = {}
        const features = ref.current.querySourceFeatures('locations')

        for (const feature of features) {
          const coords = feature.geometry.coordinates
          const props = feature.properties

          if (props.cluster) continue
          const id = props.code ?? props.name

          let marker = markers[id]

          if (!marker) {
            const el = createElement(props)
            marker = markers[id] = new Marker({
              element: el,
              anchor: 'bottom'
            }).setLngLat(coords)

            el.addEventListener('click', () => onClick(feature))
          }
          newMarkers[id] = marker

          if (!markersOnScreen[id]) {
            marker.addTo(ref.current)
          }
        }

        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
          if (!newMarkers[id]) {
            markersOnScreen[id].remove()
          }
        }
        markersOnScreen = newMarkers
      }

      ref.current.on('render', () => {
        if (!ref.current.isSourceLoaded('locations')) return

        setMarkersOnMap(stores)
      })
    })
  }
}
