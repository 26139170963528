import React, {
  useEffect,
  useState
} from 'react'

import {
  AnimatePresence,
  motion
} from 'framer-motion'

import useIsMobile from 'hooks/useIsMobile'

import { StyledMenuFooterWrapper } from './styles'

import MenuFooterHeader from './Header'
import MenuFooterMain from './Main'

const MenuFooter = ({
  type,
  className = null,
  collapsable = true,
  collapsableLabel = null,
  header,
  columns = null,
  direction = 'column',
  main
}) => {
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)

  const onButtonClick = () => {
    if (!isMobile) return
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsOpen(!isMobile)
    return
  }, [isMobile])

  const mainExists = !!main

  return (
    <StyledMenuFooterWrapper className={className} columns={columns}>
      {
        header &&
        <MenuFooterHeader {...{
          ...header,
          mainExists,
          isMobile,
          collapsable,
          collapsableLabel,
          isOpen,
          onButtonClick
        }}/>
      }


      {
        main &&
        <AnimatePresence>
          {
            (isOpen || !collapsable) &&
            <MenuFooterMain
              cluster={header?.label || ''}
              layout
              as={motion.main}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              direction={direction}
              type={type}
              items={main}
              columns={columns}
              theme={!header ? 'white' : 'default'}
            />
          }
        </AnimatePresence>
      }

    </StyledMenuFooterWrapper>
  )
}


export default MenuFooter
