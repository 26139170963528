import {
  generateSlug, rewriteLocale
} from 'services/API/helpers'

const options = {
  // weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}


const getMonth = (monthStr, locale)  => {
  return new Date(monthStr + '-1-01').toLocaleDateString(locale, { month: 'long' })
}
const PAGE_SIZE = 3

const defaultQueryParams = {
  populate: 'deep',
  'sort[1]': 'date:desc'
}
export const normalizedContentData = (data) => {
  if (!data) return []
  return data.reduce((acc, curr) => {
    const {
      excerpt,
      year,
      month,
      locale: _itemLocale,
      date,
      title,
      preview,
      ...rest
    } = curr.attributes

    const itemLocale = rewriteLocale(_itemLocale, 'shortened')
    const itemsFilters = [
      {
        id: year,
        label: year,
        type: 'year'
      },
      {
        id: month,
        label: getMonth(month, itemLocale),
        type: 'month'
      }
    ]

    const link = {
      href: generateSlug(curr.attributes).join('/'),
      target: '_self',
      locale: itemLocale,
    }

    const dateFormatted = new Date(date)
    const dateString = dateFormatted.toLocaleDateString(itemLocale, options)

    if (preview) {
      preview.title = title
    }

    const item = {
      ...rest,
      link,
      preview,
      locale: itemLocale,
      description: excerpt,
      filters: itemsFilters,
      date: dateString
    }

    acc.push(item)

    return acc
  }, [])
}

const NewsItemsArchive = async (data, locale) => {
  const {
    showFilters,
    filters,
    pageSize,
  } = data

  const params =  {
    locale: rewriteLocale(locale, 'full'),
    'pagination[page]': 1,
    'pagination[pageSize]': !!pageSize ? pageSize : PAGE_SIZE,
    ...defaultQueryParams
  }

  let header = false


  if (showFilters) {
    header = Object.keys(filters).map(key => {
      const customLabel = data[`${key}FilterLabel`]
      const {
        label,
        items,
      } = filters[key]

      return {
        id: key,
        title: customLabel || label,
        items: items.map(({
          id,
          name,
          ...rest
        }) => {
          return {
            id,
            title: key === 'month' ? getMonth(name, locale) : name,
            ...rest
          }
        })
      }
    })
  }


  const normalizedData = {
    ...data,
    params,
    filters: {
      header,
      content: null
    },
  }

  return normalizedData
}

export default NewsItemsArchive
