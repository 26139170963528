import {
  styled, rem
} from 'config/stitches.config'

import Grid from '@/UI/Grid'

export const StyledStats = styled('div', {
  marginTop: rem(40),

  [`${Grid}`]: { gap: rem(36), }
})

export const StyledStat = styled('div', {
  backgroundColor: '$white',
  padding: '$4',
  minHeight: rem(216),
  minWidth: 'auto',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  br: 20,
  boxShadow: '0px 2px 64px rgba(51, 51, 51, 0.03)',
  border: '1px solid #D3D3D3',


  'strong': {
    fontWeight: '$1',
    fontSize: '$stats',
    lineHeight: '$stats',
    fontVariantNumeric: 'tabular-nums',
    whiteSpace: 'nowrap'

    // fontSize: '$stats-mobile',
    // lineHeight: '$stats-mobile',
    // '@md': {
    //   fontSize: '7vw',
    //   lineHeight: '7vw',
    // },
    // '@xl': {
    //   fontSize: '$stats',
    //   lineHeight: '$stats',
    // }
  },

  'p': { color: '$gray ', },
  '@md': { border: 'none', }

})

export const StyledStatDetail = styled('div', {
  display: 'inline-flex',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%'
})

export const StyledStatTextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  variants: {
    rows: {
      1: { minHeight: rem(27) },
      2: { minHeight: rem(2 * 27) },
      3: { minHeight: rem(3 * 27) },
    }
  }
})
