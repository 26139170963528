import { getEntity } from 'services/API'
import { rewriteLocale } from 'services/API/helpers'
import { normalizedContentData } from './NewsItemsArchive'

const PAGE_SIZE = 3

const defaultQueryParams = {
  populate: 'deep',
  'sort[1]': 'date:desc'
}

const NewsItems = async (data, locale) => {
  const {
    news,
    pageSize,
    ...rest
  } = data

  const params =  {
    locale: rewriteLocale(locale, 'full'),
    'pagination[page]': 1,
    'pagination[pageSize]': !!pageSize ? pageSize : PAGE_SIZE,
    ...defaultQueryParams
  }

  let content = news && !!news.data.length ? normalizedContentData(news.data) : []

  if (!news || news.data.length === 0) {
    const latestNews = await getEntity({
      entity: 'news',
      params
    })

    content = latestNews.data && !!latestNews.data.length ?  normalizedContentData(latestNews.data) : []
  }

  const normalizedData = {
    ...rest,
    preview: true,
    params,
    filters: { content },
    headerCta: true
  }

  return normalizedData
}

export default NewsItems
