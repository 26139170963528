import React from 'react'

import withCustomCursor from 'hoc/withCustomCursor'

import ContentDivider from '@/ContentDivider'
import RichText from '@/UI/RichText'
import SimpleLink from '@/UI/Link'
import Icon from '@/UI/Icon'
import Button from '@/UI/Button'

import {
  StyledClientListPreview, StyledClientListPreviewHeader
} from './styles'


const ClientListPreview = ({
  idx,
  title,
  description,
  showLabel = true,
  cta
}) => {
  const labelFormatted = idx <= 9 ? `0${idx}` : idx
  const label = showLabel ? labelFormatted : ''

  const Link = withCustomCursor(SimpleLink)
  const withLink = cta && cta.href
  const Wrapper = (props) => withLink ?
    <Link
      cursor={{
        type: 'defaultLink',
        text: cta.label,
        blendMode: 'normal'
      }}
      href={cta.href}
      aria-label={cta.label}
      css={{
        display: 'block',
        textDecoration: 'none'
      }}
      {...props} />
    : <>{props.children}</>

  return (
    <StyledClientListPreview>
      <Wrapper>
        <StyledClientListPreviewHeader>

          <ContentDivider as="h3" layout='column' label={label} title={title}/>
          {
            withLink &&
            <Button
              className='ClientListCta'
              as={'div'}
              size="verySmall"
              theme={'default'}
            >
              <Icon css={{ size: '50%' }} theme="inverted" name="arrowRight" />
            </Button>
          }
        </StyledClientListPreviewHeader>
        {
          description &&
          <RichText
            className="Description"
            as="div"
            size={{
              '@initial': 'p1-mobile',
              '@md': 'p1',
            }}
            content={description} />
        }

      </Wrapper>
    </StyledClientListPreview>
  )
}

export default ClientListPreview
