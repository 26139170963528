import {
  styled,
  rem
} from 'config/stitches.config'

// import { StyledSearchButton } from '@/Buttons/styles'
// import { StyledLogo } from '@/Logo/styles'


export const StyledHeader = styled('header', {
  position: 'sticky',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 11,
  // pointerEvents: 'none',
  backgroundColor: '$white',
  boxShadow: '0px 4px 40px rgba(60, 60, 60, 0.06)',
  // variants: {
  //   isOpen: { true: { [`& ${StyledSearchButton} svg, & ${StyledLogo} svg g path`]: { fill: '$white' }, } },
  //   theme: { inverted: { [`& ${StyledSearchButton} svg, & ${StyledLogo} svg g path`]: { fill: '$white' }, } }
  // },

})

export const StyledHeaderContainer = styled('div', {})

export const StyledHeaderUpper = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '180px auto',
  gridTemplateRows: 'auto',
  gridTemplateAreas: '"logo content"',
  columnGap: rem(20),
  paddingY: rem(16),
})

export const  StyledHeaderUpperContent = styled('div', {
  gridArea: 'content',
  display: 'inline-flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '$2'
})

export const StyledHeaderLower = styled('div', {
  position: 'relative',
  paddingY: rem(16),
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',

  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: 'calc(100vw - 24px)',
    height: 1,
    backgroundColor: '$darkGray',
    transform: 'translateX(-50%)',
    opacity: 0.2
  }
})
