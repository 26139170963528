import {
  styled,
  rem,
} from 'config/stitches.config'

import Heading from '@/UI/Heading'
import Cta from '@/UI/Cta/DefaultCta'

import { StyledSectionCta } from '../Container/styles'

Heading.toString = () => '.Heading'
Cta.toString = () => '.HeaderCta'

export const StyledSectionHeader = styled('header', {
  display: 'grid',
  // rowGap: '$4',
  columnGap: '$4',
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'auto auto auto ',
  gridTemplateAreas: `
    "heading"
    "content"
    "cta"
  `,

  '@lg': {
    // rowGap: rem(72),
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `
      "heading cta"
      "content content"
    `,
    minHeight: 0,
    minWidth: 0,
  },

  [` ${Heading}`]: {
    gridArea: 'heading',
    width: 'auto',
    'p': { margin: 0 }
  },

  ' .HeaderCta': {
    gridArea: 'cta',
    width: '100%',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',

    '@middle': {
      width: 'auto',
      minWidth: 0,
      minHeight: 0,
      marginTop: rem(20)
    },
    'svg': { fill: '$black' }
  },
})

export const SectionHeaderLower = styled('div', {
  marginTop: '$4',
  gridArea: 'content',
  '@lg': { rowGap: rem(72), }
})


export const StyledSectionHeaderCta = styled(StyledSectionCta, {
  marginTop: rem(40),
  'a': { fontSize: '$p3', },
  '@middle': { marginTop: 0 },
})
