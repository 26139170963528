import { styled } from 'config/stitches.config'

export const StyledClientListPreview = styled('div', {
  '.Divider': { 'strong': { color: '$black', } },

  '.Description': {
    color: '$gray',

    'a': {
      color: '$primary',
      fontSize: '$cta-underline-mobile',
      lineHeight: '$cta-underline-mobile',
      fontWeight: '$3',

      '@md': {
        fontSize: '$cta-underline',
        lineHeight: '$cta-underline',
      }
    }
  }
})

export const StyledClientListPreviewHeader = styled('div', {
  position: 'relative',
  '.Divider > div': {
    marginBottom: 24,
    paddingRight: 50,
    '@md': {
      paddingRight: 0, marginBottom: '$2',
    },
  },
  '.ClientListCta': {
    paddingX: 4,
    position: 'absolute',
    top: 0,
    right: 0,
    height: 32,
    '.icon': { margin: ' 0 auto', },

    '@md': { display: 'none' }
  }
})
