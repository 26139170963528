import React from 'react'

import ContentDivider from '@/ContentDivider'
import Grid from '@/UI/Grid'
import RichText from '@/UI/RichText'
import CustomImage from '@/Image'
import ImageWithLabelRepeater from '@/ImageWithLabelRepeater'

import {
  StyledParagraph,
  StyledParagraphContent
} from './styles'

const Paragraph = ({
  title = null,
  description,
  theme = 'default',
  size,
  imagesWithTag = null,
  tinyImageWithLabel = null,
  imagePreserveRatio = false,
}) => {
  const imageProps = {}

  if (imagesWithTag) {
    Object.assign(imageProps, {
      ...imagesWithTag,
      rounded: 'normal',
      as: 'div',
      tags: imagesWithTag.keyword ? [imagesWithTag.keyword] : false
    })
  }

  return (
    <StyledParagraph full={!!imagesWithTag} preserveRatio={!!imagePreserveRatio}>
      <ContentDivider title={title} theme={theme} size={size} withLine={false} />

      <Grid
        align="start"
        gapX={{
          '@middle': 4,
          '@lg': 1,
        }}
        gapY={2}
        columns={{
          '@initial': 1,
          '@middle': imagesWithTag ? 2 : 1
        }}>

        <StyledParagraphContent>
          <RichText
            as='div'
            content={description}
            size={{
              '@initial': 'p1-mobile',
              '@md': 'p2'
            }}
          />
          <ImageWithLabelRepeater items={tinyImageWithLabel} />
        </StyledParagraphContent>
        {
          imagesWithTag &&
          <CustomImage className="ParagraphImage" {...imageProps}/>
        }

      </Grid>

    </StyledParagraph>
  )
}

export default Paragraph
