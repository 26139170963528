import React from 'react'

import SectionContainer from '@/Section/Container'

import MediaWithTag from '@/MediaWithTag'
import Text from '@/UI/Text'
import Cta from '@/UI/Cta/DefaultCta'
import Button from '@/UI/Button'

import {
  StyledBanner,
  StyledBannerContent,
  StyledBannerImage,
  StyledBannerDetails,
  StyledBannerCtas,
} from './styles'


const Banner = ({
  mediaWithTag,
  fullWidth = false,
  title,
  cta,
  bannerLabel,
}) => {
  return (
    <StyledBanner isFullScreen={fullWidth}>
      <SectionContainer
        className="BannerContainer"
        theme="wider"
        css={{ paddingY: 0, }}
      >
        <StyledBannerContent isFullScreen={fullWidth}>
          {
            title &&
            <StyledBannerDetails>
              {
                bannerLabel &&
                <Button
                  as="div"
                  className='no-hover'
                  size="verySmall"
                  theme="inverted"
                  css={{
                    fontWeight: 500,
                    height: '$4',
                    marginBottom: '$2',
                    textTransform: 'uppercase',
                    paddingX: 16,
                    flexShrink: 0,
                    justifyContent: 'center'
                  }}
                >
                  {bannerLabel}
                </Button>
              }

              <Text
                bold
                as="h2"
                size="p1-mid"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </StyledBannerDetails>
          }


          {
            cta &&
            !!cta.length &&
            <StyledBannerCtas>
              {
                cta.map(c => <Cta
                  key={cta.label}
                  {...c}
                  size="big"
                  className="BannerCta"
                  theme="secondary">
                  {c.label}
                </Cta>)
              }
            </StyledBannerCtas>
          }
        </StyledBannerContent>

        {
          mediaWithTag.media &&
          mediaWithTag.media.data &&
          <StyledBannerImage>
            <MediaWithTag {...mediaWithTag} />
          </StyledBannerImage>
        }

      </SectionContainer>
    </StyledBanner>
  )
}

export default Banner
