import React, { useContext } from 'react'
import { GeneralContext } from 'contexts/General'

import { StyledGlobalButton } from './styles'
import Icon from '@/UI/Icon'

const GlobalButton = () => {
  const { options } = useContext(GeneralContext)
  const { mapPage } = options

  return (
    <StyledGlobalButton {...mapPage}>
      <Icon name="mapPin" />
    </StyledGlobalButton>
  )
}

export default GlobalButton
