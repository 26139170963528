import {
  rem, styled
} from 'config/stitches.config'

import Grid from '@/UI/Grid'
import SimpleLink from '@/UI/Link'
import Icon from '@/UI/Icon'

SimpleLink.toString = () => '.PartnerLink'
Icon.toString = () => '.icon'

export const StyledPartners = styled('div', { marginTop: rem(80) })

export const StyledFeaturedPartnersList = styled('div', {
  'img': { width: '100%' },

  [Grid]: {
    columnGap: rem(72),
    rowGap: rem(44)
  }
})

export const StyledFeaturedPartner = styled('div', {
  display: 'block',

  'h3': {
    paddingY: '$2', borderBottom: '1px solid $white'
  },

  '.PartnerDescription': {
    marginY: '$3', minHeight: 120
  }
})


export const StyledOtherPartners = styled('div', {
  marginTop: rem(58),
  '@md': { marginTop: rem(120), },
})

export const StyledOtherPartnersList = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  columnGap: '$3',
  rowGap: '$3',
  marginTop: rem(32),
  borderTop: '1px solid $white',
  '@md': {
    borderTop: 'none',
    marginTop: rem(48)
  }


})

export const StyledOtherPartner = styled(SimpleLink, {
  paddingY: '$3',
  fontSize: '$p1-mid-mobile',
  lineHeight: '$p1-mid-mobile',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid $white',

  [Icon]: {
    'svg': {
      size: rem(16),
      '@md': { display: 'none' }
    }
  },

  '@md': {
    paddingY: '$2',
    br: rem(90),
    borderBottom: 'none',
    paddingX: '$4',
    width: 'auto',
    border: '1px solid',
    fontSize: '$p1-mid',
    lineHeight: '$p1-mid',
  },

  variants: {
    theme: {
      default: {
        borderColor: '$primary',
        color: '$primary',
        [Icon]: { 'svg': { fill: '$primary' } },
      },
      inverted: {
        borderColor: '$white',
        color: '$white',
        [Icon]: { 'svg': { fill: '$white' } },
      }
    }
  }
})
