import React from 'react'
import { motion } from 'framer-motion'

import Heading from '@/UI/Heading'
import RichText from '@/UI/RichText'
import CustomImage from '@/Image'

import { FadeIn } from 'animations'

import { StyledFeaturedPartner } from './styles'

const FeaturedPartner = ({
  ctaText,
  ctaUrl,
  description,
  featureImage,
  title,
  theme,
  ctaLabel,
}) => (
  <StyledFeaturedPartner
    as={motion.div}
    variants={FadeIn()}
  >
    <Heading as="h3" size="s2">{title}</Heading>
    <RichText className='PartnerDescription' content={description} />
    <CustomImage
      href={ctaUrl}
      className="PartnerImages"
      media={featureImage}
      rounded="normal"
      cursor={{
        type: `${theme}Link`,
        text: ctaLabel,
        blendMode: 'normal'
      }}
      cta={{
        theme,
        label: ctaText,
        href: ctaUrl,
        target: '_self',
        rel: 'noopener'
      }} />
  </StyledFeaturedPartner>
)

export default FeaturedPartner
