import {
  styled,
  rem,
  HEADER_HEIGHT
} from 'config/stitches.config'

export const StyledTitleContainer = styled('div', {
  marginBottom: 0,
  maxWidth: 900,
  position: 'relative',
  variants: { fullScreen: { true: { marginBottom: rem(37), } } }
})

export const StyledHeroContainer = styled('div', {
  width: '100%',
  paddingTop: rem(192),
  paddingBottom: rem(40),

  '.TitleInCover': {
    color: '$white',
    textShadow: '0 2px 2px rgb(0 0 0 / 13%)',
    'div:first-of-type': {
      top: 0, left: 0
    }
  },
  'h1, h2': {
    position: 'relative',
    overflow: 'hidden',

    'span': { display: 'inline-block' }
  },
  '.HeroTitleWord': { lineHeight: rem(57) },
  '.HeroCta': {
    fontSize: '$subtitle-2-mobile',
    lineHeight: '$subtitle-2-mobile'
  },
  '@md': { paddingTop: rem(220) },
  '@lg': {
    '.HeroTitleWord': { lineHeight: '110%' },
    '.HeroTitleLetter': { transform: 'translateY(-7px)' }
  }
})

export const StyledHeroSubtitle = styled('div', {
  display: 'inline-flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  columnGap: '$3',
  color: '$black',
  '.no-hover': {
    height: 'auto',
    backgroundColor: '$black',
    borderColor: '$black',
    '@md': { paddingX: '$2' }

  }
})


export const StyledHero = styled('div', {
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',

  '@md': { alignItems: 'flex-start', },
  // '.plyr': { display: 'none' },
  //   height: '100%',
  //   // maxHeight: '100vh',

  //   top: 0,
  //   left: 0,
  //   position: 'absolute',
  //   width: '100%',
  '.plyr__video-wrapper': {
    width: '100%',
    height: '100%',
    // maxHeight: '100vh',
  },
  // },

  variants: {
    fullScreen: {
      true: {
        // minHeight: `calc(100vh - ${HEADER_HEIGHT.mobile}px)`,
        [StyledHeroContainer]: {
          zIndex: 3,
          paddingBottom: rem(120),
          '@md': { paddingBottom: rem(62), }
        },
        '&::before': {
          // TODO: define final effect
          backgroundColor: '$primary',
          opacity: 0.35,
          zIndex: 2,
          content: '',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
        '.MediaWithTag': {
          marginTop: 0,
          position: 'absolute',
          overflow: 'hidden',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          '> div': {
            width: '100%',
            height: '100%',
          },
          'img': {
            br: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center center'
          }
        },
        // '@md': { minHeight: `calc(100vh - ${HEADER_HEIGHT.desktop}px)`, }
      },
      false: {
        [StyledHeroSubtitle]: { color: '$black' },
        [StyledHeroContainer]: {
          paddingTop: `clamp(${rem(192)}, 30vh, 50vh)`,
          '@md': { paddingTop: rem(220) }
        },
      },
    }
  }
})

export const StyledHeroSubtitleWrapper = styled('div', { })


export const StyledHeroCtasWrapper = styled('div', {
  marginTop: rem(56),
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: rem(16)
})
