import React, { forwardRef } from 'react'

import Heading from '@/UI/Heading'

import Controller from './Controller'

import {
  StyledFieldset,
  StyledFieldsetTitle,
  StyledFieldsetContent
} from './styles'

const Fieldset = forwardRef(function Fieldset({
  title = null, fields = null
}, ref) {
  return (
    <StyledFieldset>
      {
        title &&
        <StyledFieldsetTitle>
          <Heading as='strong' size="s1">{title}</Heading>
        </StyledFieldsetTitle>
      }

      {
        fields &&
        !!fields.length &&
        <StyledFieldsetContent>
          {
            fields.map((field, idx) => <Controller key={idx} {...field} ref={ref}/>)
          }
        </StyledFieldsetContent>

      }
    </StyledFieldset>
  )
})

export default Fieldset
