import {
  styled,
  rem
} from 'config/stitches.config'

import { motion } from 'framer-motion'

import Button from '@/UI/Button'
import Text from '@/UI/Text'
import SimpleLink from '@/UI/Link'

export const StyledStores = styled(motion.div, {
  '@md': {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
    padding: rem(22),
  }
})

export const StyledStoresContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  height: '100%',
  width: '100%',
  paddingX: '$3',
  paddingBottom: '$2',

  '@md': {
    br: 40,
    width: 460,
    padding: '$3 $4',
  },

  '.StoreToggle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: rem(16),
    lineHeight: rem(21.6),

    'span sup': {
      marginLeft: rem(4),
      color: '#A6A6A6'
    },

    '.icon': {
      width: 'auto',
      svg: { fill: 'none' }
    }
  }
})

export const StyledStoresHeader = styled('div', {
  display: 'inline-flex',
  borderTop: '1px solid $black',
  marginTop: '$2',
  paddingY: '$2',
  gap: '$2',
  overflow: 'auto',

  [Button]: {
    paddingY: '$2',
    paddingX: '$4',
    fontSize: rem(14),
    lineHeight: rem(20),
  }
})

export const StyledStoresContent = styled(motion.div, {
  height: '100%',
  width: '100%',
  overflow: 'auto',
})

export const StyledStoresContentItems = styled('div', {
  height: '100%',
  overflow: 'auto'
})

export const StyledItemsContainer = styled('div', {
  '&.mobile-map': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '$white',
    borderRadius: '$smaller $smaller 0 0',
    padding: '$2 $3',
    zIndex: 1,

    '.icon': {
      position: 'absolute',
      top: rem(20),
      right: rem(28),
      width: 16,
      height: 16,
    },

    'li': { borderBottom: 'none' }
  },
})

export const StyledItems = styled('ul', {
  listStyle: 'none',
  width: '100%',
  padding: 0,
  margin: 0,

  '&.mobile-map': {
    position: 'absolute',
    bottom: 0,
    backgroundColor: '$white',
    borderRadius: '$smaller $smaller 0 0',
    padding: '$2 $3',
    zIndex: 1,

    'li': { borderBottom: 'none' }
  }
})

export const StyledItem = styled('li', {
  borderBottom: '1px solid $black',

  [Button]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '$black',
    width: '100%',
    border: 'none',
    br: 0,
    padding: `${rem(26)} 0`,
    fontSize: rem(18),
    lineHeight: rem(20),

    '.icon': {
      width: 'auto',
      svg: { fill: 'none' }
    }
  }
})


export const StyledDetail = styled('div', {
  paddingY: '$2',

  [Text]: {
    marginBottom: '$1',

    '&:last-child': { marginBottom: 0 }
  }
})

export const StyledDetailHeaderMain = styled('div', {
  flexGrow: 1,

  [Text]: {
    marginBottom: '$6',

    '&:last-child': { marginBottom: 0 }
  }
})

export const StyledDetailHeader = styled('div', {
  marginBottom: '$3', display: 'flex', alignItems: 'center'
})
export const StyledDetailHeaderMap = styled('div', {
  marginLeft: '10px', marginRight: '10px'
})

export const StyledNavigationCta = styled(SimpleLink, {
  backgroundColor: '$primary',
  justifyContent: 'center',
  color: '$white',
  marginTop: '$3',
  textDecoration: 'none',
  paddingY: rem(16),
  br: 80
  // '@md': {
  //   paddingY: rem(10),
  //   paddingX: rem(24),
  //   br: 80
  // }
})

export const StyledStoresContentBreadcrumbs = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginY: '$2',
})

export const StyledBreadcrumbsButton = styled('button', {
  backgroundColor: 'transparent',
  color: '$gray3',
  border: 'none',
  textDecoration: 'underline',
  fontWeight: '$1',
  marginX: '$1',
  padding: 0,
  fontSize: '$cta-primary',
  cursor: 'pointer',

  '&:first-child': { marginLeft: 0 }
})

export const StyledBreadcrumbsSeparatorSpan = styled('span', {
  fontWeight: '$3',
  fontSize: '$cta-underline'
})

export const StyledBreadcrumbsText = styled('span', {
  fontWeight: '$1',
  fontSize: '$cta-primary',
  marginLeft: '$1',
})
