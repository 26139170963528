import React from 'react'

import CustomImage from '@/Image'
import Heading from '@/UI/Heading'
import Text from '@/UI/Text'

import {
  StyledNewsPreview,
  StyledNewsPreviewContainer,
  StyledNewsPreviewContent,
} from './styles'


const NewsPreview = ({
  link,
  date,
  preview,
}) => (
  <StyledNewsPreview>
    <StyledNewsPreviewContainer {...link}>
      {
        preview?.title &&
        <StyledNewsPreviewContent>
          <Text as="span" size={{
            '@initial': 'p4-mobile',
            '@md': 'p4'
          }}>{date}</Text>
          <Heading as="strong" size="news">{preview.title}</Heading>
        </StyledNewsPreviewContent>

      }
      {
        preview?.mediaWithTag &&
        <CustomImage className="NewsPreview_image" {...preview.mediaWithTag} as="div"rounded="normal" />
      }

    </StyledNewsPreviewContainer>
  </StyledNewsPreview>
)

export default NewsPreview
