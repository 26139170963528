import React, { useState } from 'react'

import Tabs from '@/UI/Tabs'
import Stores from './Stores'
import Map from './Map'

import { StyledStoreLocatorMobile } from './styles'

const StoreLocatorMobile = ({
  ctaLabel,
  breadcrumbLabel,
  mapTabLabel,
  sectionTabLabel,
  stores,
  count,
  state,
  dispatch
}) => {
  const tabs = {
    header: [
      {
        id: 0,
        title: sectionTabLabel,
        size: 'big'
      },
      {
        id: 1,
        title: mapTabLabel,
        size: 'big'
      }
    ]
  }

  const [selectedTab, setSelectedTab] = useState(tabs.header[0].id)

  const onTabClick = (id) => {
    setSelectedTab(id)
  }

  return (
    <StyledStoreLocatorMobile>
      {
        <Tabs
          theme={'noBackground'}
          header={tabs.header}
          onTabClick={onTabClick}
          selectedTab={selectedTab}
        >
          {
            tabs.header.map(({ id }) => {
              let componentToShow = <Stores
                key={id} label={ctaLabel}
                breadcrumbLabel={breadcrumbLabel}
                items={stores}
                count={count}
                state={state}
                dispatch={dispatch}
                isMobile={true} />
              if (id == 1) {
                componentToShow = <Map key={id} state={state} dispatch={dispatch} isMobile={true} />
              }
              return id == selectedTab
                && (
                  componentToShow
                )
            })
          }
        </Tabs>}
    </StyledStoreLocatorMobile>
  )
}

export default StoreLocatorMobile
