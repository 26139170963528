import React from 'react'

const manufacturing = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="10" fill="#26376D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.6872 24.6667H26.6462L27.3545 47.3333H17.9789L18.6872 24.6667ZM19.9795 26L19.3545 46H25.9789L25.3539 26H19.9795Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.6667 30.6667C18.6667 30.2985 18.9652 30 19.3334 30H26C26.3682 30 26.6667 30.2985 26.6667 30.6667C26.6667 31.0349 26.3682 31.3333 26 31.3333H19.3334C18.9652 31.3333 18.6667 31.0349 18.6667 30.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18 25.3333C18 24.9651 18.2985 24.6667 18.6667 24.6667H26.6667C27.0349 24.6667 27.3334 24.9651 27.3334 25.3333C27.3334 25.7015 27.0349 26 26.6667 26H18.6667C18.2985 26 18 25.7015 18 25.3333Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6667 46.6667C16.6667 46.2985 16.9652 46 17.3334 46H46.6667C47.0349 46 47.3334 46.2985 47.3334 46.6667C47.3334 47.0349 47.0349 47.3333 46.6667 47.3333H17.3334C16.9652 47.3333 16.6667 47.0349 16.6667 46.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26 39.3333C26 35.6514 28.9848 32.6667 32.6667 32.6667C36.3486 32.6667 39.3334 35.6514 39.3334 39.3333V47.3333H26V39.3333ZM32.6667 34C29.7212 34 27.3334 36.3878 27.3334 39.3333V46H38V39.3333C38 36.3878 35.6122 34 32.6667 34Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.6667 30.6667C36.6667 28.0893 38.756 26 41.3334 26C43.9107 26 46 28.0893 46 30.6667V40H44.6667V30.6667C44.6667 28.8257 43.1743 27.3333 41.3334 27.3333C39.4924 27.3333 38 28.8257 38 30.6667V33.3333H36.6667V30.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32 24C32 22.8954 32.8955 22 34 22H40C41.1046 22 42 22.8954 42 24V26.6667C42 27.0349 41.7015 27.3333 41.3334 27.3333C40.9652 27.3333 40.6667 27.0349 40.6667 26.6667V24C40.6667 23.6318 40.3682 23.3333 40 23.3333H34C33.6318 23.3333 33.3334 23.6318 33.3334 24V33.3333C33.3334 33.7015 33.0349 34 32.6667 34C32.2985 34 32 33.7015 32 33.3333V24Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30 42C30 40.5272 31.1939 39.3333 32.6667 39.3333C34.1394 39.3333 35.3334 40.5272 35.3334 42V47.3333H30V42ZM32.6667 40.6667C31.9303 40.6667 31.3334 41.2636 31.3334 42V46H34V42C34 41.2636 33.4031 40.6667 32.6667 40.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40 34H33.3334V32.6667H40C43.3137 32.6667 46 35.353 46 38.6667V47.3333H33.3334V46H44.6667V40.6667H42C41.6318 40.6667 41.3334 40.3682 41.3334 40C41.3334 39.6318 41.6318 39.3333 42 39.3333H44.6667V38.6667C44.6667 36.0893 42.5774 34 40 34Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6667 22.6667C20.6667 22.2985 20.9652 22 21.3334 22H24C24.3682 22 24.6667 22.2985 24.6667 22.6667C24.6667 23.0349 24.3682 23.3333 24 23.3333H21.3334C20.9652 23.3333 20.6667 23.0349 20.6667 22.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22 20.6667C22 20.2985 22.2985 20 22.6667 20H28C28.3682 20 28.6667 20.2985 28.6667 20.6667C28.6667 21.0349 28.3682 21.3333 28 21.3333H22.6667C22.2985 21.3333 22 21.0349 22 20.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3334 18.6667C19.3334 18.2985 19.6318 18 20 18H26.6667C27.0349 18 27.3334 18.2985 27.3334 18.6667C27.3334 19.0349 27.0349 19.3333 26.6667 19.3333H20C19.6318 19.3333 19.3334 19.0349 19.3334 18.6667Z" fill="white"/>
  </svg>
)

export default manufacturing
