import React from 'react'

import SectionContainer from '@/Section/Container'
import StoreLocator from '@/StoreLocator'

const Map = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
    footer={
      <StoreLocator {...sectionContent}/>
    }
  />
)

export default Map
