import React, {
  useReducer, useState
} from 'react'
import useIsMobile from 'hooks/useIsMobile'

import StoreLocatorDesktop from './desktop'
import StoreLocatorMobile from './mobile'


function reducer(state, action) {
  const { allItems } = state
  const allCountries = allItems.reduce((acc, item) => acc.concat(item.countries), [])
  const allCities = allCountries.reduce((acc, item) => acc.concat(item.cities), [])
  const allStores = allCities.reduce((acc, item) => acc.concat(item.stores), [])

  switch (action.type) {
    case 'continent': {
      // if the user clicks on the same continent, reset the state
      const allMapItems = allItems
      if (state.levels.continent && state.levels.continent.code === action.code && !action.keepContinentSelected) {
        return {
          levels: {
            continent: {},
            countries: {},
            cities: {},
            store: {}
          },
          componentToShow: 'List',
          show: 'countries',
          nextLevel: 'continent',
          allItems,
          allMapItems,
          items: allItems,
          previousStates: [],
        }
      }

      const items = allItems.find(continent => continent.code === action.code).countries
      const editedState = {
        ...state,
        levels: {
          continent: {
            name: action.name,
            code: action.code,
          },
          countries: {},
          cities: {},
          store: {}
        },
        componentToShow: 'List',
        show: 'cities',
        nextLevel: 'countries',
        allItems,
        allMapItems: allCountries,
        items,
        zoomLevel: action.zoomLevel,
        previousStates: [],
      }

      if (state.levels.countries.code === undefined) {
        editedState.previousStates = [state]
      } else {
        editedState.previousStates = [state.previousStates[0]]
      }

      return editedState
    }
    case 'countries': {
      const countries = allCountries.find(continent => continent.code === action.code)
      const { cities: items } = countries

      return {
        ...state,
        levels: {
          ...state.levels,
          continent: action.continent,
          countries: {
            name: action.name,
            code: action.code,
          },
          cities: {},
          store: {}
        },
        show: 'stores',
        componentToShow: 'List',
        nextLevel: 'cities',
        items,
        allItems,
        allMapItems: allCities,
        zoomLevel: action.zoomLevel,
        previousStates: [...state.previousStates, state],
      }
    }
    case 'cities': {
      const { stores: items } = allCities.find(city => city.id === action.code)

      return {
        ...state,
        levels: {
          ...state.levels,
          continent: action.continent,
          countries: action.countries,
          cities: {
            name: action.name,
            code: action.code,
          },
          store: {}
        },
        nextLevel: 'store',
        componentToShow: 'Details',
        allItems,
        allMapItems: allStores,
        items,
        zoomLevel: action.zoomLevel,
        previousStates: [...state.previousStates, state],
      }
    }

    case 'store': {
      const items = allStores.filter(store => store.id === action.code)

      return {
        ...state,
        levels: {
          continent: action.continent,
          countries: action.countries,
          cities: action.cities,
          store: {
            code: action.code,
            name: action.name,
          },
        },
        componentToShow: 'Details',
        allItems,
        items,
        previousStates: [...state.previousStates],
      }
    }
    case 'back': {
      const clonedState = structuredClone(state)
      const previousState = clonedState.previousStates.pop()
      return previousState
    }

    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const StoreLocator = ({
  ctaLabel,
  breadcrumbLabel,
  mapTabLabel,
  sectionTabLabel,
  stores,
  count
}) => {
  const isMobile = useIsMobile()
  const initialState = {
    levels: {
      continent: {},
      countries: {},
      cities: {},
      store: {}
    },
    componentToShow: 'List',
    show: 'countries',
    nextLevel: 'continent',
    allItems: stores,
    allMapItems: stores,
    items: stores,
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const [storeToggleState, setStoreToggleState] = useState(isMobile ? 'opened' : 'closed')
  return (
    <div style={{
      width: '100%', overflow: 'hidden'
    }}>
      {isMobile
        ? <StoreLocatorMobile
          ctaLabel={ctaLabel}
          breadcrumbLabel={breadcrumbLabel}
          mapTabLabel={mapTabLabel}
          sectionTabLabel={sectionTabLabel}
          stores={stores}
          count={count}
          state={state}
          dispatch={dispatch}></StoreLocatorMobile>
        : <StoreLocatorDesktop
          ctaLabel={ctaLabel}
          breadcrumbLabel={breadcrumbLabel}
          stores={stores}
          count={count}
          state={state}
          setStoreToggleState={setStoreToggleState}
          storeToggleState={storeToggleState}
          dispatch={dispatch}
        >
        </StoreLocatorDesktop>
      }
    </div>
  )
}

export default StoreLocator
