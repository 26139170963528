import React from 'react'
import DropDown from '@/UI/Dropdown'
import { StyledNavigationItem } from './styles'

const NavigationItem = ({
  title,
  items,
  ...menuItemProps
}) => {
  return (
    items
      ? <DropDown trigger={title}>
        {
          items.map(item => (
            <StyledNavigationItem key={item.title} {...item}>
              <span>{item.title}</span>
            </StyledNavigationItem>
          ))
        }
      </DropDown>
      : <StyledNavigationItem {...menuItemProps}>
        <span>{title}</span>
      </StyledNavigationItem>
  )
}

export default NavigationItem
