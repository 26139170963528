import React from 'react'

const search = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4734 15.4798L11.105 11.1113" stroke="#4D4D4F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M6.97217 12.8232C10.1996 12.8232 12.8159 10.2069 12.8159 6.97949C12.8159 3.75208 10.1996 1.13574 6.97217 1.13574C3.74475 1.13574 1.12842 3.75208 1.12842 6.97949C1.12842 10.2069 3.74475 12.8232 6.97217 12.8232Z" stroke="#4D4D4F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
  </svg>
)

export default search
