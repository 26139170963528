/* eslint-disable no-return-assign */
import React, {
  useRef, useState, useEffect
} from 'react'

import StatsDetail from './StatsDetail'

import Grid from '@/UI/Grid'
import Text from '@/UI/Text'

import {
  StyledStats,
  StyledStat,
  StyledStatTextWrapper
} from './styles'

const Stats = ({ items }) => {
  const textsRef = useRef([])
  const [rowsNumber, setRowsNumber] = useState(1)

  useEffect(() => {
    let number
    textsRef.current.forEach(t => {
      if ((t.offsetHeight / 27) > rowsNumber) {
        number = t.offsetHeight / 27
      }
    })
    setRowsNumber(number)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    items &&
    !!items.length &&
    <StyledStats>
      <Grid
        columns={{
          '@initial': 1,
          '@md': 2,
          '@xl': 3
        }}
      >
        {
          items.map(({
            id,
            title,
            subtitle
          }, i) => {
            return (
              <StyledStat key={id}>
                <StatsDetail content={title}/>
                <StyledStatTextWrapper rows={rowsNumber}>
                  <Text
                    as="p"
                    size={{ '@initial': 'subtitle-2-mobile' }}
                    ref={el => textsRef.current[i] = el}>
                    {subtitle}
                  </Text>
                </StyledStatTextWrapper>
              </StyledStat>
            )
          })}
      </Grid>


    </StyledStats>
  )
}

export default Stats
