import React, { useContext } from 'react'
import { GeneralContext } from 'contexts/General'
import { StyledLogo } from './styles'
import Image from '@/UI/Image'

const Logo = (props) => {
  const {
    options,
    page
  } = useContext(GeneralContext)

  const { logo } = options

  return (
    <StyledLogo href='/' darkMode={page.pageDarkTheme} aria-label={options.company} {...props}>
      <Image
        {...logo}
        alt="Transmec"
        sizes="(max-width: 768px) 156px"
        loading="eager"
        priority
      />
    </StyledLogo>
  )
}

export default Logo
