import {
  rem,
  styled,
  HEADER_HEIGHT
} from 'config/stitches.config'

import Image from '@/Image'

Image.toString = () => '.ListImage'

export const StyledListWithImages = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column-reverse',
  columnGap: rem(72),
  rowGap: rem(40),
  marginBlockEnd: 72,

  '&:last-child': { marginBlockEnd: 0, },


  '@md': {
    flexDirection: 'row',
    marginTop: rem(120),
    marginBottom: rem(72),
  },

  variants: { direction: { right: { '@md': { flexDirection: 'row-reverse' } }, } },

  [`${Image}`]: {
    flexShrink: 0,
    flexBasis: '100%',
    '@md': {
      flexBasis: 'clamp(150px, 40%, 450px)',
      position: 'sticky',
      top: HEADER_HEIGHT.desktop + 24,
    },
    'img': {
      minHeight: rem(320),
      objectFit: 'cover',
      '@md': {
        height: `calc(100vh - ${HEADER_HEIGHT.desktop + 48}px)`,
        maxHeight: `calc(100vh - ${HEADER_HEIGHT.desktop + 48}px)`
      },
    }
  }
})


export const StyledListContent = styled('div', {
  display: 'flex',
  flexBasis: '100%',
  flexDirection: 'column',
  rowGap: rem(48),
  '@md': {
    flexBasis: '60%',
    rowGap: rem(64)
  },
  variants: { fullWidth: { true: { flexBasis: '100%', } } }
})
