import {
  useState,
  useEffect
} from 'react'

import breakpoints from 'theme/breakpoints'

const useIsMobile = (breakpoint = 'md') => {
  const [isMobile, setIsMobile] = useState(undefined)

  const checkIsMobile = () => {
    const { width } = document.body.getBoundingClientRect()
    setIsMobile(width < breakpoints[breakpoint])
  }

  useEffect(() => {
    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)
    return () => {
      if (window) {
        window.removeEventListener('resize', checkIsMobile)
      }
    }
  }, [])

  return isMobile
}

export default useIsMobile
