import React, { useContext } from 'react'

import { MenuContext } from 'contexts/Menu'

import FormComponent from '@/Form'
import Text from '@/UI/Text'
import {
  StyledNewsletter, StyledNewsletterForm
} from './styles'

const Newsletter = () => {
  const { menus } = useContext(MenuContext)
  const { newsletterForm } = menus.footer

  return (
    !!newsletterForm &&
    <StyledNewsletter>
      <Text
        css={{ color: '$gray4' }}
        size={{
          '@initial': 'p1-mid-mobile',
          '@md': 'p1',
        }}
        dangerouslySetInnerHTML={{ __html: newsletterForm.form.fields.payoff }}
      />

      <StyledNewsletterForm>
        <FormComponent validateOnMount className="NewsletterForm" {...newsletterForm} />
      </StyledNewsletterForm>
    </StyledNewsletter>
  )
}

export default Newsletter
