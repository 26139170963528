import React from 'react'

import Icon from '@/UI/Icon'
import Line from '@/UI/Line'

import { ScaleX } from 'animations'

import {
  StyledDivider,
  StyledDividerHeader,
  StyledDividerPlaceholder,
  StyledDividerTitle
} from './styles'

const ContentDivider = ({
  icon = null,
  label = null,
  title = null,
  theme = 'default', // default|inverted|lightGray
  layout = 'row',
  size,
  lineProps = {},
  withLine = true,
  as = 'strong',
  children
}) => (
  <StyledDivider
    className='Divider'
    layout={layout}
  >
    <StyledDividerHeader
      theme={theme}
      layout={layout}>
      {
        (icon || label) &&
        <StyledDividerPlaceholder theme={theme} className={icon ? 'with-icon' : null}>
          {icon && <Icon name={icon} />}
          {label}
        </StyledDividerPlaceholder>
      }

      {
        title &&
        <StyledDividerTitle as={as} size={size} dangerouslySetInnerHTML={{ __html: title }} />
      }
    </StyledDividerHeader>

    {
      withLine &&
      <Line
        variants={ScaleX()}
        className='DividerLine'
        theme={theme}
        {...lineProps}
      />
    }
    {children}
  </StyledDivider>
)

export default ContentDivider
