import {
  styled,
  rem
} from 'config/stitches.config'

import Link from '@/UI/Link'

export const StyledLogo = styled(Link, {
  gridArea: 'logo',
  justifySelf: 'start',
  paddingRight: '$3',
  pointerEvents: 'auto',

  'img': {
    width: rem(180),
    height: 'auto',
  },
})
