import React from 'react'

import ContentDivider from '@/ContentDivider'
import SimpleLink from '@/UI/Link'
import Column from '@/UI/Column'
import Text from '@/UI/Text'
import RichText from '@/UI/RichText'
import TagList from '@/UI/Tag/List'
import Button from '@/UI/Button'
import Icon from '@/UI/Icon'

import {
  StyledJobsPreview,
  StyledJobsPreviewContent,
  StyledJobsPreviewInfos
} from './styles'

const JobPreview = ({
  description,
  link,
  tags,
  title,
  idx,
}) => {
  const contentProps = link ? {
    as: SimpleLink, ...link
  } : {}

  const JobsCta = (props) => <Button
    as={'div'}

    size="verySmall"
    theme={'default'}
    css={{
      flexShrink: 0,
      justifyContent: 'center'
    }}
    {...props}
  >
    <Icon css={{ size: '80%' }} theme="inverted" name="arrowRight" />
  </Button>


  return (
    <StyledJobsPreview {...contentProps}>
      <ContentDivider
        label={idx < 9 ? `0${idx + 1}` : idx + 1}
        lineProps={{
          initial: { transform: 'scale(0)' },
          animate: { transform: 'scale(1)' },
        }}
      >
        <JobsCta className='JobsCtaHeader' />
      </ContentDivider>
      <StyledJobsPreviewContent >
        <Column col={{ '@lg': 10 }}>
          <Text
            css={{ fontWeight: '$1' }}
            as="h3"
            size={{
              '@initial': 'cta-underline-mobile',
              '@md': 'cta-underline'
            }}>{title}</Text>
          <RichText content={description} size={{
            '@initial': 'p3-mid-mobile',
            '@md': 'p3-mid'
          }} />
        </Column>
        <Column
          css={{
            overflow: 'hidden',
            display: 'inline-flex',
            justifyContent: 'flex-start',
            '@md': { justifyContent: 'flex-end' }
          }}

          col={{ '@lg': 2 }}
        >
          <StyledJobsPreviewInfos>
            <TagList tags={tags} />
            <JobsCta />
          </StyledJobsPreviewInfos>
        </Column>
      </StyledJobsPreviewContent>
    </StyledJobsPreview>
  )
}

export default JobPreview
