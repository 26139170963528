import {
  styled,
  rem
} from 'config/stitches.config'

export const StyledDivider = styled('div', {
  width: '100%',
  display: 'flex',
  variants: {
    layout: {
      row: {
        columnGap: rem(10),
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap'
      },
      column: { flexWrap: 'wrap' }
    }
  }
})

export const StyledDividerHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: rem(10),
  variants: {
    layout: {
      row: {
        width: 'auto',
        flexShrink: 0
      },
      column: {
        width: '100%',
        marginBottom: '$2'
      }
    },
    theme: {
      default: { 'strong': { color: '$primary', } },
      inverted: { 'strong': { color: '$white', } },
      lightGray: { 'strong': { color: '$accessibleGray', } }
    }
  }
})


export const StyledDividerPlaceholder = styled('div', {
  size: '$3',
  br: '$round',
  fontWeight: '$1',
  fontSize: '$p3-mid-mobile',
  lineHeight: '$p3-mid-mobile',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  justifyContent: 'center',
  flexShrink: 0,
  '&.with-icon': { border: 'none', },
  '.icon': { size: '60%', },
  '@md': {
    '&.with-icon': { border: '1px solid', },
    size: '40px',
    border: '1px solid',
    fontSize: '$p3-mid',
    lineHeight: '$p3-mid',
  },
  variants: {
    theme: {
      default: {
        borderColor: '$black',
        backgroundColor: 'transparent',
        color: '$black',
        '.icon svg': { fill: '$black', },
        'strong': { color: '$black', }
      },
      inverted: {
        borderColor: '$white',
        backgroundColor: '$primary',
        color: '$white',
        '.icon svg': { fill: '$white', },
        'strong': { color: '$white', }
      },
      lightGray: {
        borderColor: '$lightGray',
        backgroundColor: '$primary',
        color: '$lightGray',
        '.icon svg': { fill: '$lightGray', },
        'strong': { color: '$lightGray', }
      },

    }
  },
  defaultVariants: { theme: 'default' }
})


export const StyledDividerTitle = styled('strong', {
  width: '100%',
  fontWeight: '$1',
  variants: {
    size: {
      default: {
        fontSize: '$p1-mid-mobile',
        lineHeight: '$p1-mid-mobile',
        '@md': {
          fontSize: '$p1-mid',
          lineHeight: '$p1-mid',
        }
      },
      'sub-desk': {
        fontSize: '$sub-desk',
        lineHeight: '$sub-desk',
        textTransform: 'uppercase',
      }
    }
  },

  defaultVariants: { size: 'default' },
})
