import React, { useState } from 'react'
import {
  motion,
  AnimatePresence
} from 'framer-motion'

import {
  StyledTabsHeaderButton,
  StyledTabsHeaderButtonHover
} from './styles'

const buttonMotion = {
  initial: {
    x: '-50%',
    y: '100%',
  },
  animate: {
    x: '-50%',
    y: '0%',
  },
  exit: {
    x: '-50%',
    y: '-200%',
  },
  transition: { duration: 0.6 }
}

const Tab = ({
  id,
  theme,
  onTabClick,
  selectedTab,
  title,
  totalItems,
  size,
}) => {
  const [buttonHovered, setButtonHovered] = useState(null)
  return (
    <StyledTabsHeaderButton
      as={motion.button}
      size={size}
      theme={theme}
      selected= {id == selectedTab}
      onClick={() => onTabClick(id)}
      hovered={!!buttonHovered}
      onHoverStart={() => {
        setButtonHovered(id)
      }}
      onHoverEnd={() => setButtonHovered(null)}
    >
      <AnimatePresence>
        {
          buttonHovered &&
          <StyledTabsHeaderButtonHover as={motion.span} {...buttonMotion} />
        }
      </AnimatePresence>
      <span style={{
        position: 'relative',
        zIndex: 1
      }}>{title}</span>
      <sup>{totalItems}</sup>
    </StyledTabsHeaderButton>
  )
}

export default Tab
