import { styled } from 'config/stitches.config'

import Image from 'next/image'

export const StyledImage = styled(Image, {
  verticalAlign: 'middle',
  maxWidth: '100%',
  maxHeight: '100%',
  height: 'auto',
  variants: {
    rounded: {
      none: { br: 0 },
      small: { br: 16 },
      normal: { br: 20 },
      big: { br: 40 }
    }
  },
  defaultVariants: { rounded: 'none' },
})
