import {
  styled,
  rem
} from 'config/stitches.config'


export const StyledImagesList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  'a': {
    maxWidth: '100%',
    '.SeparatedIconCta-label': {
      color: '$black',
      fontSize: '$p1-mobile',
      lineHeight: '$p1-mobile',
      fontWeight: '$1',

      '@md': {
        fontSize: '$p3-mid',
        lineHeight: '$p3-mid',
      }
    },
    '.SeparatedIconCta-icon svg': { fill: '$black', },
    'img': {
      width: '100%',
      height: '100%',
      '@md': { width: 'auto' },
    },
    '@md': { maxWidth: '100%' },
  },
  '@md': {

    flexDirection: 'row',
    'a': {
      aspectRatio: '382 / 450',
      width: '100%',
      maxWidth: '33.3333%',
      'img': {
        width: '100%',
        objectFit: 'cover',
      }
    }
  },
  variants: {
    layout: {
      'only-title': {
        marginTop: rem(36),
        '@md': { marginTop: rem(126) }
      }
    }
  }
})
