import React, {
  useCallback, useEffect, useState
} from 'react'
import { useFormikContext } from 'formik'

import {
  StyledField,
  StyledFieldContainer,
  StyledFieldLabel
} from './styles'

import FieldError from './Error'

const Field = ({
  id = null,
  type,
  label,
  field,
  meta,
  helpers,
  children,
  dependsOn
}) => {
  const { values } = useFormikContext()
  const checkDependsOn = useCallback((_foundMatch) => {
    let isMatching = true
    let founds = null
    if (!!dependsOn) {
      const keys = Object.keys(dependsOn)
      founds = keys.filter(k => {
        const valueToCheck = dependsOn[k].split('not:')
        return valueToCheck.length > 1 ? values[k] !== valueToCheck[1] : values[k] === valueToCheck[0]
      })
      isMatching = !!founds.length
    }
    return {
      match: isMatching, founds
    }
  }, [dependsOn, values])
  const [foundMatch, setFoundMatch] = useState(checkDependsOn())

  useEffect(() => {
    const {
      match, founds
    } = checkDependsOn()
    if (founds && match !== foundMatch) {
      setFoundMatch(match)
      if (helpers) {
        helpers.setValue('')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDependsOn, dependsOn, values])


  return (
    (!dependsOn || foundMatch) &&
    <StyledField
      type={type}
      data-type={type}
      disabled={field.disabled}
    >
      <StyledFieldContainer
        type={type}
        error={meta?.touched && !!meta?.error}
        withValue={!!field?.value?.length}>

        {children}

        {
          label &&
        <StyledFieldLabel htmlFor={id || field.name} dangerouslySetInnerHTML={{ __html: label }} />
        }
      </StyledFieldContainer>

      <FieldError {...meta} />
    </StyledField>
  )
}

export default Field
