import React, {
  useMemo,
  useEffect
} from 'react'

import { AnimatePresence } from 'framer-motion'

import Icon from '@/UI/Icon'
import Button from '@/UI/Button'

import MapBackButton from '../Map/back'
import StoresBreadcrumbs from './Breadcrumbs'

import {
  StyledStores,
  StyledStoresContainer,
  StyledStoresHeader,
  StyledStoresContent,
  StyledStoresContentItems
} from './styles'

import List from './List'
import Details from './Details'

const storesVariants = {
  closed: { height: 'auto' },
  opened: { height: '100%' }
}

const ComponentMap = {
  List,
  Details
}

const createContinentObject = (continent, continents) => {
  return {
    name: continent.name,
    code: continent.code,
    zoomLevel: continent.zoomLevel,
    items: continent.countries,
    continents: continents ?? continent.continents
  }
}

const Stores = ({
  label,
  breadcrumbLabel,
  items,
  state,
  dispatch,
  count,
  isMobile,
  storeToggleState,
  setStoreToggleState,
}) => {
  const onContinentClick = (data) => {
    dispatch({
      type: 'continent',
      ...data,
    })
  }

  const onButtonClick = () => {
    const toggleState = storeToggleState === 'closed' ? 'opened' : 'closed'
    if (!state.levels.continent.name && storeToggleState !== 'opened') {
      const continentObj = createContinentObject(items[0])
      onContinentClick(continentObj)
    }

    setStoreToggleState(toggleState)
  }

  const onItemClick = (data) => {
    dispatch(data)
  }

  const onBackClick = () => {
    dispatch({ type: 'back' })
  }

  useEffect(() => {
    if (state.levels.store.name) {
      setStoreToggleState('opened')
    }
  }, [state])

  const ComponentToRender = useMemo(() => ComponentMap[state.componentToShow] || ComponentMap.List, [state.componentToShow])
  return (
    <>
      {
        !isMobile && state.levels.continent.name &&
        <MapBackButton clickFn={onBackClick}></MapBackButton>
      }
      <StyledStores
        initial={ isMobile ? 'opened' : 'closed' }
        animate={storeToggleState}
        variants={storesVariants}
      >
        <StyledStoresContainer>
          {
            !isMobile &&
          <Button
            className='StoreToggle'
            theme="reset"
            onClick={onButtonClick}>
            <span>
              {label}
              <sup>{count}</sup>
            </span>

            <Icon name="chevronRight" />
          </Button>
          }
          {
            storeToggleState === 'opened' &&
          <>
            <StyledStoresHeader data-lenis-prevent>
              {
                items.map(continent => {
                  const continentObj = createContinentObject(continent, items)

                  return <Button
                    size="small"
                    theme={state.levels.continent.code === continent.code ? 'secondary' : 'inverted'}
                    onClick={() => {
                      onContinentClick(continentObj)
                    }}
                    key={continent.name}>
                    {continent.name}
                  </Button>
                })
              }
            </StyledStoresHeader>

            <AnimatePresence>
              {
                !!state.items &&
                <StyledStoresContent
                  initial={{ x: '100%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '-100%' }}
                >
                  <StoresBreadcrumbs
                    breadcrumbLabel={breadcrumbLabel}
                    items={items}
                    state={state}
                    dispatch={dispatch}
                    onContinentClick={onContinentClick}></StoresBreadcrumbs>
                  <StyledStoresContentItems data-lenis-prevent>
                    {
                      state.levels.continent.name &&
                      <ComponentToRender
                        {...state}
                        onClick={onItemClick}
                      />
                    }
                  </StyledStoresContentItems>
                </StyledStoresContent>
              }
            </AnimatePresence>
          </>
          }
        </StyledStoresContainer>
      </StyledStores>
    </>
  )
}

export default Stores
