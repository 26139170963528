import React from 'react'

import SectionContainer from '@/Section/Container'

import Forms from '@/FormList'

const FormList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) =>{
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
    >
      <Forms tabs={sectionContent.formTab}/>

    </SectionContainer>
  )
}

export default FormList
