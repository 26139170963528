import React, {
  useState, useEffect
} from 'react'

import {
  StyledStoresContentBreadcrumbs,
  StyledBreadcrumbsButton,
  StyledBreadcrumbsSeparatorSpan,
  StyledBreadcrumbsText
} from './styles'

const StoresBreadcrumbs = ({
  breadcrumbLabel,
  items,
  state,
  dispatch,
  onContinentClick
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    const tempBreadcrumbs = []
    if (state.levels.countries && state.levels.countries.name) {
      const continent = items.find(c => c.code === state.levels.continent.code)
      tempBreadcrumbs.push(<StyledBreadcrumbsButton key={breadcrumbLabel} onClick={() => {
        onContinentClick({
          code: continent.code,
          name: continent.name,
          keepContinentSelected: true
        })
      }}>{breadcrumbLabel}</StyledBreadcrumbsButton>)
    }
    if (state.levels.cities && state.levels.cities.name) {
      tempBreadcrumbs.push(<StyledBreadcrumbsSeparatorSpan key="span_1">/</StyledBreadcrumbsSeparatorSpan>)
      tempBreadcrumbs.push(<StyledBreadcrumbsButton key={state.levels.countries.name} onClick={() => {
        dispatch({
          type: 'countries',
          ...state.levels,
          code: state.levels.countries.code,
          name: state.levels.countries.name,
        })
      }}>{state.levels.countries.name}</StyledBreadcrumbsButton>)
      tempBreadcrumbs.push(<StyledBreadcrumbsSeparatorSpan key="span_2">/</StyledBreadcrumbsSeparatorSpan>)
      tempBreadcrumbs.push(<StyledBreadcrumbsText key={state.levels.cities.name}>{state.levels.cities.name}</StyledBreadcrumbsText>)
    }
    setBreadcrumbs(tempBreadcrumbs)
  }, [state])

  return <>
    {
      breadcrumbs.length > 0 &&
        <StyledStoresContentBreadcrumbs>
          {breadcrumbs}
        </StyledStoresContentBreadcrumbs>
    }
  </>
}

export default StoresBreadcrumbs
