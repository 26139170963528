import React from 'react'

import {
  AnimatePresence,
  motion
} from 'framer-motion'

import Tab from './Tab'
import DropDown from '../Dropdown'
import DropDownButton from '@/Buttons/DropDown'
import {
  StyledTabs,
  StyledTabsHeader,
  StyledTabsContent,
} from './styles'


const animationSettings = {
  initial: {
    x: 100, opacity: 0
  },
  animate: {
    x: 0, opacity: 1
  },
  exit: {
    x: -100, opacity: 0
  },
  transition: { duration: 0.3 }
}


const Tabs = ({
  theme,
  header,
  children,
  onTabClick = null,
  tabsAnimationSettings = {},
  selectedTab,
  withDropDown = false,
  overlap = true
}) => {
  return (
    <StyledTabs theme={theme} >
      <StyledTabsHeader
        data-lenis-prevent
        withDropDown={withDropDown}>
        {
          header &&
          header.map(({
            id,
            title,
            totalItems,
            items,
            size,
          }) => (
            withDropDown &&
            !!items.length
              ? <DropDown
                className="TabsDropDown"
                key={title}
                theme="boxed"
                trigger={title}
                overlap={overlap}
              >
                {
                  items.map(item => (
                    <DropDownButton
                      autoClose
                      selected={!!selectedTab[id] && selectedTab[id]?.id === item.id}
                      cluster={id}
                      key={item.title}
                      {...item}
                      onClick={onTabClick}
                    />
                  ))
                }
              </DropDown>
              : <Tab
                key={id}
                id={id}
                theme={theme}
                onTabClick={onTabClick}
                selectedTab={selectedTab}
                title={title}
                totalItems={totalItems}
                size={size}
              />
          ))}
      </StyledTabsHeader>

      <StyledTabsContent withDropDown={withDropDown}>
        <AnimatePresence mode='wait' >
          <motion.div
            key={selectedTab ? selectedTab : 'empty'}
            { ...animationSettings }
            { ...tabsAnimationSettings }
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </StyledTabsContent>
    </StyledTabs>
  )
}

export default Tabs
