export const htmlStringToString = (string) => {
  return string.replace(/(<([^>]+)>)/ig, '')
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const slugify = (string) => {
  return string ? string.toLowerCase().replace(/ /g, '-').trim() : ''
}

export const generateRandomNumber = (maxLimit = 10) => {
  let rand = Math.random() * maxLimit

  rand = Math.floor(rand) // 99

  return rand
}


export const getRandomChars = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}


export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}
