import arrowLeft from './arrow-left'
import arrowRight from './arrow-right'
import customerCare from './customer-care'
import endToEnd from './end-to-end'
import fullServiceC from './full-service-c'
import marketplaceOnesolution from './marketplace-onesolution'
import marketplaceSolution from './marketplace-solution'
import merchantRecords from './merchant-records'
import search from './search'
import world from './world'
import automotive from './automotive'
import fashion from './fashion'
import hiTech from './hi-tech'
import manufacturing from './manufacturing'
import publishing from './publishing'
import grocery from './grocery'
import chevron from './chevron'
import linkedin from './linkedin'
import youtube from './youtube'
import twitter from './twitter'
import facebook from './facebook'
import chevronRight from './chevron-right'
import gMap from './g-map'
import close from './close'
import mapPin from './map-pin'

const iconsList = {
  arrowLeft,
  arrowRight,
  customerCare,
  endToEnd,
  fullServiceC,
  marketplaceOnesolution,
  marketplaceSolution,
  merchantRecords,
  search,
  world,
  automotive,
  fashion,
  hiTech,
  manufacturing,
  publishing,
  grocery,
  chevron,
  linkedin,
  youtube,
  twitter,
  facebook,
  chevronRight,
  gMap,
  close,
  mapPin
}

export default iconsList
