import React from 'react'

const grocery = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="10" fill="#26376D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31 21C31 19.159 32.4924 17.6667 34.3333 17.6667C36.1743 17.6667 37.6667 19.159 37.6667 21V29.6666H36.3333V21C36.3333 19.8954 35.4379 19 34.3333 19C33.2288 19 32.3333 19.8954 32.3333 21V29H31V21Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.1953 21.8619C33.4556 21.6016 33.8777 21.6016 34.1381 21.8619L35.4714 23.1952C35.7318 23.4556 35.7318 23.8777 35.4714 24.1381C35.2111 24.3984 34.7889 24.3984 34.5286 24.1381L33.1953 22.8047C32.9349 22.5444 32.9349 22.1223 33.1953 21.8619Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.1953 25.8619C33.4556 25.6016 33.8777 25.6016 34.1381 25.8619L35.4714 27.1952C35.7318 27.4556 35.7318 27.8777 35.4714 28.138C35.2111 28.3984 34.7889 28.3984 34.5286 28.138L33.1953 26.8047C32.9349 26.5444 32.9349 26.1223 33.1953 25.8619Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1905 25C19.1905 24.6318 19.489 24.3333 19.8572 24.3333H27.4762C27.8444 24.3333 28.1429 24.6318 28.1429 25V27.0608L29.5929 29.8803C29.6414 29.9746 29.6667 30.0791 29.6667 30.1852V41C29.6667 41.3682 29.3682 41.6666 29 41.6666C28.6318 41.6666 28.3333 41.3682 28.3333 41V30.3465L27.4762 28.6799L26.6191 30.3465V44.3333L27.6667 44.3333C28.0349 44.3333 28.3333 44.6318 28.3333 45C28.3333 45.3681 28.0349 45.6666 27.6667 45.6666H19.6667C18.5621 45.6666 17.6667 44.7712 17.6667 43.6666V30.1852C17.6667 30.0791 17.692 29.9746 17.7405 29.8803L19.1905 27.0608V25ZM20.264 27.8889L19.4259 29.5185H25.5456L26.3837 27.8889H20.264ZM26.8095 26.5555H20.5238V25.6666H26.8095V26.5555ZM25.2857 30.8518H19V43.6666C19 44.0348 19.2985 44.3333 19.6667 44.3333H25.2857V30.8518Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M41 40.3333C42.8409 40.3333 44.3333 41.8257 44.3333 43.6666V45.6666H38.3333V44.3333H43V43.6666C43 42.5621 42.1046 41.6666 41 41.6666V40.3333Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.3333 40.3333C28.4924 40.3333 27 41.8257 27 43.6666V45.6666H33V44.3333H28.3333V43.6666C28.3333 42.5621 29.2288 41.6666 30.3333 41.6666V40.3333Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M38.3333 37.6666C40.1743 37.6666 41.6667 39.159 41.6667 41V45.6666H35.6667V44.3333H40.3333V41C40.3333 39.8954 39.4379 39 38.3333 39V37.6666Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33 37.6666C31.1591 37.6666 29.6667 39.159 29.6667 41V45.6666H35.6667V44.3333H31V41C31 39.8954 31.8954 39 33 39V37.6666Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32.3333 39.6666C32.3333 37.8257 33.8257 36.3333 35.6667 36.3333C37.5076 36.3333 39 37.8257 39 39.6666V45.6666H32.3333V39.6666ZM35.6667 37.6666C34.5621 37.6666 33.6667 38.5621 33.6667 39.6666V44.3333H37.6667V39.6666C37.6667 38.5621 36.7712 37.6666 35.6667 37.6666Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.8619 30.138C31.7541 30.0302 31.5792 30.0302 31.4714 30.138L31.1953 30.4142C30.7799 30.8295 30.1394 30.8825 29.6667 30.573V41C29.6667 41.3682 29.3682 41.6666 29 41.6666C28.6318 41.6666 28.3333 41.3682 28.3333 41V30.3333C28.3333 29.2862 29.5541 28.7431 30.3298 29.394L30.5286 29.1952C31.1571 28.5667 32.1762 28.5667 32.8047 29.1952C32.9126 29.3031 33.0874 29.3031 33.1953 29.1952C33.8238 28.5667 34.8429 28.5667 35.4714 29.1952C35.5792 29.3031 35.7541 29.3031 35.8619 29.1952C36.4905 28.5667 37.5095 28.5667 38.1381 29.1952C38.2459 29.3031 38.4207 29.3031 38.5286 29.1952C39.1571 28.5667 40.1762 28.5667 40.8047 29.1952L41.0035 29.394C41.7792 28.7431 43 29.2862 43 30.3333V41C43 41.3682 42.7015 41.6666 42.3333 41.6666C41.9651 41.6666 41.6667 41.3682 41.6667 41V30.573C41.1939 30.8825 40.5534 30.8295 40.1381 30.4142L39.8619 30.138C39.7541 30.0302 39.5792 30.0302 39.4714 30.138C38.8429 30.7666 37.8238 30.7666 37.1953 30.138C37.0874 30.0302 36.9126 30.0302 36.8047 30.138C36.1762 30.7666 35.1571 30.7666 34.5286 30.138C34.4208 30.0302 34.2459 30.0302 34.1381 30.138C33.5095 30.7666 32.4905 30.7666 31.8619 30.138Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.8667 21C36.8667 20.6318 37.1652 20.3333 37.5334 20.3333H38.3926C39.9063 20.3333 41.1334 21.5604 41.1334 23.0741V23.5481C41.1334 24.0636 41.5512 24.4815 42.0667 24.4815C43.3185 24.4815 44.3334 25.4963 44.3334 26.7481V27.3122C44.3334 28.1019 44.0035 28.8557 43.4235 29.3915L42.5191 30.227C42.2486 30.4769 41.8268 30.4602 41.577 30.1897C41.3272 29.9193 41.3439 29.4975 41.6143 29.2477L42.5187 28.4122C42.8255 28.1287 43 27.73 43 27.3122V26.7481C43 26.2327 42.5822 25.8148 42.0667 25.8148C40.8148 25.8148 39.8 24.8 39.8 23.5481V23.0741C39.8 22.2968 39.1699 21.6667 38.3926 21.6667H37.5334C37.1652 21.6667 36.8667 21.3682 36.8667 21Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.9999 23C27.5496 23 26.314 23.9266 25.8561 25.2221L24.599 24.7778C25.2393 22.9662 26.967 21.6667 28.9999 21.6667C30.1948 21.6667 31.2861 22.1166 32.1113 22.8552L31.222 23.8486C30.6319 23.3204 29.8541 23 28.9999 23Z" fill="white"/>
  </svg>

)

export default grocery
