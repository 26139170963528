import React from 'react'


import SectionContainer from '@/Section/Container'
import Services from '@/Services'

const TradesList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    <Services
      theme={theme}
      data={sectionContent.trades}
      layout={sectionContent.layout}/>
  </SectionContainer>
)

export default TradesList
