import React, { useContext } from 'react'
import { rem } from 'config/stitches.config'

import { MenuContext } from 'contexts/Menu'
import { GeneralContext } from 'contexts/General'
import withCustomCursor from 'hoc/withCustomCursor'

import MenuFooter from '@/Menus/MenuFooter'
import Grid from '@/UI/Grid'
import Container from '@/UI/Container'
import Text from '@/UI/Text'
import RichText from '@/UI/RichText'
import Newsletter from '@/Newsletter'

import {
  StyledFooter,
  StyledFooterRow,
  StyledFooterCol,
  StyledFooterLower,
} from './styles'


const Footer = (props) => {
  const { menus } = useContext(MenuContext)
  const { page } = useContext(GeneralContext)
  const { footer } = menus

  const hideFooter = !!page.removeFooter

  if (hideFooter || !footer) return <></>

  const footerUpperColumns = !!footer.upper ? footer.upper.reduce((acc, el) => {
    const colNumber = el.columns && el.columns !== 0 ? el.columns : 1
    return acc + colNumber
  }, 0) : 0

  return (
    <StyledFooter
      className='Footer'
      data-theme='inverted'
      {...props}>
      <Container>
        {
          footer.payoff &&
          <StyledFooterRow css={{
            borderTop: 'none',
            paddingTop: 0,
            paddingBottom: rem(80),
            '@md': { paddingBottom: rem(90) }
          }}>
            <RichText
              size={{
                '@initial': 'h2-mobile',
                '@md': 'h2'
              }}
              content={footer.payoff}
            />
          </StyledFooterRow>

        }

        <StyledFooterRow css={{ paddingY: 0 }}>
          <Newsletter />
        </StyledFooterRow>

        {
          footer.upper &&
          <StyledFooterRow>
            <Grid
              gapX={3}
              flow="row"
              columns={{
                '@initial': 1,
                '@md': footerUpperColumns
              }}
            >
              {
                footer.upper.map((el, idx) => <MenuFooter key={idx} {...el} />)
              }
            </Grid>
          </StyledFooterRow>
        }

        {
          footer.lower &&
          <StyledFooterRow>
            <StyledFooterLower>
              <StyledFooterCol css={{ 'grid-area': 'company' }}>
                <Text size="footer" css={{ color: '$white' }}>
                @ {new Date().getFullYear()} {footer.company}
                </Text>
              </StyledFooterCol>
              <StyledFooterCol css={{ 'grid-area': 'menus' }}>
                <Grid
                  flow="row"
                  gapX={3}
                  css={{
                    '@md': {
                      placeItems: 'center',
                      alignItems: 'center'
                    }
                  }}
                  columns={{
                    '@initial': 1,
                    '@md': 2
                  }}
                >
                  {
                    footer.lower.map((el, idx) => <MenuFooter key={idx} {...el} />)
                  }
                </Grid>

              </StyledFooterCol>
              <StyledFooterCol css={{
                textAlign: 'right',
                gridArea: 'iva',
              }}>
                <Text size="footer" css={{ color: '$white' }} >
                  {footer.vatNumber}
                </Text>
              </StyledFooterCol>
            </StyledFooterLower>
          </StyledFooterRow>
        }

      </Container>
    </StyledFooter>
  )
}

export default withCustomCursor(Footer)
