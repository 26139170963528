
import React from 'react'
import { motion } from 'framer-motion'

import CustomImage from '@/Image'
import ClientListPreview from '@/Previews/ClientListPreview'

import {
  StyledListWithImages,
  StyledListContent,
} from './styles'

const container = {
  hidden: {  },
  show: {
    transition: {
      duration: 1,
      staggerChildren: 0.3
    }
  },
}
const ListWithImages = ({
  theme,
  items,
  image = null,
  imagePosition = null,
  showLabel,
}) => {
  const imgPos = !!imagePosition ? imagePosition : 'left'
  return (
    <StyledListWithImages direction={imgPos}>
      {
        image &&
        <CustomImage
          theme={theme}
          as="div"
          className="ListImage"
          rounded="normal"
          media={image.media}
          tags={!!image.keyword ? [image.keyword] : false} />
      }

      <StyledListContent
        as={motion.div}
        variants={container}
        initial="hidden"
        whileInView="show"
        fullWidth={!image}
        viewport={{ once: true }}
      >
        {items.map((item, idx) => (
          <ClientListPreview
            idx={idx + 1}
            key={idx}
            showLabel={showLabel}
            {...item}
          />
        ))}
      </StyledListContent>

    </StyledListWithImages>
  )
}

export default ListWithImages
