import React from 'react'

import MenuItem from '../MenuItem'

import {
  StyledMenuFooterMain,
  StyledMenuFooterMainUl
} from './styles'

const MenuFooterMain = ({
  items,
  direction,
  cluster,
  columns,
  theme = 'default'
}) => (
  items &&
  !!items.length &&
  <StyledMenuFooterMain>
    <StyledMenuFooterMainUl direction={direction} theme={theme} columns={columns || 1}>
      {
        items.map(({
          id,
          label,
          ...rest
        }, idx) => <li key={idx}><MenuItem id={`item_${id}_${cluster.toLowerCase().replace(/\s+/g, '-')}_${label ? label : rest.icon}`} {...rest} title={label} /></li>)
      }
    </StyledMenuFooterMainUl>
  </StyledMenuFooterMain>
)

export default MenuFooterMain
