import React, {
  useState, useEffect
} from 'react'
import { useRouter } from 'next/router'

import FormComponent from '@/Form'
import Tabs from '@/UI/Tabs'

import { StyledFormList } from './styles'

const FormList = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState(tabs.header[0].id)
  const { query } = useRouter()


  const onTabClick = (id) => {
    const tab = selectedTab != id ? id : null

    setSelectedTab(tab)
  }

  useEffect(() => {
    const { form } = query
    const validForm = tabs.content.find(el => el.id == form)

    if (!form || !validForm) return () =>{}

    return setSelectedTab(form)
  }, [query, tabs.content])

  return (
    <StyledFormList>
      <Tabs
        theme={'noBackground'}
        header={tabs.header}
        onTabClick={onTabClick}
        selectedTab={selectedTab}
      >
        {
          tabs.content.map(({
            id,
            form
          }) =>
            id == selectedTab
            && (
              <FormComponent key={id} form={form} />
            )
          )
        }
      </Tabs>
    </StyledFormList>
  )
}

export default FormList
