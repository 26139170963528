import {
  styled, rem, HEADER_HEIGHT
} from 'config/stitches.config'

export const StyledParagraph = styled('div', {

  '.Divider': {
    marginBottom: rem(24),
    '@md': { marginBottom: rem(32) }
  },
  '.ParagraphImage': {
    '@md': {
      position: 'sticky',
      top: HEADER_HEIGHT.desktop + 24,
    }
  },

  variants: {
    preserveRatio: {
      false: {
        '.ParagraphImage': {
          'img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            minHeight: rem(320),
            '@md': {
              height: `calc(100vh - ${HEADER_HEIGHT.desktop + 48}px)`,
              maxHeight: `calc(100vh - ${HEADER_HEIGHT.desktop + 48}px)`
            },
          },
        },
      },
      true: {
        '.ParagraphImage': {
          'img': {
            width: '100%',
            height: 'auto'
          },
        },
      }
    },
    full: {
      true: { flexBasis: '100%' },
      false: { '@lg': { flexBasis: 'calc(50% - 60px)' } },
    }
  },
  defaultVariants: { preserveRatio: false, }
})

export const StyledParagraphContent = styled('div', { overflow: 'hidden' })
