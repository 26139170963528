import React, { useState } from 'react'
import Text from '@/UI/Text'
import { motion } from 'framer-motion'

import { StyledStatDetail } from './styles'

import {
  generateRandomNumber,
  getRandomChars
} from 'utils/functions'

const Letter = ({ letters, }) => {
  const [count, setCount] = useState(0)

  if (count < letters.length - 1) {
    setTimeout(()=> {
      setCount(c => c + 1)
    }, 100)
  }

  return (
    <Text
      translate='no'
      as={'strong'}
      key={letters[count]}
    >{letters[count]}</Text>
  )
}

const StatsDetail = ({ content }) => {
  const titleSplitted = content.split('')
  const [enteredState, setEnteredState] = useState('hidden')

  const stringsToShow = titleSplitted.map((l) => {
    let chars = getRandomChars(generateRandomNumber())
    chars = chars + l
    return chars
  })

  const onViewportEnter = () => {
    setEnteredState('show')
  }

  return (
    <motion.div
      onViewportEnter={onViewportEnter}
      style={{
        marginBottom: 16,
        display: 'inline-flex',
        position: 'relative'
      }}
    >
      <Text as="strong" style={{ visibility: 'hidden' }}>{content}</Text>

      <StyledStatDetail>
        {
          enteredState === 'show' &&
          titleSplitted.map((_, idx) => {
            const letters = stringsToShow[idx]
            return <Letter
              key={idx}
              letters={letters}
            />
          })}
      </StyledStatDetail>
    </motion.div>

  )
}


export default StatsDetail
