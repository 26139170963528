/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useContext
} from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  Formik,
  Form
} from 'formik'

import { fetchData } from 'services/API'
import {
  GTMContext, GTM_CUSTOM_EVENTS
} from 'contexts/GTM'

import { getCookie } from 'utils/functions'

import ButtonTimer from '@/Buttons/ButtonTimer'

import RichText from '@/UI/RichText'
import Cta from '@/UI/Cta/DefaultCta'

import Fieldset from './Fieldset'

import { StyledFormSubmit } from './styles'

const FormModal = dynamic(() => import('@/Modals/FormModal'), { ssr: false, })

const CustomForm = ({
  title,
  endpoint,
  successMessage,
  initialValues,
  fieldsets = [],
  validationSchema = {},
  validateOnMount = false,
  submitButton = null,
  onSubmit = null,
  className = null,
  darkMode = false,
  settings = null,
}) => {
  const { pushToDataLayer } = useContext(GTMContext)
  const [isOpen, setIsOpen] = useState(false)
  const [feedback, setFeedback] = useState(null)

  const recaptchaRef = useRef(null)
  const {
    locale,
    query
  } = useRouter()

  const getErrors = (errors) => {
    const { details: { errors: errorsArray }  } = errors
    return errorsArray.reduce((acc, curr) => {
      const {
        path, message
      } = curr

      acc[path[0]] = message
      return acc
    }, {})
  }


  return (
    <>
      <Formik
        validateOnMount={validateOnMount}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, {
          resetForm,
          setErrors,
        }) => {
          try {
            const _values = Object.assign({}, values)
            let token
            const isLocalEnvironment = window.location.href.includes('localhost')
            if (recaptchaRef && recaptchaRef.current && !isLocalEnvironment) {
              token = await recaptchaRef.current.executeAsync()
              if (!token) {
                throw new Error('Invalid Recaptcha validation')
              }
            }
            if (endpoint) {
              const urlEndpoint = new URL(endpoint)
              urlEndpoint.searchParams.set('locale', locale)

              const formData = new FormData()

              for (const val in _values) {
                // con il recaptcha invisibile questo campo va fuori da aggiornamento dati
                // e validazione di Formik
                const value = val === 'recaptcha' ? token : _values[val]
                formData.append(val, value)
              }

              if (!!Object.keys(query).length) {
                for (const params in query) {
                  formData.append(params, query[params])
                }
              }

              const gclidCookie = getCookie('gclid')
              if (gclidCookie) {
                formData.append('gclid', gclidCookie)
              }

              const response = await fetchData(urlEndpoint, {
                method: 'POST',
                body: formData
              })

              pushToDataLayer({
                type: GTM_CUSTOM_EVENTS.FORM,
                label: `${title} - ${locale.toUpperCase()}`,
                value: response.error ? 'Error' : 'Success',
              })

              if (response.error) {
                if (response.error?.details) {
                  const errors = await getErrors(response.error)
                  await setErrors(errors)
                  return
                }

                const errorMessage = response.error.message

                setFeedback(errorMessage)
              }
            }

            await resetForm()
            setIsOpen(true)

            if (onSubmit) await onSubmit(_values)
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        }}
      >
        {({
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Form
            id={settings?.id}
            className={className}>
            {
              fieldsets &&
              !!fieldsets.length &&
              fieldsets.map((fieldset, idx) => <Fieldset key={idx} {...fieldset} ref={recaptchaRef}/>)
            }

            {
              submitButton &&
              <StyledFormSubmit>
                <Cta
                  className="FormSubmit"
                  as="button"
                  type="submit"
                  disabled={!isValid || !!Object.entries(errors).length || isSubmitting}
                  darkMode={darkMode}
                  size="huge"
                  {...submitButton}
                >
                  {
                    submitButton.label &&
                  <span>
                    {submitButton.label}
                  </span>
                  }
                </Cta>
              </StyledFormSubmit>
            }
          </Form>
        )}
      </Formik>

      {
        settings &&
        !settings.hideSuccessMessage &&
         <FormModal
           isOpen={isOpen}
           modalHandler={() => setIsOpen(false)}
           success={!feedback}
         >
           <RichText
             className="SuccessMessage"
             size={{
               '@initial': 'p1-mid-mobile',
               '@md': 'p1-mid',
             }}
             content={feedback ? feedback : successMessage}
           />
           <ButtonTimer
             onClick={() => setIsOpen(false)}
             onAnimationComplete={() => {
               setFeedback(null)
               if (isOpen) setIsOpen(false)
             }}
           />
         </FormModal>
      }

    </>
  )
}

export default CustomForm
