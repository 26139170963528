import {
  generateSlug, rewriteLocale
} from 'services/API/helpers'

const PAGE_SIZE = 3

const defaultQueryParams = {
  populate: 'deep',
  'sort[1]': 'date:desc'
}

export const normalizedContentData = (data, additionalData) => {
  if (!data) return []

  const { ctaLabel } = additionalData
  return data.reduce((acc, curr) => {
    const {
      excerpt,
      locale: itemLocale,
      title,
      preview = {},
      ...rest
    } = curr.attributes

    const link = {
      label: ctaLabel,
      href: generateSlug(curr.attributes).join('/'),
      target: '_self',
      locale: rewriteLocale(itemLocale, 'shortened'),
    }


    let image = null
    if (preview && preview.mediaWithTag) {
      image = preview.mediaWithTag
    }

    const item = {
      ...rest,
      image,
      layout: 'preview',
      locale: itemLocale,
      items: [{
        showLabel: false,
        title,
        description: excerpt,
        cta: link
      }],
    }

    acc.push(item)

    return acc
  }, [])
}

const CaseArchive = async (data, locale) => {
  const {
    showFilters,
    filters,
    pageSize,
  } = data

  const params =  {
    locale: rewriteLocale(locale, 'full'),
    'pagination[page]': 1,
    'pagination[pageSize]': !!pageSize ? pageSize : PAGE_SIZE,
    ...defaultQueryParams
  }

  let header = false


  if (showFilters && filters) {
    header = Object.keys(filters).map(key => {
      const customLabel = data[`${key}FilterLabel`]
      const {
        label,
        items,
      } = filters[key]

      return {
        id: key,
        title: customLabel || label,
        items: items.map(({
          id,
          name,
          ...rest
        }) => {
          return {
            id,
            title: name,
            attrs: ['id'],
            ...rest
          }
        })
      }
    })
  }


  const normalizedData = {
    ...data,
    params,
    filters: {
      header,
      content: null
    },
  }

  return normalizedData
}

export default CaseArchive
