import Footer from './Footer'
import Form from './Form'
import JobsList from './JobsList'
import MainMenu from './MainMenu'
import Options from './Options'
import PartnersList from './PartnersList'
import Scenarios from './Scenarios'
import SectionContact from './SectionContact'
import ServicesList from './ServicesList'
import Tabs from './Tabs'
import TailList from './TailList'
import TradesList from './TradesList'
import CardLink from './CardLink'
import Banner from './Banner'
import FormList from './FormList'
import NewsItemsArchive from './NewsItemsArchive'
import NewsItems from './NewsItems'
import CaseArchive from './CaseArchive'
import Case from './Case'
import Map from './Map'

const ScenariosListPrefilled = Scenarios
const ServicesListPrefilled = ServicesList

export const models = {
  Footer,
  Form,
  JobsList,
  MainMenu,
  Options,
  PartnersList,
  Scenarios,
  SectionContact,
  ScenariosListPrefilled,
  ServicesList,
  ServicesListPrefilled,
  Tabs,
  TailList,
  TradesList,
  CardLink,
  Banner,
  FormList,
  NewsItemsArchive,
  NewsItems,
  Case,
  CaseArchive,
  Map,
}
