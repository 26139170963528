import React from 'react'
import Heading from '@/UI/Heading'

import Input from './Input'

import {
  StyledGroup,
  StyledGroupContainer
} from './styles'

const Group = ({
  label,
  items,
  dependsOn,
}) => {
  return (
    <StyledGroup>
      {
        label &&
        <Heading as='strong' size="s2">{label}</Heading>
      }
      <StyledGroupContainer>
        {
          items.map((item, index) => (<Input dependsOn={dependsOn} key={index} {...item}  />))
        }
      </StyledGroupContainer>
    </StyledGroup>
  )
}

export default Group
