import React from 'react'

import Icon from '@/UI/Icon'
import Heading from '@/UI/Heading'
import Button from '@/UI/Button'

import { StyledHeroSubtitle } from './styles'

const HeroSubtitle = ({
  content,
  withBackground = false
}) => {
  return (
    <StyledHeroSubtitle>
      {
        !withBackground &&
        <Button
          as="div"
          className='no-hover'
          size="verySmall"
          css={{
            flexShrink: 0,
            justifyContent: 'center'
          }}
        >
          <Icon css={{ size: '80%' }} theme="inverted" name="arrowRight" />
        </Button>
      }
      <Heading as="p" size="s1" css={{ color: withBackground ? '$secondary' : '$gray3' }}>{content}</Heading>
    </StyledHeroSubtitle>
  )
}

export default HeroSubtitle
