import React, {
  useRef,
  // useEffect,
} from 'react'
// import { gsap } from 'gsap'
// import SplitText from 'gsap/dist/SplitText'

import SectionContainer from '@/Section/Container'
import MediaWithTag from '@/MediaWithTag'

import Heading from '@/UI/Heading'
import Divider from '@/UI/Divider'
import Cta from '@/UI/Cta/DefaultCta'

import SubTitle from './Subtitle'

import {
  ScaleX,
  FadeIn
} from 'animations'

import {
  StyledHero,
  StyledHeroContainer,
  StyledHeroSubtitleWrapper,
  StyledHeroCtasWrapper,
  StyledTitleContainer,
} from './styles'


const Hero = ({
  title,
  subtitle,
  asTitle = true,
  mediaWithTag,
  cta: ctas,
}) => {
  const containerRef = useRef(null)
  const titleRef = useRef(null)

  const isFullScreen = !!mediaWithTag && mediaWithTag.layout === 'cover'


  // useEffect(() => {
  //   const titleToAnimate = document.createElement('span')
  //   titleToAnimate.setAttribute('translate', 'no')
  //   titleToAnimate.tabIndex = -1
  //   titleToAnimate.innerHTML = title
  //   titleToAnimate.style.display = 'block'
  //   titleToAnimate.style.position = 'absolute'
  //   titleToAnimate.style.top = '7px'
  //   titleToAnimate.style.left = '0px'
  //   titleToAnimate.className = titleRef.current.className

  //   containerRef.current.appendChild(titleToAnimate)

  //   gsap.set(titleRef.current, {
  //     zIndex: 0,
  //     opacity: 0,
  //   })

  //   const titleToAnimateSplitted = new SplitText(titleToAnimate, {
  //     type: 'words,chars',
  //     wordsClass: 'HeroTitleWord',
  //     charsClass: 'HeroTitleLetter',
  //   })

  //   const tl = gsap.timeline()

  //   tl.from(titleToAnimateSplitted.chars, {
  //     duration: 0.6,
  //     delay: 1,
  //     opacity: 0,
  //     y: 80,
  //     stagger: 0.02,
  //     onComplete: () => {
  //       gsap.set(titleRef.current, { opacity: 1 })

  //       titleToAnimate.remove()
  //     }
  //   })
  // }, [titleRef, containerRef])

  return (
    <StyledHero
      fullScreen={isFullScreen}
    >
      {
        mediaWithTag &&
        mediaWithTag.layout === 'cover' &&
        <MediaWithTag title={title} {...mediaWithTag} />
      }
      <StyledHeroContainer>
        <SectionContainer
          css={{ paddingY: 0 }}
          variants={FadeIn({ staggerChildren: 0.2 })}
          theme="wider"
        >
          {
            title &&
            <StyledTitleContainer
              fullScreen={isFullScreen}
              ref={containerRef}
            >
              <Heading
                ref={titleRef}
                css={{ display: 'inline' }}
                className={ isFullScreen ? 'TitleInCover' : ''}
                as={asTitle ? 'h1' : 'h2'}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </StyledTitleContainer>
          }
          {
            subtitle &&
            <StyledHeroSubtitleWrapper>
              {
                !isFullScreen &&
                <Divider
                  variants={ScaleX({ opts: { delay: 0.7 } })}
                />
              }

              <SubTitle content={subtitle} withBackground={isFullScreen}/>
            </StyledHeroSubtitleWrapper>
          }

          {
            ctas &&
            !!ctas.length &&
            <StyledHeroCtasWrapper>
              {
                ctas.map((cta, idx) => (

                  <Cta
                    key={`Hero_seaction_cta_${idx}`}
                    className="HeroCta"
                    href={cta.href}
                    target={cta.target}
                    theme="secondary"
                  >
                    {cta.label}
                  </Cta>
                ))
              }
            </StyledHeroCtasWrapper>
          }

          {
            mediaWithTag &&
            mediaWithTag.layout !== 'cover' &&
            <MediaWithTag title={title} {...mediaWithTag} />
          }
        </SectionContainer>
      </StyledHeroContainer>

    </StyledHero>
  )
}

export default Hero
