import React from 'react'


import SectionContainer from '@/Section/Container'
import CardsLink from '@/CardsLink'

const CardLink = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    <CardsLink items={sectionContent.items}/>
  </SectionContainer>
)

export default CardLink
