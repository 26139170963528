import {
  styled,
  rem
} from 'config/stitches.config'

import breakpoints from 'theme/breakpoints'

export const StyledSectionContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  width: '100%',
  boxSizing: 'border-box',

  variants: {
    theme: {
      inverted: {
        backgroundColor: '$black',
        br: 30,
        color: '$lightGray',
        '.Heading': { color: '$white' }
      }
    }
  },
  defaultVariants: { theme: 'default' }
})

export const StyledSectionContent = styled('div', {
  display: 'block',
  margin: '0 auto',
  width: '100%',
  '@xl': { maxWidth: breakpoints.xl, },
  paddingTop: rem(64),
  paddingBottom: rem(48),
  '@md': {
    paddingTop: rem(80),
    paddingBottom: rem(100),
  },
  variants: {
    theme: {
      default: {
        paddingX: '$2',
        '@md': {
          paddingLeft: rem(288),
          paddingRight: rem(72)
        },
        '@lg': { paddingRight: rem(216) },
      },
      inverted: {
        paddingX: '$3',
        '@md': {
          paddingLeft: rem(264),
          paddingRight: rem(88)
        },
        '@lg': { paddingRight: rem(192) },
      },
      wider: {
        paddingX: '$2',
        '@md': {
          paddingLeft: rem(288),
          paddingRight: rem(72)
        },
      },
    },
    noPadding: { true: { padding: 0 } },
  },
  defaultVariants: { theme: 'default' }
})

export const StyledSectionCta = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: rem(80),
  width: '100%',
  flexDirection: 'column',
  gap: '$4',

  '@middle': {
    display: 'inline-flex',
    flexDirection: 'row',
  },

  'a': {
    flexShrink: 0,
    textAlign: 'center',
    // flexBasis: '100%',
  },
  variants: { multiple: { true: { 'a': { '@middle': { flexBasis: '48%' } } } } }
})
