import React from 'react'

const publishing = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="10" fill="#26376D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.3333 21.3333C27.3333 20.2288 28.2288 19.3333 29.3333 19.3333H34.6667C35.7712 19.3333 36.6667 20.2288 36.6667 21.3333V42.6667C36.6667 43.7712 35.7712 44.6667 34.6667 44.6667H29.3333C28.2288 44.6667 27.3333 43.7712 27.3333 42.6667V21.3333ZM29.3333 20.6667C28.9651 20.6667 28.6667 20.9652 28.6667 21.3333V24.6667H35.3333V21.3333C35.3333 20.9652 35.0349 20.6667 34.6667 20.6667H29.3333ZM35.3333 26H28.6667V38H35.3333V26ZM35.3333 39.3333H28.6667V42.6667C28.6667 43.0349 28.9651 43.3333 29.3333 43.3333H34.6667C35.0349 43.3333 35.3333 43.0349 35.3333 42.6667V39.3333Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32 28.6667C32.3682 28.6667 32.6667 28.9652 32.6667 29.3333V34.6667C32.6667 35.0349 32.3682 35.3333 32 35.3333C31.6318 35.3333 31.3333 35.0349 31.3333 34.6667V29.3333C31.3333 28.9652 31.6318 28.6667 32 28.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M35.3333 24C35.3333 22.8954 36.2288 22 37.3333 22H42.6667C43.7712 22 44.6667 22.8954 44.6667 24V42.6667C44.6667 43.7712 43.7712 44.6667 42.6667 44.6667H37.3333C36.2288 44.6667 35.3333 43.7712 35.3333 42.6667V24ZM37.3333 23.3333C36.9651 23.3333 36.6667 23.6318 36.6667 24V26H43.3333V24C43.3333 23.6318 43.0349 23.3333 42.6667 23.3333H37.3333ZM43.3333 27.3333H36.6667V28.6667H43.3333V27.3333ZM43.3333 30H36.6667V36.6667H43.3333V30ZM43.3333 38H36.6667V39.3333H43.3333V38ZM43.3333 40.6667H36.6667V42.6667C36.6667 43.0349 36.9651 43.3333 37.3333 43.3333H42.6667C43.0349 43.3333 43.3333 43.0349 43.3333 42.6667V40.6667Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.7343 23.6128C20.8959 22.5201 21.9126 21.7653 23.0053 21.9268L25.6433 22.3168C26.736 22.4784 27.4908 23.4952 27.3293 24.5879L24.5992 43.0538C24.4376 44.1465 23.4209 44.9013 22.3282 44.7398L19.6902 44.3498C18.5975 44.1882 17.8426 43.1714 18.0042 42.0788L20.7343 23.6128ZM22.8103 23.2458C22.4461 23.192 22.1071 23.4436 22.0533 23.8078L19.3232 42.2738C19.2693 42.638 19.521 42.9769 19.8852 43.0308L22.5232 43.4208C22.8874 43.4746 23.2263 43.223 23.2802 42.8588L26.0103 24.3928C26.0641 24.0286 25.8125 23.6897 25.4483 23.6358L22.8103 23.2458Z" fill="white"/>
    <path d="M24.9608 26.9333C24.8531 27.6618 24.1752 28.165 23.4468 28.0573C22.7183 27.9496 22.2151 27.2718 22.3228 26.5433C22.4305 25.8149 23.1083 25.3116 23.8368 25.4193C24.5652 25.527 25.0685 26.2049 24.9608 26.9333Z" fill="white"/>
  </svg>
)

export default publishing
