import React from 'react'

import SectionContainer from '@/Section/Container'
import { normalizedContentData } from 'services/models/CaseArchive'

import Filters from '@/Filters'
import ListWithImages from '@/ListWithImages'

const CaseArchive = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    <Filters
      {...sectionContent}
      ElementToRender={ListWithImages}
      normalizeFunction={normalizedContentData}
      contentStyle={{
        'rowGap': 0,
        display: 'block'
      }}
      overlap={false}
    />
  </SectionContainer>
)

export default CaseArchive
