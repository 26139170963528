import {
  styled,
  rem
} from 'config/stitches.config'

import Text from '@/UI/Text'
import SectionContent from '@/Section/Content'

export const StyledBannerDetails = styled('div', {
  paddingX: rem(24),
  marginBottom: rem(64),

  '@middle': {
    paddingLeft: 0,
    marginBottom: 0
  }
})
export const StyledBannerContent = styled('div', {
  zIndex: 1,
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: 240,
  paddingY: rem(54),
  paddingX: rem(8),

  '@middle': {
    flexDirection: 'row',
    paddingY: rem(92),
    paddingX: rem(72),
  },


  '.BannerCta': {
    width: '100%',
    justifyContent: 'center',
    fontWeight: '$3',
    fontSize: '$cta-primary',
    lineHeight: '$cta-primary',
    '@middle': { width: 'auto', }
  },
  [Text]: {
    width: '100%',
    maxWidth: 350,
    color: '$white',
    '.highlighted': { color: '$secondary' }
  },
  variants: { isFullScreen: { true: { '@md': { paddingX: 0 } } } }
})

export const StyledBannerImage = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex: 0,

  '.MediaWithTag': {
    marginTop: 0,
    height: '100%',

    'div': { height: '100%' },

    'img': {
      position: 'relative',
      zIndex: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center center'
    },
  },

  '&::before': {
    content: '',
    position: 'absolute',
    size: '100%',
    left: 0,
    top: 0,
    backgroundColor: '$black',
    opacity: 0.5,
    zIndex: 1,
  },
})


export const StyledBanner = styled('div', {
  position: 'relative',
  [SectionContent]: {
    position: 'relative',
    [StyledBannerImage]: { br: 40 }
  },
  variants: {
    isFullScreen: {
      true: {
        '@media (max-width: 767px)': { '.BannerContainer': { paddingX: 0 }, },
        [SectionContent]: {
          [StyledBannerImage]: { br: 0 },
          '@md': { position: 'static', }
        },
      },
      false: { marginY: rem(64) }
    }
  }
})

export const StyledBannerCtas = styled('div', {
  display: 'flex',
  gap: '$2',
  flexWrap: 'wrap',
  paddingX: rem(24),
  width: '100%',
  '@md': {
    paddingX: 0,
    width: 'auto'
  }
})
