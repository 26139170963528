import React from 'react'

import { normalizedContentData } from 'services/models/JobsList'

import SectionContainer from '@/Section/Container'

import Filters from '@/Filters'
import JobPreview from '@/Previews/JobPreview'

const JobsList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    <Filters
      {...sectionContent}
      ElementToRender={JobPreview}
      normalizeFunction={normalizedContentData}
      overlap={false}
    />
  </SectionContainer>
)

export default JobsList
