import React, {
  useState,
  useContext,
  forwardRef
} from 'react'

import dynamic from 'next/dynamic'

import { GeneralContext } from 'contexts/General'

import Icon from '@/UI/Icon'

import { StyledSearchButton } from './styles'

const SearchModal = dynamic(() => import('@/Modals/SearchModal'), { ssr: false, })

const SearchButton = (props, forwardedRef) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    options,
    page
  } = useContext(GeneralContext)

  const onButtonClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    options.search &&
    <>
      <StyledSearchButton ref={forwardedRef} {...props} onClick={onButtonClick} aria-label="Search" darkMode={page.pageDarkTheme}>
        <Icon name="search" />
      </StyledSearchButton>

      <SearchModal isOpen={isOpen} modalHandler={onButtonClick}/>
    </>
  )
}

export default forwardRef(SearchButton)
