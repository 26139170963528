import React from 'react'

import { AnimatePresence } from 'framer-motion'

import { StyledContentList } from './styles'

const ContentList = ({
  items,
  ElementToRender,
  contentStyle,
}) => (
  items &&
  !!items.length &&
  <StyledContentList css={{ ...contentStyle }}>
    <AnimatePresence>
      {
        items.map((job, idx) => (<ElementToRender key={idx} idx={idx} {...job} />))
      }
    </AnimatePresence>
  </StyledContentList>
)

export default ContentList
