import React, { useState } from 'react'
import { motion } from 'framer-motion'
import CustomImage from '@/Image'

import {
  StyledImagesWithTag,
  StyledImageWithTag
} from './styles'


const AnimatedImage = ({
  media,
  totalImages,
  alignment,
  ...imageProps
}) => {
  const [inView, setInView] = useState(false)

  const containerVariants = {
    zoom: () => {
      if (!inView) setInView(true)
    }
  }

  return (
    <StyledImageWithTag
      count={totalImages}
      alignment={alignment}
      className={inView ? 'inView' : ''}
      as={motion.div}
      variants={containerVariants}
    >
      {
        media.data &&
        <CustomImage media={media} {...imageProps} />
      }
    </StyledImageWithTag>
  )
}


const ImagesWithTag = ({
  items,
  alignment,
  theme
}) => {
  if (!items || items.length === 0) return <></>
  const totalImages = items.length
  return (
    <StyledImagesWithTag
      as={motion.div}
      alignment={alignment}
      whileInView="zoom"
      viewport={{ once: true }}
    >
      {items.map((item, idx) => {
        const {
          media,
          lazyload = true,
          keyword
        } = item

        const imageProps = {
          totalImages,
          alignment,
          as: 'div',
          media,
          tags: keyword ? [keyword] : false,
          theme,
          lazyload,
        }


        return (
          <AnimatedImage key={idx} {...imageProps} />
        )
      })}

    </StyledImagesWithTag>
  )
}

export default ImagesWithTag
