import {
  styled, rem
} from 'config/stitches.config'

import SimpleLink from '@/UI/Link'

export const StyledNewsPreview = styled('article', {
  borderTop: '1px solid $black',
  paddingY: '$2',

  '@md': { paddingY: rem(32), }
})

export const StyledNewsPreviewContainer = styled(SimpleLink, {
  textDecoration: 'none',
  width: '100%',
  flexDirection: 'column',
  rowGap: rem(48),

  '.NewsPreview_image': {
    width: '100%',
    aspectRatio: '343 / 240',

    'img': {
      size: '100%',
      objectFit: 'cover',
      objectPosition: 'center center'
    },

    '@md': {
      aspectRatio: '432 / 140',
      flexBasis: '50%',
    }
  },
  '@md': { flexDirection: 'row', }
})

export const StyledNewsPreviewContent = styled('div', {
  textAlign: 'left',
  width: '100%',
  '> span': { marginBottom: '$1', },
  'strong': {
    fontSize: '$news-title-mobile',
    '@md': { fontSize: '$news-title', }
  },

  '@md': {
    flexBasis: '50%',
    flexShrink: 0,
    paddingRight: rem(80)
  }
})

