import React from 'react'

const close = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0539 0.162324C15.2703 -0.0541075 15.6212 -0.0541071 15.8377 0.162324C16.0541 0.378756 16.0541 0.729661 15.8377 0.946092L0.946091 15.8377C0.72966 16.0541 0.378755 16.0541 0.162324 15.8377C-0.0541079 15.6212 -0.0541079 15.2703 0.162324 15.0539L15.0539 0.162324Z" fill="#141414"/>
    <path d="M15.8377 15.0539C16.0541 15.2703 16.0541 15.6212 15.8377 15.8377C15.6212 16.0541 15.2703 16.0541 15.0539 15.8377L0.162324 0.946091C-0.0541075 0.729659 -0.0541071 0.378755 0.162324 0.162324C0.378756 -0.0541078 0.729661 -0.0541079 0.946092 0.162324L15.8377 15.0539Z" fill="#141414"/>
  </svg>
)

export default close
