import React from 'react'
import SectionContainer from '@/Section/Container'

import ListWithImages from '@/ListWithImages'


const CardsListRepeater = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) =>  {
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
    >
      {
        sectionContent.cardList.map(({
          id,
          imagesWithTag,
          clientsList,
          showIndex
        }) => {
          return <ListWithImages key={`CardListRepeater_${id}`} layout={sectionContent.theme} items={clientsList} image={imagesWithTag} theme={theme} showLabel={showIndex} />
        })
      }
    </SectionContainer>
  )
}

export default CardsListRepeater
