import React from 'react'

import SectionContainer from '@/Section/Container'
import ImagesList from '@/ImagesList'
import RichText from '@/UI/RichText'

const TailList = ({
  sectionHeader,
  sectionContent: {
    items, layout
  }
}) => (
  <SectionContainer
    {...{ sectionHeader: layout === 'default' ? sectionHeader : null }}
    theme="wider"
  >
    {
      layout !== 'default' &&
      sectionHeader.title &&
        <RichText size={{
          '@initial': 'big-text-mobile',
          '@md': 'big-text-desk'
        }} content={sectionHeader.title} />
    }

    <ImagesList items={items}  layout={layout}/>
  </SectionContainer>
)

export default TailList
