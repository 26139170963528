import {
  generateSlug, rewriteLocale
} from 'services/API/helpers'

const PAGE_SIZE = 3
const defaultQueryParams = {
  populate: 'deep',
  'sort[1]': 'date:desc'
}

export const normalizedContentData = (data) => {
  if (!data) return []

  return data.reduce((acc, curr) => {
    const {
      title,
      excerpt,
      jobLocations,
      jobFamily,
      locale: jobLocale
    } = curr.attributes

    const locations = jobLocations.data.map(({
      id,
      attributes
    }) => {
      const {
        name,
        jobCountry
      } = attributes

      return [{
        id,
        label: name,
        type: 'jobLocations'
      }, {
        id: jobCountry.data.id,
        label: jobCountry.data.attributes.name,
        type: 'jobCountry'
      }]
    })

    const itemsFilters = [
      ...locations.flat(),
      {
        id: jobFamily.data.id,
        label: jobFamily.data.attributes.name,
        type: 'jobFamily'
      }
    ]

    const link = {
      href: generateSlug(curr.attributes).join('/'),
      target: '_self',
      locale: rewriteLocale(jobLocale, 'shortened'),
    }

    const job = {
      title,
      description: excerpt,
      link,
      filters: itemsFilters,
    }

    acc.push(job)

    return acc
  }, [])
}

const JobsList = async (data, locale) => {
  const {
    showFilters,
    filters,
    pageSize,
  } = data

  const params =  {
    locale: rewriteLocale(locale, 'full'),
    'pagination[page]': 1,
    'pagination[pageSize]': pageSize  ? Number(pageSize) : PAGE_SIZE,
    ...defaultQueryParams
  }

  let header = false

  if (showFilters) {
    header = Object.keys(filters).map(key => {
      const customLabel = data[`${key}FilterLabel`]
      const {
        label,
        items,
      } = filters[key]

      return {
        id: key,
        title: customLabel || label,
        items: items.map(({
          id,
          name: title = null,
          ...rest
        }) => ({
          id,
          title,
          attrs: ['id'],
          ...rest
        }))
      }
    })
  }

  const normalizedData = {
    ...data,
    pageSize,
    params,
    filters: {
      header,
      content: null
    },
  }

  return normalizedData
}

export default JobsList
