import React from 'react'

import Grid from '@/UI/Grid'
import Icon from '@/UI/Icon'
import RichText from '@/UI/RichText'
import SimpleLink from '@/UI/Link'

import {
  StyledCardsLink,
  StyledCardLink,
  StyledCardLinkHeader,
  StyledCardLinkContent,
} from './styles'


const CardsLink = ({ items }) => (
  items &&
  !!items.length ? (
      <StyledCardsLink>
        <Grid
          css={{ gap: 72 }}
          columns={{
            '@initial': 1,
            '@middle': 2
          }}
        >
          {
            items.map(({
              title,
              link,
              icon,
              description
            }) => {
              const withLink = !!link
              return (
                <StyledCardLink
                  key={title}
                  withLink={withLink}
                  spaced={!withLink && !icon}
                  as={withLink ? SimpleLink : 'div'}
                  {...link}
                >
                  <StyledCardLinkHeader>
                    {
                      title &&
                  <RichText
                    size={{
                      '@initial': 'h4-mobile',
                      '@md': 'p1-mid'
                    }}
                    content={title}
                  />
                    }
                    {
                      icon &&
                  <Icon name={icon} />
                    }
                  </StyledCardLinkHeader>

                  {
                    description &&
                <StyledCardLinkContent>
                  <RichText
                    size={{
                      '@initial': 'p1-mobile',
                      '@md': 'p3'
                    }}
                    content={description}
                  />
                </StyledCardLinkContent>
                  }

                </StyledCardLink>
              )
            })
          }
        </Grid>
      </StyledCardsLink>
    ) : null)

export default CardsLink
